import { ReactNode } from 'react';

export type ModalProps = {
  isShow: boolean;
  children: ReactNode;
  isBackdrop?: boolean;
  onBackdropDismiss?: (closeModal: () => void) => void;
  className?: string;
  containerClassName?: string;
};

export enum ModalType {
  Auth = 'auth',
  DeliveryAddress = 'delivery-address',
  DeliveryInfo = 'delivery-info',
  ChangeEmail = 'change-email',
  ChangeName = 'change-name',
  ChangeNotifications = 'change-notifications',
  Promocode = 'promocode',
  AddCard = 'add-card',
  DeleteAccount = 'delete-account',
  Logout = 'logout',
  DeleteAddress = 'delete-address',
  DeleteCard = 'delete-card',
  SelectDate = 'select-date',
  Feedback = 'feedback',
  FeedbackSuccess = 'feedback-success',
  EnterDeliveryAddress = 'enter-delivery-address',
  AgeRestriction = 'age-restriction',
  RateApp = 'rate-app',
  Recommendations = 'recommendations',
  CheckoutReciever = 'reciever-data',
  Check3Ds = 'check-3ds',
}
