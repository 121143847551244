import { ENDPOINT } from './constants';
import { ETADeliveryMethodType } from './ETADeliveryMethodType';
import { RequestAPI } from './Requests';

export interface ETACalculation {
  cost: Partial<Record<ETADeliveryMethodType, ETACost>>;
  paymentMethods: Partial<Record<ETADeliveryMethodType, ETAPaymentMethod[]>>;
  distance: number;
  duration: {
    min: number;
    max: number;
    range: string;
  };
  highDemand: boolean;
  meta: {
    todayAsIndex: number;
    serverTime: string;
  };
  warehouse: ETAWarehouse;
  deliverySlots: DeliverySlotsResponse;
}

export const enum MechanicType {
  SLOT = 'SLOT',
  ON_DEMAND = 'ON_DEMAND',
}

export const enum DeliveryCostMechanicType {
  SLOT = 'SLOT_DELIVERY',
  ON_DEMAND = 'ON_DEMAND',
}

export interface ETAWarehouse {
  id: string;
  code: string;
  address: string;
  location: {
    latitude: number;
    longitude: number;
  };
  availability: {
    availableNow: boolean;
    nextAvailabilityDay: number;
    localTime: string;
    opening: string;
    closing: string;
    deliveryМechanics: {
      [key in MechanicType]: {
        nextAvailabilityDay: number;
        localTime: string;
        availableNow: boolean;
        opening: string;
        closing: string;
      };
    };
  };
  deliveryMethods: ETADeliveryMethod[];
}

export enum ETAPaymentMethod {
  Card = 'CARD',
  Cash = 'CASH',
}

interface ETADeliveryMethod {
  cost: ETACost;
  type: ETADeliveryMethodType;
  sellers: string[];
  paymentMethods: ETAPaymentMethod[];
}

export interface ETACost {
  shipping: number;
  threshold: number;
  serviceFee: number;
  serviceFeeThreshold: number;
  shippingPounds: string;
  thresholdPounds: string;
  serviceFeeShippingPounds: string;
  serviceFeeThresholdPounds: string;
}

interface ETAResponse {
  data: ETACalculation;
}

interface DeliveryCostResponse {
  shipping: number;
  threshold: number;
  ttl: string;
}

export interface DeliverySlot {
  current_date: number;
  current_date_string: string;
  time_zone: string;
  name: string;
  day_of_week: number;
  schedule_slot_id: string;
  process_start_time: number;
  process_start_time_string: string;
  cut_off_time: number;
  cut_off_time_string: string;
  dispatch_time: number;
  dispatch_time_string: string;
  delivery_min_time: number;
  delivery_min_time_string: string;
  delivery_max_time: number;
  delivery_max_time_string: string;
  delivery_price: number;
  free_delivery_threshold: number;
  remaining_capacity: number;
  reserved_capacity: number;
  total_capacity: number;
  is_active: boolean;
  company_id: string;
  zone_id: string;
}

export interface DeliverySlotsResponse {
  [timestamp: string]: DeliverySlot[];
}

interface ETARequestsInterface {
  getETA(data: {
    pos: string;
    seller: string;
    deliveryMethod?: ETADeliveryMethodType;
  }): Promise<ETAResponse>;

  getETASlot(data: {
    pos: string;
    seller: string;
    scheduleSlotId?: string;
    deliverySlotId?: string;
    createdAt?: number;
    currentDate?: number;
  }): Promise<ETAResponse>;

  getDeliveryCost(data: {
    wh_code: string;
    latitude: string | number;
    longitude: string | number;
    seller: string;
    force: boolean;
    delivery_type: number;
    deliveryMechanic?: DeliveryCostMechanicType;
  }): Promise<DeliveryCostResponse>;
}

export const ETARequests: ETARequestsInterface = {
  getETA: (data) => RequestAPI.get(ENDPOINT.eta.get, data),

  getETASlot: (data) => RequestAPI.get(ENDPOINT.eta.getSlot, data),

  getDeliveryCost: (data) =>
    RequestAPI.post(ENDPOINT.eta.getDeliveryCost, data),
};
