import { classes } from 'html-classes';

import Icon from '../Icon/Icon';

interface Props {
  label: string;
  value?: boolean;
  onChange: (values: boolean) => void;
  className?: string;
  isCompact?: boolean;
}

const ProductExpressDeliveryFilter = ({
  label,
  value = false,
  onChange,
  className,
  isCompact,
}: Props) => {
  return (
    <label
      className={classes(['control__switch', 'checkbox-wrapper', className])}
    >
      <input
        onChange={() => onChange(!value)}
        checked={value}
        type="checkbox"
        className="control__switch-input"
        name="express"
      />
      <span className="control__switch-mark" />
      <div className="control__switch-text text-wrapper">
        <div className={classes(['description', isCompact && 'reversed'])}>
          {label}
          <Icon type="express-fill" size={isCompact ? 16 : 24} />
        </div>
      </div>
    </label>
  );
};

export default ProductExpressDeliveryFilter;
