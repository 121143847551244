import { t } from 'i18next';
import { Link } from 'react-router-dom';

import Icon from '~/components/Icon/Icon';
import Text from '~/components/Text';
import { IMAGES } from '~/constants/images';
import MobileHeaderLayout from '~/pages/Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '~/pages/Main/MobileHeader/MobileHeaderTitle';

const EmptyCart = () => (
  <>
    {/* TODO: replace /shop to navigate(-1) ? */}
    <MobileHeaderLayout content={<MobileHeaderTitle text="Continue " />} />
    <div className="content-layout">
      <Link className="button _no-color _no-padding continue-shopping" to="/">
        <Icon type="chevron" size={24} className="icon__rotate-90" />
        <p className="continue-shopping__text">{t('continueShopping')}</p>
      </Link>
      <div className="cart-header">
        <Text size="32" weight="400" className="cart-header__title" isTitle>
          {t('myCart')}
        </Text>
      </div>
    </div>
    <div className="content-layout empty-cart">
      <img className="empty-cart__img" src={IMAGES.cart.empty} alt="" />
      <p className="empty-cart__title">{t('emptyCart')}</p>
      <p className="empty-cart__content">{t('phrases:emptyCartDescription')}</p>
      <Link to="/" className="button _primary _med">
        <p className="text _center empty-cart__button-text">
          {t('goShopping')}
        </p>
      </Link>
    </div>
  </>
);

export default EmptyCart;
