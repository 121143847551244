import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { CartCalculationOrderResponse } from '~/api/Catalog';
import { ETAPaymentMethod } from '~/api/ETA';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { company } from '~/company/Company';
import AwsPayment from '~/components/AwsPayment/AwsPayment';
import Collapsible from '~/components/Collapsible/Collapsible';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import Price from '~/components/Price';
import Promocode from '~/components/Promocode/Promocode';
import Spinner from '~/components/Spinner/Spinner';
import { useModal } from '~/hooks/useModal';
import { DeliveryMethodsTab } from '~/pages/Checkout/components/DeliveryMethodsTab';
import { PaymentMethods } from '~/pages/Checkout/PaymentMethods';
import MobileHeaderLayout from '~/pages/Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '~/pages/Main/MobileHeader/MobileHeaderTitle';
import { useIsTablet } from '~/pages/Order/hooks';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import styles from './CheckoutContent.module.scss';
import InfoItem from './components/InfoItem/InfoItem';
import { MobileActiveDeliveryMethodProductList } from './components/MobileActiveDeliveryMethodProductList';

export interface CheckoutContentMobileProps {
  handleCommentChange: (val: string) => void;
  handleDeliveryAddress: () => void;
  handleOpenRecieverData: () => void;
  handleSelectDateModal: (
    index: number,
    order: CartCalculationOrderResponse,
  ) => void;
  handleChangeEmailModal: () => void;
  totalDeliveryFee: number;
  isRestrictedPayment: boolean;
}

const CheckoutContentMobile = ({
  handleSelectDateModal,
  handleCommentChange,
  handleDeliveryAddress,
  handleOpenRecieverData,
  isRestrictedPayment,
  totalDeliveryFee,
}: CheckoutContentMobileProps) => {
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const isActiveAws =
    isTablet &&
    company.useAwsPaymentMethod &&
    checkoutStore.activePaymentMethod === ETAPaymentMethod.Card;

  const hasExpressDelivery = checkoutStore.deliveries?.some(
    (delivery) => !delivery.slotDeliveryDetails,
  );

  const onBackClick = () => {
    navigate('/cart');
  };

  const isDeliveryMethodSelectionAvailable =
    company.deliveryMethodSelectionAvailable &&
    checkoutStore.availableDeliveryMethods.length > 1;

  const isPickUpOrder =
    checkoutStore.deliveryMethod === ETADeliveryMethodType.ClickAndCollect;

  const personalInfo = checkoutStore.phoneVal
    ? `, ${mainStore.prettyPhoneNumber(checkoutStore.phoneVal ?? '')}`
    : '';

  const isPromoLoading =
    catalogStore.promotionStore.applyPromocodeMeta.isLoading;
  const openDeliveryModal = () => {
    openModal(ModalType.DeliveryInfo, 'checkout');
  };
  const getReceiverElement = () => {
    switch (checkoutStore.deliveryMethod) {
      case ETADeliveryMethodType.JiffyDelivery:
        return (
          <li className="delivery-payment__list-item">
            <InfoItem
              name={t('deliverTo')}
              value={checkoutStore.addressVal}
              onClick={handleDeliveryAddress}
            />
          </li>
        );
      case ETADeliveryMethodType.ClickAndCollect:
        return (
          <li className="delivery-payment__list-item">
            {orderStore.etaCalculation && (
              <InfoItem
                name={t('deliveryMethods:pickupFrom')}
                value={orderStore.etaCalculation.warehouse.address}
                onClick={handleDeliveryAddress}
              />
            )}
          </li>
        );
    }
  };

  return (
    <>
      <MobileHeaderLayout
        content={
          <MobileHeaderTitle text="Checkout" onBackClick={onBackClick} />
        }
      />
      <div
        className={classes([
          'checkout phone',
          isPickUpOrder && styles.mobilePickUpCheckout,
        ])}
      >
        {isDeliveryMethodSelectionAvailable && (
          <div className={styles.mobileDeliveryMethodSelectionWr}>
            <p className={styles.mobileDeliveryMethodSelection__caption}>
              {t('deliveryMethods:caption')}
            </p>
            <DeliveryMethodsTab
              activeTab={checkoutStore.deliveryMethod}
              onTabClick={(method) => checkoutStore.setDeliveryMethod(method)}
              className={styles.mobileDeliveryMethodSelection__methods}
            />
          </div>
        )}
        <ul
          className={classes([
            'delivery-payment__list',
            isPickUpOrder && styles.bgWhite,
          ])}
        >
          {getReceiverElement()}
          <li className="delivery-payment__list-item">
            {!checkoutStore.emailVal &&
            checkoutStore.nameVal &&
            personalInfo ? (
              <InfoItem
                name={t(checkoutStore.emailVal ? 'email' : 'enterEmail')}
                value={checkoutStore.emailVal}
                required={true}
                onClick={handleOpenRecieverData}
              />
            ) : (
              <InfoItem
                name={t(personalInfo ? 'receiver' : 'fillOutPersonalDetails')}
                value={checkoutStore.nameVal}
                additionalValue={personalInfo}
                required={true}
                onClick={handleOpenRecieverData}
              />
            )}
          </li>
        </ul>

        <MobileActiveDeliveryMethodProductList
          isDeliveryMethodSelectionAvailable={
            isDeliveryMethodSelectionAvailable
          }
          hasExpressDelivery={hasExpressDelivery}
          handleSelectDateModal={handleSelectDateModal}
          handleCommentChange={handleCommentChange}
        />

        <div
          className={classes([
            'delivery-payment',
            isPickUpOrder && styles.bgWhite,
          ])}
        >
          <p className="delivery-payment__title">
            {t(
              isDeliveryMethodSelectionAvailable
                ? 'paymentMethod'
                : 'deliveryAndReciever',
            )}
          </p>

          <ul className="delivery-payment__list">
            <li className="delivery-payment__list-item">
              {isTablet && <PaymentMethods />}
            </li>
            {isActiveAws && (
              <AwsPayment
                isDisabledSubmit={isRestrictedPayment}
                isVisibleSubmitButton
              />
            )}
          </ul>
        </div>
        <div className="checkout-devider" />
        <div
          className={classes([
            'checkout-layout__summary',
            isPickUpOrder && styles.bgWhite,
          ])}
        >
          {checkoutStore.isLoading ||
            orderStore.isLoading ||
            (isPromoLoading && <Spinner layout={true} />)}
          <div className="checkout-summary__item">
            <Collapsible
              titleElem={
                <div className="checkout-summary__header">
                  <div className="checkout-summary__header-text">
                    <p className="checkout-summary__header-text-title">
                      {t('orderValue')}
                      <Icon type="chevron" size={16} />
                    </p>
                  </div>
                  <Price
                    price={catalogStore.totalCartPrice.paidWithoutDiscount}
                    classNameCurrentPrice="checkout-summary__header-price"
                  />
                </div>
              }
              isCollapsed={true}
            >
              <ul className="checkout-summary__list">
                {catalogStore.selectedCartItems.map((i) => (
                  <li key={i.id} className="checkout-summary__list-item">
                    <div className="checkout-summary__list-item-info">
                      {i.name}{' '}
                      {i.count > 1 && (
                        <span className="checkout-summary__list-item-info-product-count">
                          {i.count}x
                        </span>
                      )}
                    </div>
                    <div className="checkout-summary__list-item-price">
                      {formatPriceWithCurrency(
                        (i.discountPrice
                          ? i.count * i.discountPrice
                          : i.count * i.price) / 100,
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </Collapsible>
          </div>
          {checkoutStore.deliveryMethod !==
            ETADeliveryMethodType.ClickAndCollect && (
            <div className="checkout-summary__item">
              <Collapsible
                titleElem={
                  <div className="checkout-summary__header">
                    <div className="checkout-summary__header-text">
                      <p className="checkout-summary__header-text-title">
                        {t('deliveryFeeLong')}
                        {company.isDeliveryInfoModalEnabled && (
                          <div
                            onClick={openDeliveryModal}
                            className={styles.deliveryTooltip}
                          >
                            <Icon className="static" type="info" size={16} />
                          </div>
                        )}
                        <Icon type="chevron" size={16} />
                      </p>
                    </div>
                    <Price
                      price={mainStore.convertPenceToPounds(totalDeliveryFee)}
                      classNameCurrentPrice="checkout-summary__header-price"
                    />
                  </div>
                }
                isCollapsed={true}
              >
                <ul className="checkout-summary__list">
                  {checkoutStore.deliveries?.map((delivery, index) => {
                    const key = delivery.slotDeliveryDetails
                      ? delivery.slotDeliveryDetails?.scheduleSlotId +
                        delivery.slotDeliveryDetails?.currentDate
                      : 'express';

                    const order = catalogStore.deliveriesOrders[index];
                    const isDeliveryFree = order?.is_delivery_free;
                    const deliveryPrice = formatPriceWithCurrency(
                      mainStore.convertPenceToPounds(
                        order?.delivery_info.paid_price ?? 0,
                      ),
                    );
                    const slotNumber = hasExpressDelivery ? index : index + 1;

                    if (!order) {
                      return <Fragment key={key} />;
                    }

                    const items = delivery.itemsIds.reduce(
                      (acc, i) =>
                        acc +
                        (catalogStore.cart.find((c) => c.sku === i)?.count ??
                          0),
                      0,
                    );

                    return (
                      <li key={key} className="checkout-summary__list-item">
                        <div className="checkout-summary__list-item-info">
                          {!slotNumber
                            ? t('expressDelivery')
                            : t('slot', { num: slotNumber })}{' '}
                          <span className="checkout-summary__list-item-info-item-count">
                            {items} {t(items > 1 ? 'items' : 'item')}
                          </span>
                        </div>
                        <div className="checkout-summary__list-item-price">
                          {isDeliveryFree ? (
                            <p className="free">{t('deliveryFreeShort')}</p>
                          ) : (
                            <p className="slot-list__slot-fee">
                              {deliveryPrice}
                            </p>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </Collapsible>
            </div>
          )}
          <div className="checkout-summary__item _no-border">
            <ul className="checkout-taxes__list">
              {catalogStore.totalCartPrice.taxPercent > 0 && (
                <li className="checkout-taxes__list-item">
                  <div className="checkout-taxes__list-item-title">
                    <p className="">
                      {t('phrases:vat', {
                        percent: catalogStore.totalCartPrice.taxPercent,
                      })}
                    </p>
                  </div>
                  <Price
                    price={mainStore.convertPenceToPounds(
                      catalogStore.totalCartPrice.taxAmount,
                    )}
                    classNameCurrentPrice="checkout-taxes__list-item-price"
                  />
                </li>
              )}
              {catalogStore.promocode.success === true && (
                <li className="checkout-taxes__list-item">
                  <div className="checkout-taxes__promocode-code">
                    <p className="checkout-taxes__promocode-code-title">
                      {t('promocode')}
                    </p>
                    <p className="checkout-taxes__promocode-code-value">
                      {catalogStore.promocode.value}
                    </p>
                  </div>
                  <Price
                    price={`-${catalogStore.totalCartPrice.promocodeDiscount}`}
                    classNameCurrentPrice="checkout-taxes__promocode-discount"
                  />
                </li>
              )}
            </ul>
          </div>
          <div className="checkout-summary__item">
            <Promocode />
          </div>
        </div>
        <div
          className={classes([
            'checkout-sum',
            isPickUpOrder && [styles.bgWhite, styles.pickUpCheckoutSum],
          ])}
        >
          <div className="checkout-sum-title">{t('total')}</div>
          <Price
            price={catalogStore.finalPrice}
            classNameCurrentPrice="checkout-sum-val"
          />
        </div>
        {company.buttonOutPanelCheckout && (
          <p className="checkout-summary__terms">
            {t('phrases:byOrdering')}{' '}
            <Link to={'/legals'} state={{ source: 'checkout' }}>
              {t('termsAndConditions')}
            </Link>
          </p>
        )}
      </div>
    </>
  );
};

export default observer(CheckoutContentMobile);
