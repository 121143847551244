import { motion, useTransform } from 'framer-motion';
import { classes } from 'html-classes';
import { forwardRef } from 'react';

import styles from './bottomSheetDrawer.module.scss';
import { useSheetContext } from './context';
import { useDragConstraints } from './hooks';
import { SheetDraggableProps } from './types';
import { mergeRefs } from './utils';

const SheetHeader = forwardRef<any, SheetDraggableProps>(
  ({ children, style, disableDrag, className, ...rest }, ref) => {
    const { indicatorRotation, dragProps } = useSheetContext();
    const { constraintsRef, onMeasureDragConstraints } = useDragConstraints();
    const _dragProps = disableDrag ? undefined : dragProps;

    const indicator1Transform = useTransform(
      indicatorRotation,
      (r) => `translateX(2px) rotate(${r}deg)`,
    );

    const indicator2Transform = useTransform(
      indicatorRotation,
      (r) => `translateX(-2px) rotate(${-1 * r}deg)`,
    );

    return (
      <motion.div
        {...rest}
        ref={mergeRefs([ref, constraintsRef])}
        className={classes([styles.headerWrapper, className])}
        style={{ ...style }}
        {..._dragProps}
        dragConstraints={constraintsRef}
        onMeasureDragConstraints={onMeasureDragConstraints}
      >
        {children || (
          <div className={classes(['react-modal-sheet-header', styles.header])}>
            <motion.span
              className={classes([
                'react-modal-sheet-drag-indicator',
                styles.indicator,
              ])}
              style={{ transform: indicator1Transform }}
            />
            <motion.span
              className={classes([
                'react-modal-sheet-drag-indicator',
                styles.indicator,
              ])}
              style={{ transform: indicator2Transform }}
            />
          </div>
        )}
      </motion.div>
    );
  },
);

SheetHeader.displayName = 'SheetHeader';

export default SheetHeader;
