import { t } from 'i18next';

import { IMAGES } from '../../../constants/images';

export default () => [
  {
    img: IMAGES.onboarding.swiftDelivery,
    textTitle: t('onboarding:swiftDelivery:title'),
    textDescription: t('onboarding:swiftDelivery:description'),
    position: 'top',
  },
  {
    img: IMAGES.onboarding.spotTheLight,
    textTitle: t('onboarding:spotTheLight:title'),
    textDescription: t('onboarding:spotTheLight:description'),
    position: 'top',
  },
  /*{
      img: IMAGES.onboarding.unlockComplementary,
      textTitle: t('onboarding:unlockComplementary:title'),
      textDescription: t('onboarding:unlockComplementary:description'),
    },*/
  {
    img: IMAGES.onboarding.trackDelivery,
    textTitle: t('onboarding:trackDelivery:title'),
    textDescription: t('onboarding:trackDelivery:description'),
    position: 'top',
  },
  {
    img: IMAGES.onboarding.shareThoughts,
    textTitle: t('onboarding:shareThoughts:title'),
    textDescription: t('onboarding:shareThoughts:description'),
    position: 'top',
  },
  {
    img: IMAGES.onboarding.referFriend,
    textTitle: t('onboarding:referFriend:title'),
    textDescription: t('onboarding:referFriend:description'),
    position: 'top',
  },
  {
    img: IMAGES.onboarding.signUp,
    textTitle: t('onboarding:sign_up:title'),
    textDescription: t('onboarding:sign_up:description'),
    position: 'top',
  },
];
