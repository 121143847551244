import { zodResolver } from '@hookform/resolvers/zod';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { MessageRequest } from '~/api/Message';
import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import InputText from '~/components/InputText/InputText';
import Textarea from '~/components/Textarea/Textarea';
import { useGlobal } from '~/hooks/useGlobal';
import { mainStore } from '~/stores/MainStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './ContactUs.module.scss';
import { ContactUsSchema, ContactUsValidationSchema } from './validators';

const ContactUs = () => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const [phoneVal, setPhoneVal] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ContactUsSchema>({
    resolver: zodResolver(ContactUsValidationSchema),
  });
  const firstNameProps = register('name');
  const emailProps = register('email');
  const phoneProps = register('phone');
  const messageProps = register('message');

  const handleForm = async (data: ContactUsSchema) => {
    try {
      await MessageRequest.send({
        name: data.name,
        phone: data.phone,
        email: data.email,
        message: data.message,
      });

      mainStore.pushAlert('success', t('sentMessage'));
      setValue('name', '');
      setValue('email', '');
      setValue('phone', '');
      setValue('message', '');
      setPhoneVal('');
    } catch (_) {
      mainStore.pushAlert('error', t('errors:oops'));
    }
  };

  const handleCodeChange = (val: string) => {
    setValue('phone', val);
    setPhoneVal(val);
  };

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
        <title>{t('phrases:contactUsMetaTitle')}</title>
        <meta
          name="description"
          content={t('phrases:contactUsMetaDescription')}
        />
        <meta name="keywords" content={t('phrases:contactUsMetaKeywords')} />
      </Helmet>
      <MobileHeaderLayout
        showTopCart
        content={<MobileHeaderTitle text={t('contactUs')} />}
      />
      <div className="content-layout">
        {!isMobile && (
          <div>
            <h1 className={styles.heading}>{t('contactUsTitle')}</h1>
            <p className={styles.description}>{t('contactUsDescription')}</p>
          </div>
        )}
        <div className={styles.root}>
          <div className={styles.contact_info_container}>
            {isMobile && (
              <div>
                <h1 className={styles.heading}>{t('contactUsTitle')}</h1>
                <p className={styles.description}>
                  {t('contactUsDescription')}
                </p>
              </div>
            )}
            <div className={styles.title}>
              <h2 className={styles.title__primary}>{t('sendUsMessage')}</h2>
              <p className={styles.title__secondary}>{t('hereToHelp')}</p>
            </div>
            <form className={styles.form} onSubmit={handleSubmit(handleForm)}>
              <InputText
                classNameWrapper={styles.input_wrapper}
                classNameInput={styles.input}
                placeholder={t('messageNamePlaceholder')}
                externalLabel={t('messageName')}
                error={errors.name?.message ?? ''}
                refObj={firstNameProps.ref}
                {...firstNameProps}
              />
              <InputText
                classNameWrapper={styles.input_wrapper}
                classNameInput={styles.input}
                placeholder={t('messageEmailPlaceholder')}
                externalLabel={t('messageEmail')}
                error={errors.email?.message ?? ''}
                refObj={emailProps.ref}
                {...emailProps}
              />
              <InputText
                classNameWrapper={styles.input_wrapper}
                classNameInput={styles.input}
                placeholder={t('messagePhonePlaceholder')}
                externalLabel={t('messagePhone')}
                error={errors.phone?.message ?? ''}
                refObj={phoneProps.ref}
                {...phoneProps}
              >
                <IMaskInput
                  className={classes(['input-text', styles.input])}
                  mask="+000000000000000"
                  type="tel"
                  value={phoneVal}
                  onAccept={handleCodeChange}
                  autoComplete="phone-number"
                  placeholder={t('messagePhonePlaceholder')}
                />
              </InputText>
              <Textarea
                classNameWrapper={styles.input_wrapper}
                classNameInput={styles.textarea}
                placeholder={t('messageMessagePlaceholder')}
                externalLabel={t('messageMessage')}
                error={errors.message?.message ?? ''}
                refObj={messageProps.ref}
                {...messageProps}
              />
              <button
                className={classes(['button _primary _big', styles.button])}
                type="submit"
              >
                {t('sendMessage')}
              </button>
              <p className={styles.info}>{t('contactUsDetails')}</p>
            </form>
          </div>
          <div>
            {!isMobile && (
              <h2 className={styles.title__primary}>{t('reachOutToUs')}</h2>
            )}
            <div className={styles.info_container}>
              <div className={styles.info_container__content}>
                <a
                  className={styles.phone}
                  href={`tel:${company.config.phoneNumber}`}
                >
                  {company.config.phoneNumber}
                </a>

                <a
                  href={company.config.links.whatsapp}
                  className={classes(['button _bordered', styles.whatsapp])}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon type="whatsapp" size={24} />
                  <p>{t('contactUs')}</p>
                </a>
                <div className={styles.social}>
                  {company.config.links.instagram && (
                    <a
                      href={company.config.links.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon type="insta" size={20} />
                    </a>
                  )}
                  {company.config.links.facebook && (
                    <a
                      href={company.config.links.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon type="fb" size={20} />
                    </a>
                  )}
                  {company.config.links.tiktok && (
                    <a
                      href={company.config.links.tiktok}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon type="tiktok" size={20} />
                    </a>
                  )}
                  {company.config.links.youtube && (
                    <a
                      href={company.config.links.youtube}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon type="youtube" size={20} />
                    </a>
                  )}
                </div>
                <a
                  className={classes(['button _bordered', styles.email])}
                  href={`mailto:${company.config.email}`}
                >
                  <Icon type="envelope" size={24} />
                  <p>{company.config.email}</p>
                </a>
                <span className={styles.address}>
                  <Icon type="location" size={24} />
                  <p>{company.config.address}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ContactUs);
