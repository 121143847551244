import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { company } from '~/company/Company';
import CityDrinksStepData from '~/company/configs/citydrinks/onboarding';
import DefaultStepData from '~/company/configs/default/onboarding';
import { IMAGES } from '~/constants/images';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';

import { userStore } from '../../stores/UserStore';

export default observer(() => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const { closeModal } = useModal();

  const handleEnter = () => {
    userStore.setIsAdult(true);
    closeModal();
  };

  const STEPS_DATA = company.variant({
    '1': CityDrinksStepData(),
    '2': DefaultStepData(),
  });

  useEffect(() => {
    STEPS_DATA.forEach((step) => {
      const img = new Image();
      img.src = step.img;
    });
  }, [STEPS_DATA]);

  const logo = IMAGES.ageRestriction[isMobile ? 'white' : 'color'];

  return (
    <div className="modal-age-restricted">
      <img className="modal-age-restricted__logo" src={logo} alt="" />
      <div className="modal-age-restricted__text">{t('phrases:ageOver21')}</div>
      <div className="modal-age-restricted__text">
        <Trans
          i18nKey="phrases:byEnteringConfirmAge"
          components={{
            a: (
              <a
                href={
                  company.config.links.legals?.generalTradingPolicy?.link || '/'
                }
              />
            ),
          }}
        />
      </div>
      <button
        className="button _primary _med _block modal-age-restricted__btn-enter"
        onClick={handleEnter}
      >
        {t('enter')}
      </button>
    </div>
  );
});
