import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { isString } from 'lodash-es';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable, isHydrated } from 'mobx-persist-store';
import * as qs from 'qs';

import { BIRequests } from '~/api/BI';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { ApiErrorResponse } from '~/api/Requests';
import { sendAnalytics } from '~/services/firebase';
import { isAndroid, isIOS } from '~/utils/platform';

import { company } from '../company/Company';

import { catalogStore } from './CatalogStore';
import { checkoutStore } from './CheckoutStore/CheckoutStore';
import { OUTDATED_APP_POPUP_INTERVAL, CURRENCY_SYMBOL } from './constants';
import { storage as localStorage } from './LocalStorage';
import { orderStore } from './OrderStore';
import { storiesStore } from './StoriesStore';
import { userStore } from './UserStore';

type DeviceInfo = {
  platform: {
    ios?: {
      buildNumber: null;
      model: string | null;
      platform: string | null;
      systemVersion: string | null;
      userInterfaceIdiom: string | null;
    };
  };
  statusBarHeight?: number;
  bottomBarHeight?: number;
  isFirstLaunch: boolean;
  deviceInfo: {
    device_id: string | null;
    player_id: string | null;
  };
  nativeAppVersion: string;
  nativeBuildVersion: string;
  applePay: boolean;
  googlePay: boolean;
  codePushVersion: string;
};

type AlertTypes = 'error' | 'success';

type Alert = {
  type: AlertTypes;
  message: string;
  id: string;
  onDismiss?: () => void;
  className?: string;
};

type Analytics = {
  lastOrderId: string | null;
  lastOrderCreatedAt: string;
  lastOrderEta: string;
  lastOrderStatus: string;
  lastOrderStatusDate: string;
  firstOrderCreatedAt: string;
  promocodeUsedCount: number;
  totalSpent: string;
  totalOrders: number;
  totalSuccessfulOrders: number;
};

export type Environment = 'production' | 'staging' | 'development' | 'stress';

export type GetRequestObj = Record<
  string,
  string | number | boolean | undefined
>;

export enum StartVerificationLocation {
  CART = 'cart',
  CABINET = 'cabinet',
  DEFAULT = '',
}

class MainStore {
  isApplePay = false;
  isGooglePay = false;
  pageScrollY: Record<string, number> = {};
  alerts: Alert[] = [];
  isShowETAPopover = false;
  isShowServiceFeePopover = false;
  isShowNeedChangeAddressPopover = false;
  isShowMainMenu = false;
  isVisibleMainMenu = true;
  isShowGiftOutStockPopover = false;
  isShowGiftAlreadyReceivedPopover = false;
  isZeroCartPopover = false;
  isInvalidPromocodePopover = false;
  newDeliveryMethodForPromocodePopover: Nullable<
    ETADeliveryMethodType | 'none'
  > = null;
  isAgeRestrictionPopover = false;
  isNotFindProductPopover = false;
  isOutdatedAppPopover = false;
  isRateOrderPopover = false;
  isPayTipPopover = false;
  isFreeDeliveryPopover = false;
  isChangeAddressPopover = false;
  outdatedAppPopoverExpired: number | null = null;
  isGeneralErrorPopover = false;
  isNoInternetPopover = false;
  isFuckupPopover = false;
  isSoonClosingPopover = false;
  lastDateShowSoonClosePopover: string | null = null;
  isShopClosedPopover = false;
  lastDateShowShopClosedPopover: string | null = null;
  rnBridgeCallbacks: Record<
    string,
    { onSuccess?: (data: any) => void; onError?: (data: any) => void }
  > = {};
  isRN = !import.meta.env.SSR && Boolean(window.ReactNativeWebView);
  isAppInstalled = false;
  isRNReady = !import.meta.env.SSR && !window.ReactNativeWebView;
  startVerificationLocation: StartVerificationLocation =
    StartVerificationLocation.DEFAULT;
  inBackground = false;
  isBlockedPush = false;
  isShowSubscribePopover = false;
  appVersion = '';
  appBuildVersion = '';
  appPlatform: 'ios' | 'android' | '' = '';
  appCodePushVersion = '';
  environment: Environment = import.meta.env.REACT_APP_ENV || 'development';
  recaptchaSiteID = import.meta.env.REACT_APP_RECAPTCHA_ID || '';
  analytics: Analytics = {
    lastOrderId: null,
    lastOrderCreatedAt: '',
    lastOrderEta: '',
    lastOrderStatus: '',
    lastOrderStatusDate: '',
    firstOrderCreatedAt: '',
    promocodeUsedCount: 0,
    totalSpent: '0',
    totalOrders: 0,
    totalSuccessfulOrders: 0,
  };
  deferedDeeplink = '';
  deferedRateOrder = false;
  analyticsOnProductsShown: Record<string, any>[] = [];
  isSafeRequest = false;
  loyaltyProgramSeen = false;
  onboardingSeen = false;
  digitsAfterComma = 2;

  constructor() {
    makeAutoObservable(this, {
      pageScrollY: false,
      rnBridgeCallbacks: false,
      analyticsOnProductsShown: false,
    });

    makePersistable(this, {
      name: 'MainStore',
      properties: [
        'analytics',
        'outdatedAppPopoverExpired',
        'isBlockedPush',
        'lastDateShowSoonClosePopover',
        'lastDateShowShopClosedPopover',
        'loyaltyProgramSeen',
        'onboardingSeen',
        'startVerificationLocation',
        'isAppInstalled',
      ],
      storage: localStorage,
    }).catch((error) => error && console.error(error));
  }

  // Getters
  get isAllStoresSynchronized(): boolean {
    return (
      isHydrated(this) &&
      userStore.isSynchronized &&
      catalogStore.isSynchronized &&
      orderStore.isSynchronized &&
      checkoutStore.isSynchronized &&
      storiesStore.isSynchronized
    );
  }

  get isOpenNewDeliveryMethodForPromocode() {
    return (
      mainStore.newDeliveryMethodForPromocodePopover !== null &&
      mainStore.newDeliveryMethodForPromocodePopover !== 'none'
    );
  }

  // Setters
  pushAlert(
    type: AlertTypes,
    message: string,
    onDismiss?: () => void,
    className?: string,
  ) {
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    if (this.alerts.length && this.alerts.slice(-1)[0].message === message) {
      this.alerts.pop();
    }
    this.alerts.push({
      type,
      message,
      onDismiss,
      className,
      id: this.getPseudoId(),
    });
  }

  popAlert(): Alert | null {
    return this.alerts.pop() || null;
  }

  setStartVerificationLocation(location: StartVerificationLocation) {
    this.startVerificationLocation = location;
  }

  setIsShowETAPopover(flag: boolean) {
    this.isShowETAPopover = flag;
  }

  setIsAppInstalled(flag: boolean) {
    this.isAppInstalled = flag;
  }

  setIsShowServiceFeePopover(flag: boolean) {
    this.isShowServiceFeePopover = flag;
  }

  setIsNeedChangeAddressPopover(flag: boolean) {
    this.isShowNeedChangeAddressPopover = flag;
  }

  setIsShowGiftOutStockPopover(flag: boolean) {
    this.isShowGiftOutStockPopover = flag;
  }

  setIsShowGiftAlreadyReceivedPopover(flag: boolean) {
    this.isShowGiftAlreadyReceivedPopover = flag;
  }

  setIsZeroCartPopover(flag: boolean) {
    this.isZeroCartPopover = flag;
  }

  setIsInvalidPromocodePopover(flag: boolean) {
    this.isInvalidPromocodePopover = flag;
  }

  setNewDeliveryMethodForPromocodePopover(
    flag: Nullable<ETADeliveryMethodType | 'none'>,
  ) {
    this.newDeliveryMethodForPromocodePopover = flag;
  }

  setIsAgeRestrictionPopover(flag: boolean) {
    this.isAgeRestrictionPopover = flag;
  }

  setIsNotFindProductPopover(flag: boolean) {
    this.isNotFindProductPopover = flag;
  }

  setIsRateOrderPopover(flag: boolean) {
    this.isRateOrderPopover = flag;
  }

  setIsPayTipPopover(flag: boolean) {
    this.isPayTipPopover = flag;
  }

  setIsFreeDeliveryPopover(flag: boolean) {
    this.isFreeDeliveryPopover = flag;
  }

  setIsChangeAddressPopover(flag: boolean) {
    this.isChangeAddressPopover = flag;
  }

  setIsOutdatedAppPopover(flag: boolean) {
    if (this.isRN && flag && !this.appVersion) {
      return;
    }
    if (
      flag &&
      this.outdatedAppPopoverExpired &&
      this.outdatedAppPopoverExpired > Date.now()
    ) {
      return;
    }
    this.outdatedAppPopoverExpired = Date.now() + OUTDATED_APP_POPUP_INTERVAL;
    this.isOutdatedAppPopover = flag;
  }

  setIsGeneralErrorPopover(flag: boolean) {
    this.isGeneralErrorPopover = flag;
  }

  setIsNoInternetPopover(flag: boolean) {
    this.isNoInternetPopover = flag;
  }

  setIsFuckupPopover(flag: boolean) {
    this.isFuckupPopover = flag;
  }

  setIsSoonClosingPopover(flag: boolean) {
    this.isSoonClosingPopover = flag;
  }

  setIsShopClosedPopover(flag: boolean) {
    this.isShopClosedPopover = flag;
  }

  setLastDateShowSoonClosePopover(date: string) {
    this.lastDateShowSoonClosePopover = date;
  }

  setLastDateShowShopClosedPopover(date: string) {
    this.lastDateShowShopClosedPopover = date;
  }

  setPageScrollY(pageName: string, scrollY: number) {
    this.pageScrollY[pageName] = scrollY;
  }

  setIsRNReady(flag: boolean) {
    this.isRNReady = flag;
  }

  setInBackground(flag: boolean) {
    this.inBackground = flag;
  }

  setIsBlockedPush(flag: boolean) {
    this.isBlockedPush = flag;
  }

  setIsShowSubscribePopover(flag: boolean) {
    /*if (![CompanyName.Jiffy].includes(company.name)) {
      this.isShowSubscribePopover = false;
      return;
    }*/
    this.isShowSubscribePopover = flag;
  }

  setIsShowMainMenu(flag: boolean) {
    this.isShowMainMenu = flag;
  }

  setIsVisibleMainMenu(flag: boolean) {
    this.isVisibleMainMenu = flag;
  }

  setDeferedDeeplink(link: string) {
    this.deferedDeeplink = link;
  }

  setDeferedRateOrder(flag: boolean) {
    this.deferedRateOrder = flag;
  }

  setLoyaltyProgramSeen(flag: boolean) {
    this.loyaltyProgramSeen = flag;
  }

  setOnboardingSeen(flag: boolean) {
    this.onboardingSeen = flag;
  }

  setDigitsAfterComma(val: number) {
    this.digitsAfterComma = val;
  }

  resetAnalytics() {
    this.analytics = {
      lastOrderId: null,
      lastOrderCreatedAt: '',
      lastOrderEta: '',
      lastOrderStatus: '',
      lastOrderStatusDate: '',
      firstOrderCreatedAt: '',
      promocodeUsedCount: 0,
      totalSpent: '0',
      totalOrders: 0,
      totalSuccessfulOrders: 0,
    };
  }

  // Actions
  initRN(deviceInfo: DeviceInfo) {
    let version = `${deviceInfo.nativeAppVersion}-${deviceInfo.nativeBuildVersion}`;
    if (deviceInfo.codePushVersion) {
      version += `(${deviceInfo.codePushVersion})`;
    }
    Sentry.setTag('Version', version);
    if (deviceInfo.bottomBarHeight !== undefined) {
      document.documentElement.style.setProperty(
        '--offset-bottom',
        deviceInfo.bottomBarHeight + 'px',
      );
    }
    if (deviceInfo.platform.ios) {
      this.appPlatform = 'ios';
    } else {
      this.appPlatform = 'android';
      this.isSafeRequest = true;
    }
    if (!userStore.deliveryAddress) {
      userStore.setIsFirstLaunch(true);
    } else if (deviceInfo.isFirstLaunch) {
      userStore.setIsFirstLaunch(true);
    }
    userStore.setDeviceId(deviceInfo.deviceInfo.device_id || '');
    userStore.setPlayerId(deviceInfo.deviceInfo.player_id || '');
    this.appVersion = deviceInfo.nativeAppVersion;
    this.appBuildVersion = deviceInfo.nativeBuildVersion;
    this.isApplePay = deviceInfo.applePay ?? false;
    this.isGooglePay = deviceInfo.googlePay ?? false;
    this.appCodePushVersion = deviceInfo.codePushVersion;
    this.checkFuckup(deviceInfo.deviceInfo.device_id || '');
  }

  checkFuckup(deviceId: string) {
    if (!deviceId || deviceId === '00000000-0000-0000-0000-000000000000') {
      this.setIsFuckupPopover(true);
      return;
    }
  }

  convertObjToGet(obj: GetRequestObj, trimEmpty = true): string {
    const keys: string[] = Object.keys(obj);
    let output = '';
    if (!keys.length) {
      return output;
    }
    for (let i = 0; i < keys.length; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (obj[keys[i]] === undefined) {
        continue;
      }
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (trimEmpty && obj[keys[i]] === '') {
        continue;
      }
      output += `&${keys[i]}=${encodeURIComponent(
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        obj[keys[i]] as string | number | boolean,
      )}`;
    }
    if (output) {
      output = '?' + output.slice(1);
    }

    /**
     * FYI: why not used qs (Mark E.)?
     *
     * trimEmpty not used
     */
    return `?${qs.stringify(obj)}`;
  }

  formatRange(
    start: string,
    props?: { end?: string; symbol?: string },
  ): string {
    const { end, symbol = '' } = props ?? {};
    const delimiter = '–';

    if (!start) {
      return '';
    }

    if (!end) {
      return `${symbol}${start.replace(
        /\s?[-—]\s?/gi,
        `${delimiter}${symbol}`,
      )}`;
    }

    return `${symbol}${start}${delimiter}${symbol}${end}`;
  }

  addZeroFraction(number: number | string, isTrimTrailingZeros = true): string {
    number = number.toString().replace(',', '.');
    const splitNumber = number.split('.');
    if (!splitNumber[1]) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      return isTrimTrailingZeros
        ? splitNumber[0]
        : `${splitNumber[0]}.${''.padEnd(this.digitsAfterComma, '0')}`;
    }
    if (isTrimTrailingZeros) {
      return parseFloat(number).toString();
    } else {
      return `${splitNumber[0]}.${splitNumber[1].padEnd(
        this.digitsAfterComma,
        '0',
      )}`;
    }
  }

  addLeadingZero(number: number): string {
    if (number > 9 || number < -9) {
      return number.toString();
    }
    if (number < 0) {
      return `-0${Math.abs(number)}`;
    }

    return `0${number}`;
  }

  toFloat(number: number | string): number {
    return parseFloat(number?.toString().replace(',', '.')) || 0;
  }

  isZero(number: number | string): boolean {
    return !this.toFloat(number);
  }

  additionCurrency(a: string | number, b: string | number): string {
    if (this.isZero(a) && this.isZero(b)) {
      return '0';
    }

    return this.convertPenceToPounds(
      this.convertPoundsToPence(a) + this.convertPoundsToPence(b),
    );
  }

  /**
   * TODO: THIS CODE SHOULD BE A PART OF MONEY CLASS
   * "subtractionCurrency" does not tell that it  converts money to pounds in result
   * Very dangerous side effect
   * */
  subtractionCurrency(a: string | number, b: string | number): string {
    if (this.isZero(a) && this.isZero(b)) {
      return '0';
    }

    return this.convertPenceToPounds(
      this.convertPoundsToPence(a) - this.convertPoundsToPence(b),
    );
  }

  convertPoundsToPence(number: number | string): NumberInt {
    if (number === undefined) {
      return number;
    }
    if (this.isZero(number)) {
      return 0;
    }
    return parseInt(this.addZeroFraction(number, false).replace('.', ''), 10);
  }

  convertPenceToPounds(number: NumberInt | string): string {
    if (number === undefined) {
      return number;
    }
    if (this.isZero(number)) {
      return this.addZeroFraction('0', company.isTrimTrailingZeros);
    }
    number = number.toString();
    const isNegative = number[0] === '-';
    if (isNegative) {
      number = number.slice(1);
    }
    number = number.padStart(this.digitsAfterComma + 1, '0');
    const letters = number.split('');
    letters.splice(this.digitsAfterComma * -1, 0, '.');
    return isNegative
      ? '-' +
          this.addZeroFraction(letters.join(''), company.isTrimTrailingZeros)
      : this.addZeroFraction(letters.join(''), company.isTrimTrailingZeros);
  }

  addPcs(num: number): string {
    return `${num}x`;
  }

  getPseudoId(): string {
    return (
      Date.now().toString().slice(-6) + Math.random().toString(32).slice(2, 12)
    );
  }

  prettyPhoneNumber(phone: string): string {
    if (!phone.length) {
      return '';
    }
    if (phone.startsWith('44')) {
      return phone
        .replace(/\D/g, '')
        .slice(-10)
        .replace(/(\d{4})(\d{3})(\d{3})/, '+44 ($1) $2-$3');
    }
    if (phone.startsWith('7')) {
      return phone
        .replace(/\D/g, '')
        .replace(/(\d{1,2})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
    }
    return '+' + phone;
  }

  calcDiscountPercentage(
    price: NumberInt,
    discountPrice: NumberInt,
  ): NumberInt {
    if (this.isZero(price) || this.isZero(discountPrice)) {
      return 0;
    }
    const discount = 100 - Math.ceil((discountPrice * 100) / price);
    return discount > 0 ? discount : 0;
  }

  flatArray(arr: any[], depth = 1): any[] {
    return depth > 0
      ? arr.reduce(
          (acc, val) =>
            acc.concat(
              Array.isArray(val) ? this.flatArray(val, depth - 1) : val,
            ),
          [],
        )
      : arr.slice();
  }

  sendToRN(
    targetFunc: string,
    data?: Record<string, any> | string | null,
    success?: (data: any) => void,
    error?: (data: any) => void,
  ) {
    if (targetFunc === 'firebaseAnalytics' && data) {
      const source = isIOS() ? 'iOS' : isAndroid() ? 'android' : 'web';

      if (isString(data)) {
        return sendAnalytics(data, { source });
      } else if (isString(data.name)) {
        return sendAnalytics(data.name, { ...data.params, source });
      }
    }

    if (!this.isRN) {
      return;
    }
    const msgObj: { targetFunc: string; data: any; msgId?: string } = {
      targetFunc,
      data: data || {},
    };
    if (success || error) {
      msgObj.msgId = this.getPseudoId();
      this.rnBridgeCallbacks[msgObj.msgId] = {
        onSuccess: success,
        onError: error,
      };
    }
    try {
      window.ReactNativeWebView?.postMessage(JSON.stringify(msgObj));
    } catch (e) {
      if (error) {
        error(msgObj);
        if (msgObj.msgId) {
          delete this.rnBridgeCallbacks[msgObj.msgId];
        }
      }
      console.error(e);
    }
  }

  setSentryUser({
    id,
    name,
    email,
    phone,
  }: {
    id?: string;
    name?: string;
    email?: string;
    phone?: string;
  }) {
    const userData: Record<string, string> = {
      ip_address: '{{auto}}',
    };
    if (id) {
      userData.id = id;
    }
    if (name) {
      userData.name = name;
    }
    if (email) {
      userData.email = email;
    }
    if (phone) {
      userData.phone = phone;
    } else if (userStore.personalData.phone) {
      userData.phone = userStore.personalData.phone;
    }
    Sentry.setUser(userData);
  }

  parseErrorMessage(
    error: AxiosError<ApiErrorResponse>,
    context: string,
  ): string {
    const { response } = error;
    if (!response) {
      if (error.code === 'ECONNABORTED') {
        return '';
      }
      if (error.message === 'Network Error') {
        this.setIsNoInternetPopover(true);
        return '';
      }
      return '';
    }
    if (response.data?.message && typeof response.data?.message === 'string') {
      return response.data.message;
    }
    if (response.data?.errors?.length) {
      if (typeof response.data.errors[0] === 'string') {
        return response.data.errors[0];
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      } else if (response.data.errors[0].message) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        return response.data.errors[0].message;
      }
    }
    Sentry.withScope((scope) => {
      scope.setExtras({
        context,
        message: error.message || undefined,
        error: response,
      });
      Sentry.captureMessage('[Unknown error]', 'error');
    });
    if (response.status >= 500) {
      this.setIsGeneralErrorPopover(true);
    }
    return '';
  }

  clearAllCache() {}

  yaMetrika(target: string, params: Record<string, any>) {
    const yaToken = company.config.apis?.yaMetrika?.token;
    if (!window.ym || !yaToken) {
      return;
    }
    window.ym(yaToken as number, 'reachGoal', target, params);
  }

  validateEmail(email: string): boolean {
    email = email.trim();
    if (!email) {
      return false;
    }

    const emailParts = email.split('@');

    if (emailParts.length !== 2) {
      return false;
    }

    const [name = '', domain = ''] = emailParts;

    if (name.length > 64 || domain.length > 255) {
      return false;
    }
    if (domain.split('.').some((part) => part.length > 63)) {
      return false;
    }
    return new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(["'].+["']))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ).test(email);
  }

  isSuitableAppVersion(reqVer: string): boolean {
    if (!reqVer) {
      return false;
    }
    if (!this.isRN) {
      return true;
    }
    if (!this.appVersion) {
      return false;
    }
    const reqVerSplit = reqVer.split('.');
    const appVerSplit = this.appVersion.split('.');
    if (reqVerSplit.length < 3 || appVerSplit.length < 3) {
      return false;
    }
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    if (parseInt(reqVerSplit[0], 10) < parseInt(appVerSplit[0], 10)) {
      return true;
    }
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    if (parseInt(reqVerSplit[0], 10) > parseInt(appVerSplit[0], 10)) {
      return false;
    }
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    if (parseInt(reqVerSplit[1], 10) < parseInt(appVerSplit[1], 10)) {
      return true;
    }
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    if (parseInt(reqVerSplit[1], 10) > parseInt(appVerSplit[1], 10)) {
      return false;
    }
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    return parseInt(reqVerSplit[2], 10) <= parseInt(appVerSplit[2], 10);
  }

  isOverdueWarehouseTime(): boolean {
    if (!orderStore.etaCalculation) {
      return false;
    }
    if (
      orderStore.etaCalculation.warehouse.availability.nextAvailabilityDay >
      orderStore.etaCalculation.meta.todayAsIndex
    ) {
      return false;
    }
    const openTime = orderStore.etaCalculation.warehouse.availability.opening
      .replace(/^00/, '24')
      .split(':');
    const closeTime = orderStore.etaCalculation.warehouse.availability.closing
      .replace(/^00/, '24')
      .split(':');
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    const curTime = orderStore.etaCalculation.meta.serverTime
      .split(' ')[1]
      .replace(/^00/, '24')
      .split(':');
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    const min = parseInt(openTime[0], 10) * 60 + parseInt(openTime[1], 10);
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    const max = parseInt(closeTime[0], 10) * 60 + parseInt(closeTime[1], 10);
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    const cur = parseInt(curTime[0], 10) * 60 + parseInt(curTime[1], 10);
    return cur > min && cur < max;
  }

  checkAndSetSubscribePopover(type: 'all' | 'onlyPush') {
    if (!this.isRN) {
      return;
    }
    let allEnabled;
    const personalisedPush =
      userStore.personalData.subscriptionFlags['personalised']?.push || false;
    const discountsPush =
      userStore.personalData.subscriptionFlags['discounts']?.push || false;
    const promotionsPush =
      userStore.personalData.subscriptionFlags['third_party']?.push || false;
    const allPushEnabled = personalisedPush && discountsPush && promotionsPush;
    if (type === 'all') {
      const personalisedEmail =
        userStore.personalData.subscriptionFlags['personalised']?.email ||
        false;
      const discountsEmail =
        userStore.personalData.subscriptionFlags['discounts']?.email || false;
      const promotionsEmail =
        userStore.personalData.subscriptionFlags['third_party']?.email || false;
      const allEmailEnabled = userStore.personalData.email
        ? personalisedEmail && discountsEmail && promotionsEmail
        : true;
      allEnabled = allPushEnabled && allEmailEnabled;
    } else {
      allEnabled = allPushEnabled;
    }
    if (!allEnabled) {
      this.setIsShowSubscribePopover(true);
    }
  }

  sendProductsShownToBI() {
    if (!this.analyticsOnProductsShown.length) {
      return;
    }
    BIRequests.sendAnalytics(
      'Catalog: product snippet shown',
      this.analyticsOnProductsShown,
    )
      .then(() => {
        runInAction(() => {
          this.analyticsOnProductsShown = [];
        });
      })
      .catch((error) => error && console.error(error));
  }

  sendAnalytics(
    targetFunc: ('BI' | 'analytics' | 'yaMetrika' | 'firebase')[],
    data: { name: string; params: GetRequestObj },
  ) {
    for (let i = 0; i < targetFunc.length; i++) {
      switch (targetFunc[i]) {
        case 'BI':
          BIRequests.sendAnalytics(data.name, data.params).catch(
            (error) => error && console.error(error),
          );
          break;
        case 'analytics':
          this.sendToRN('analytics', { name: data.name, params: data.params });
          break;
        case 'firebase':
          this.sendToRN('firebaseAnalytics', {
            name: data.name.toLowerCase().replace(/:\s|\s/g, '_'),
            params: data.params,
          });
          break;
        case 'yaMetrika':
          this.yaMetrika(data.name, data.params);
          break;
      }
    }
  }

  addCurrencySymbol(val: string | number, currency?: string): string {
    if (this.isZero(val)) {
      val = this.addZeroFraction(val, company.isTrimTrailingZeros);
    }
    const currencySymbol = currency
      ? CURRENCY_SYMBOL[currency] || ''
      : orderStore.currencySymbol;
    if ((currency || orderStore.currency) === 'aed') {
      return val + currencySymbol;
    } else {
      return currencySymbol + val;
    }
  }

  sortNumbers(a: number, b: number, sorting: 'asc' | 'desc' = 'asc'): number {
    const result = a < b ? -1 : a > b ? 1 : 0;
    if (!result || sorting === 'asc') {
      return result;
    }
    return result * -1;
  }

  sortAlphabet(a: string, b: string): number {
    if (!a || !b) {
      return 0;
    }
    return a.localeCompare(b);
  }

  isColorLight(color: string): boolean {
    if (!color) {
      return false;
    }
    if (color.toLowerCase() === 'white') {
      return true;
    }
    const split = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(color);
    if (split?.length !== 4) {
      return false;
    }
    const [, r, g, b] = split.map((x) =>
      parseInt(x.length === 1 ? x + x : x, 16),
    );
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    return 0.299 * r + 0.587 * g + 0.114 * b > 230;
  }

  /* eslint-disable no-console */
  consoleLog(message: any, type: 'log' | 'warn' | 'error' = 'log'): void {
    if (this.environment === 'production') {
      return;
    }
    if (type !== 'log') {
      return console[type]('❗️', message);
    }
    console[type](message);
  }
  /* eslint-enable no-console */

  // Errors
  errorHandler = (
    error: AxiosError<ApiErrorResponse>,
    context: string,
  ): Promise<never> => {
    if (error.response?.status === 401) {
      return Promise.reject(error);
    }
    if (context === 'requestPersonalData' && error.response?.status === 404) {
      //mainStore.pushAlert('error', i18n.t('errors:userNotFound'));
      userStore.logout().catch(() => undefined);
      return Promise.reject(error);
    }
    const errorMessage = this.parseErrorMessage(error, context);
    if (errorMessage) {
      this.pushAlert('error', errorMessage);
    }
    return Promise.reject(error);
  };
}

export const mainStore = new MainStore();
