import { classes } from 'html-classes';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import { CartNotification } from '~/components/Header/components/CartNotification';
import { ModalType } from '~/components/Modal/interface';
import Welcome from '~/components/Welcome/Welcome';
import { useModal } from '~/hooks/useModal';
import { userStore } from '~/stores/UserStore';

import Icon from '../../../components/Icon/Icon';
import { catalogStore } from '../../../stores/CatalogStore';

import styles from './MobileHeaderLayout.module.scss';
import MobileHeaderLayoutLocalee from './MobileHeaderLayoutLocalee';

interface MobileHeaderLayoutProps {
  showCart?: boolean;
  content?: React.ReactElement;
  showSearch?: boolean;
  showTopCart?: boolean;
  className?: string;
  showPersonalData?: boolean;
  isSticky?: boolean;
  toolbarContentClassName?: string;
}

const SEARCH_PAGE_LIST = ['/shop', '/explore', '/c/'];
const MAIN = '/';

const MobileHeaderLayout = ({
  showCart,
  content,
  showSearch,
  showTopCart,
  className,
  showPersonalData,
  isSticky,
  toolbarContentClassName,
}: MobileHeaderLayoutProps) => {
  const { pathname } = useLocation();
  const { openModal } = useModal();
  const navigate = useNavigate();

  const handleNavigateCart = () => {
    if (!userStore.deliveryAddress) {
      openModal(ModalType.EnterDeliveryAddress);
    } else {
      navigate('/cart', { replace: true });
    }
  };

  const handleNavigatePersonalData = () => {
    navigate('/cabinet/personal-data');
  };

  if (DEFAULT_COMPANIES.includes(company.name)) {
    return (
      <MobileHeaderLayoutLocalee
        showCart={showCart}
        content={content}
        showSearch={showSearch}
        showTopCart={showTopCart}
        className={className}
        showPersonalData={showPersonalData}
        isSticky={isSticky}
        toolbarContentClassName={toolbarContentClassName}
      />
    );
  }

  return (
    <div
      className={classes([
        styles.container,
        isSticky && styles.sticky,
        className,
      ])}
      data-company={company.name}
    >
      {(content || showTopCart) && (
        <div className={classes([styles.toolbar, 'content-layout'])}>
          <div
            className={classes([
              styles.toolbarContent,
              toolbarContentClassName,
            ])}
          >
            {content}
          </div>
          {showTopCart && (
            <div
              className={classes([
                styles.cart,
                catalogStore.totalCartCount && styles.cartFull,
              ])}
              onClick={handleNavigateCart}
            >
              <Icon type="bag" size={24} />
              <div className={styles.totalCartCount}>
                {catalogStore.totalCartCount}
              </div>
            </div>
          )}
          {showPersonalData && (
            <div
              className={classes([
                styles.cart,
                catalogStore.totalCartCount && styles.cartFull,
              ])}
              onClick={handleNavigatePersonalData}
            >
              <Icon type="filter" size={24} />
              <div className={styles.totalCartCount}>
                {catalogStore.totalCartCount}
              </div>
            </div>
          )}
        </div>
      )}
      {company.hasWelcomeBlock && pathname === MAIN && <Welcome />}
      {(pathname === MAIN ||
        SEARCH_PAGE_LIST.some((i) => pathname.includes(i)) ||
        showSearch) && (
        <div
          className={classes([
            'content-layout header-search-container',
            styles.searchBar,
          ])}
        >
          <Link
            className={classes([
              'header-search',
              showCart &&
                company.isAvailableCartInMobileHeader &&
                styles.searchInput,
            ])}
            to="/search"
            replace={true}
            data-company={company.name}
          >
            <Icon className="header-search__icon" type="search" size={24} />

            <div className="header-search__text">{t('phrases:searchFor')}</div>
          </Link>
          {(showCart || content) && company.isAvailableCartInMobileHeader && (
            <div className={styles.toolbar}>
              <div
                className={classes([
                  styles.cart,
                  catalogStore.totalCartCount && styles.cartFull,
                ])}
                onClick={handleNavigateCart}
              >
                <Icon type="bag" size={24} />
                <div className={styles.totalCartCount}>
                  {catalogStore.totalCartCount}
                </div>
                <CartNotification />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(MobileHeaderLayout);
