import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import { createCategoryUrl } from '~/components/CategoryBlocks/utils';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import Icon from '~/components/Icon/Icon';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import styles from '~/pages/Shop/ShopSidebarItem.module.scss';
import { catalogStore } from '~/stores/CatalogStore';
import { Category } from '~/stores/CategoriesStore';

interface ShopSidebarItemProps {
  category: Category;
}

const ShopSidebarItem = ({ category }: ShopSidebarItemProps) => {
  const { handleClickCategory } = useCategoryAnalytics();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const subcategories = category?.subcategory ?? [];

  const subcategoryLinkProps = (subcategory: Category) => {
    return {
      state: { clean_filters: true },
      className: styles.categoryContentLink,
      to: createCategoryUrl(
        company.variant({
          '1': subcategory,
          '2': category.parentId ? category : subcategory,
        }),
      ),
      onClick: () => {
        if (!category.parentId) {
          return;
        }
        catalogStore.resetCatalogFilters();

        if (company.variant({ '2': true })) {
          catalogStore.applyCatalogFilter('type', [subcategory.id.toString()]);
        }
      },
    };
  };

  return (
    <div key={category.id} className={styles.root} data-company={company.name}>
      <div
        className={styles.itemHeader}
        onClick={() => {
          setIsOpen((isOpen) => !isOpen);
        }}
      >
        <CloudflareResponseImage
          src={category.previewImage}
          srcParams={{ width: 54, height: 54 }}
          srcSetParams={defaultSrcSetParams}
          alt={category.name}
          className={styles.itemHeaderImage}
          loading="lazy"
        />

        <div className={styles.itemHeaderTitle}>{category.name}</div>
        <Icon
          type="chevron"
          size={20}
          className={classes([
            styles.itemHeaderIcon,
            isOpen && 'icon__rotate-180',
          ])}
        />
      </div>
      {isOpen && (
        <>
          <ul className={styles.categoryContent}>
            {subcategories.map((subcategory) => (
              <li key={subcategory.id}>
                <Link {...subcategoryLinkProps(subcategory)}>
                  {subcategory.name}
                </Link>
              </li>
            ))}
          </ul>
          {subcategories.length > 1 && (
            <p className={styles.viewAll}>
              <Link
                onClick={() => {
                  handleClickCategory(category.id)();
                }}
                to={createCategoryUrl({ id: category.id })}
                className={styles.link}
              >
                {t('viewAll')}
                <Icon type="arrow" size={16} />
              </Link>
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default observer(ShopSidebarItem);
