export const SORTING_OPTIONS = [
  'salesQuantity.DESC',
  'name.ASC',
  'name.DESC',
  'price.ASC',
  'price.DESC',
  'createdAt.DESC',
  'createdAt.ASC',
  'ratingAverage.DESC',
  //'reviewsCount.DESC'
];
