import { Coords } from 'google-map-react';
import { t } from 'i18next';
import { makeAutoObservable } from 'mobx';

import { AutocompleteSuggestion } from '~/api/DeliveryAddress';
import { ETACalculation } from '~/api/ETA';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { company } from '~/company/Company';
import { DeliveryZoneRef } from '~/components/ModalDeliveryAddress/GMap/interfaces';
import { isInsideZone } from '~/components/ModalDeliveryAddress/GMap/utils';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore, DeliveryAddress } from '~/stores/UserStore';

import { EditAddressData } from './interface';

export const {
  map: { center: mapCenter, zoom: mapZoom },
} = company.config;

class DeliveryAddressStore {
  isMapBusy = true;
  mapCenter: GeoCoordinates = mapCenter;
  selectedZone: Nullable<DeliveryZoneRef> = null;
  mapZoom = mapZoom;
  geolocationCoordinates: GeoCoordinates | null = null;
  curGeolocationAddress: AutocompleteSuggestion | null = null;
  isActiveGeolocation = false;
  isManuallyInput = false;
  isLoading = false;
  isAwaitGeolocation = false;
  isAwaitSubscription = false;
  inputAddressValue = '';
  inputAddressFocused = false;
  deliveryAddress: DeliveryAddress | null = null;
  etaCalculation: ETACalculation | null = null;
  isAddressNotExist = false;
  isAddressNotCovered = false;
  isSmallSizeCover = true;
  nameVal = '';
  emailVal = '';
  isShowAddressNotFound = false;
  isShowAddressFound = false;
  isPushRequested = false;
  editAddressData: EditAddressData = {
    addressId: null,
    address2: '',
    type: 'home',
    comment: '',
    instructions: [],
  };
  isChangeDeliveryAreaPopover = false;
  renderedZonesOnMap: Record<string, DeliveryZoneRef> = {};

  constructor() {
    makeAutoObservable(this);
  }

  get isEmailValid(): boolean {
    return mainStore.validateEmail(this.emailVal);
  }

  get isFormValid(): boolean {
    return this.isEmailValid && !!this.nameVal.length;
  }

  setAddressType(type: string) {
    if (type === 'home') {
      this.setAddressTypeValue(type);
    } else {
      this.setAddressTypeValue('');
    }
  }

  setAddressTypeValue(val: string) {
    this.editAddressData.type = val;
  }

  setIsMapBusy(flag: boolean) {
    this.isMapBusy = flag;
  }

  setIsActiveGeolocation(flag: boolean) {
    this.isActiveGeolocation = flag;
  }

  setIsManuallyInput(flag: boolean) {
    this.isManuallyInput = flag;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  setInputAddressFocused(flag: boolean) {
    this.inputAddressFocused = flag;
  }

  setIsAddressNotExist(flag: boolean) {
    this.isAddressNotExist = flag;
  }

  setIsAddressNotCovered(flag: boolean) {
    this.isAddressNotCovered = flag;
  }

  setMapCenter(val: GeoCoordinates) {
    this.mapCenter = val;
  }

  setSelectedZone(zone: Nullable<DeliveryZoneRef>) {
    this.selectedZone = zone;
  }

  setMapZoom(val: number) {
    this.mapZoom = val;
  }

  setInputAddressValue(val: string) {
    this.inputAddressValue = val;
  }

  setDeliveryAddress(val: DeliveryAddress | null) {
    this.deliveryAddress = !val
      ? val
      : Object.assign(val, this.editAddressData);
  }

  setDeliveryAddress2(val: string) {
    if (this.editAddressData) {
      this.editAddressData.address2 = val;
    }
  }

  setEtaCalculation(val: ETACalculation | null) {
    this.etaCalculation = val;
  }

  setIsSmallSizeCover(flag: boolean) {
    this.isSmallSizeCover = flag;
  }

  setNameVal(val: string) {
    this.nameVal = val;
  }

  setEmailVal(val: string) {
    this.emailVal = val;
  }

  setIsShowAddressNotFound(flag: boolean) {
    this.isShowAddressNotFound = flag;
  }

  setIsShowAddressFound(flag: boolean) {
    this.isShowAddressFound = flag;
  }

  setIsAwaitGeolocation(flag: boolean) {
    this.isAwaitGeolocation = flag;
  }

  setIsAwaitSubscription(flag: boolean) {
    this.isAwaitSubscription = flag;
  }

  setGeolocationCoordinates(val: GeoCoordinates | null) {
    this.geolocationCoordinates = val;

    /**
     * Code below sets new center of map, together with "geolocationCoordinates"
     *
     * TODO: Not sure why this properties are different. Needs to remove one of them
     * */
    if (val) {
      this.mapCenter = val;
    }
  }

  setCurGeolocationAddress(val: AutocompleteSuggestion | null) {
    this.curGeolocationAddress = val;
  }

  setIsPushRequested(flag: boolean) {
    this.isPushRequested = flag;
  }

  setEditAddressData(val: EditAddressData) {
    this.editAddressData = val;
  }

  setIsChangeDeliveryAreaPopover(flag: boolean) {
    this.isChangeDeliveryAreaPopover = flag;
  }

  resetStore() {
    this.isMapBusy = true;
    this.mapCenter = mapCenter;
    this.geolocationCoordinates = null;
    this.isActiveGeolocation = false;
    this.isManuallyInput = false;
    this.isLoading = false;
    this.isAwaitGeolocation = false;
    this.isAwaitSubscription = false;
    this.inputAddressValue = '';
    this.inputAddressFocused = false;
    this.deliveryAddress = null;
    this.etaCalculation = null;
    this.isAddressNotExist = false;
    this.isAddressNotCovered = false;
    this.isSmallSizeCover = true;
    this.nameVal = '';
    this.emailVal = '';
    this.isShowAddressNotFound = false;
    this.isShowAddressFound = false;
    this.curGeolocationAddress = null;
    this.isPushRequested = false;
    this.selectedZone = null;
    this.editAddressData = {
      addressId: null,
      address2: '',
      type: 'home',
      comment: '',
      instructions: [],
    };
  }

  async geocoding(skipGeocoding = false, { lat, lng }: GeoCoordinates) {
    const deliveryMethod = this.getDeliveryMethodBySelectedZone();

    try {
      const etaCalculation = await orderStore
        .fetchETA(`${lat},${lng}`, deliveryMethod)
        .catch(() => null);

      mainStore.sendToRN('sendTags', {
        not_in_the_area: 'false',
      });

      this.setEtaCalculation(etaCalculation);
      this.setIsAddressNotCovered(false);
      this.setIsAddressNotExist(false);
      if (!etaCalculation) {
        mainStore.pushAlert('error', t('addressOutOfArea'));
        mainStore.sendToRN('sendTags', {
          not_in_the_area: true,
        });

        this.setIsAddressNotCovered(true);
        return;
      }

      if (!skipGeocoding) {
        const prevPlaceId = this.deliveryAddress?.placeId;
        try {
          const deliveryAddress = await userStore.geocodingByCoordinates(
            this.mapCenter,
          );

          this.setDeliveryAddress(deliveryAddress);
          this.setInputAddressValue(deliveryAddress?.address1 || '');
        } catch (e) {
          this.setDeliveryAddress(null);
          this.setInputAddressValue('');
        }
        if (prevPlaceId === this.deliveryAddress?.placeId) {
          if (!this.etaCalculation && !userStore.isFirstLaunch) {
            this.setIsAddressNotCovered(true);
          }
          return;
        }
      }
    } catch (error) {
      this.setEtaCalculation(null);
      mainStore.sendToRN('sendTags', {
        not_in_the_area: true,
      });

      if (!userStore.isFirstLaunch) {
        this.setIsAddressNotCovered(true);
      }
    }
  }

  getDeliveryMethodBySelectedZone(): ETADeliveryMethodType | undefined {
    if (!this.selectedZone) {
      return;
    }

    const zoneDeliveryMechanics =
      this.selectedZone.zone.deliveryMethod.deliveryMechanics;
    const clickCollect =
      zoneDeliveryMechanics[ETADeliveryMethodType.ClickAndCollect];
    const delivery = zoneDeliveryMechanics[ETADeliveryMethodType.JiffyDelivery];

    return !delivery && clickCollect
      ? ETADeliveryMethodType.ClickAndCollect
      : ETADeliveryMethodType.JiffyDelivery;
  }

  getSelectedPolygon(point: Coords) {
    for (const deliveryZoneRef of Object.values(this.renderedZonesOnMap)) {
      const isSelectedPolygon = isInsideZone(point, deliveryZoneRef.polygon);

      if (isSelectedPolygon) {
        return deliveryZoneRef;
      }
    }

    return null;
  }
}

export const deliveryAddressStore = new DeliveryAddressStore();
