import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SubscriptionFlagsNames } from '~/api/Customer';
import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { usePushNotifications } from '~/hooks/usePushNotifications';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import ContentBottomSheet from './components/ContentBottomSheet/ContentBottomSheet';
import ProgressIndicator from './components/ProgressIndicator/ProgressIndicator';
import { useStepData } from './hooks/useStepData';
import styles from './Onboarding.module.scss';

const Onboarding = () => {
  const navigate = useNavigate();
  const { updateDeviceAndSendTags } = usePushNotifications();
  const {
    handleChangeScreenIndex,
    handleTimerAlive,
    currentStepData,
    currentStep,
    stepsNumber,
  } = useStepData();

  useEffect(() => {
    analyticsEventsEmitter.emit(
      EventsName.ONBOARDING_SUBSCRIBE_DIALOG_SHOWN,
      {},
    );
  }, []);

  const sendOnboardingSuccesfull = () => {
    mainStore.sendToRN('sendTags', {
      onboarding_succesfull: true,
    });
    mainStore.sendToRN('sendTags', {
      onboarding_succesfull_date: Math.floor(Date.now() / 1000),
    });
  };

  const handleLaterPress = () => {
    if (currentStep === stepsNumber - 1) {
      analyticsEventsEmitter.emit(
        EventsName.ONBOARDING_SUBSCRIBE_DIALOG_CLICKED,
        {
          answer: 'skip',
        },
      );
      handleTimerAlive(false);
      mainStore.setOnboardingSeen(true);
      subscibeToNotifications();
      sendOnboardingSuccesfull();
      mainStore.sendToRN('setStatusBar', 'dark-content');
      navigate('/', { replace: true, state: { isShowAuthModal: true } });
    } else {
      handleChangeScreenIndex(stepsNumber - 1);
    }
  };

  const handleNextPress = () => {
    handleTimerAlive(false);
    handleChangeScreenIndex(currentStep + 1);
  };

  const handlePrevPress = () => {
    handleTimerAlive(false);
    handleChangeScreenIndex(currentStep - 1);
  };

  const handleSubmitForm = ({ name = '', email = '' }) => {
    analyticsEventsEmitter.emit(
      EventsName.ONBOARDING_SUBSCRIBE_DIALOG_CLICKED,
      {
        answer: 'next',
      },
    );
    analyticsEventsEmitter.emit(EventsName.ONBOARDING_COMPLETE);
    mainStore.setOnboardingSeen(true);

    const { middleName, firstName, lastName } = userStore.splitFullName(name);

    if (email) {
      mainStore.sendToRN('setOneSignalUserEmail', email);
    }

    userStore.setDraftUserData({
      middleName,
      firstName,
      lastName,
      email,
    });
    subscibeToNotifications(
      `${firstName || ''} ${middleName || ''} ${lastName || ''}`,
      email,
    );

    mainStore.sendToRN('setStatusBar', 'dark-content');
    navigate('/', { replace: true, state: { isShowAuthModal: true } });
  };

  const subscibeToNotifications = (firstName = '', email = '') => {
    updateDeviceAndSendTags(firstName, email, [
      {
        name: SubscriptionFlagsNames.NEWS,
        enabled: true,
      },
      {
        name: SubscriptionFlagsNames.REMINDERS,
        enabled: true,
      },
      {
        name: SubscriptionFlagsNames.RECOMMENDATIONS,
        enabled: true,
      },
      {
        name: SubscriptionFlagsNames.SPECIAL_DEALS,
        enabled: true,
      },
      {
        name: SubscriptionFlagsNames.NEWS_EMAIL,
        enabled: true,
      },
      {
        name: SubscriptionFlagsNames.REMINDERS_EMAIL,
        enabled: true,
      },
      {
        name: SubscriptionFlagsNames.RECOMMENDATIONS_EMAIL,
        enabled: true,
      },
      {
        name: SubscriptionFlagsNames.SPECIAL_DEALS_EMAIL,
        enabled: true,
      },
    ]);
  };

  return (
    <div
      className={classes([
        styles.onboarding,
        company.hasNewOnboardingDesign && styles.new,
      ])}
    >
      {company.hasOnboardingDotsInHeader && (
        <ProgressIndicator
          stepsNumber={stepsNumber}
          activeStep={currentStep}
          noPadding={false}
        />
      )}
      {company.hasOnboardingCloseButton && (
        <button onClick={handleLaterPress} className={styles.closeButton}>
          <Icon className={styles.closeIcon} type="close" />
        </button>
      )}
      <div className={styles.imageContainer}>
        <div
          className={classes([
            styles.image,
            company.hasNewOnboardingDesign && styles.new,
            currentStep === stepsNumber - 1 && styles.isLarge,
          ])}
          style={{
            backgroundImage: `url('${currentStepData?.img}')`,
            backgroundPosition: currentStepData?.position || 'bottom',
          }}
        />
      </div>
      <ContentBottomSheet
        isVisibleInputs={currentStep === stepsNumber - 1}
        currentPage={currentStep}
        onNextPress={handleNextPress}
        onSubmitForm={handleSubmitForm}
        onLaterPress={handleLaterPress}
        onPrevPress={handlePrevPress}
        buttonsHeader={
          company.hasOnboardingDotsAboveButtons && (
            <ProgressIndicator
              stepsNumber={stepsNumber}
              activeStep={currentStep}
              noPadding={true}
            />
          )
        }
        title={currentStepData?.textTitle || ''}
        description={currentStepData?.textDescription || ''}
      />
    </div>
  );
};

export default observer(Onboarding);
