import { classes } from 'html-classes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { ReferralProgramModal } from '~/pages/ReferralProgram/ReferralProgramModal';
import { mainStore } from '~/stores/MainStore';

import styles from '../../Order.module.scss';

interface ShareBlockProps {
  orderId?: string;
}

const ShareBlock = ({ orderId }: ShareBlockProps) => {
  const { t } = useTranslation();
  const [openReferralModal, setOpenReferralModal] = useState(false);

  return (
    <div className="share-block" data-company={company.name}>
      <div className="share-block_content">
        <p className="share-block_content-title">{t('shareLove')}</p>
        {company.isShareBlockCaptionVisible && (
          <p className="share-block_content-text">{t('referFriend')}</p>
        )}
      </div>
      <ReferralProgramModal
        open={openReferralModal}
        onClose={() => setOpenReferralModal(false)}
      />
      {company.match({
        citydrinks: (
          <Link
            to="/cabinet/referral"
            className={classes(['button', '_bordered', styles.whiteBtn])}
            onClick={() => {
              mainStore.sendAnalytics(['BI', 'analytics'], {
                name: 'Invite friends now',
                params: { orderId },
              });
            }}
          >
            {company.isShareBlockButtonIconVisible && <Icon type="refferal" />}
            {t('inviteFriends')}
          </Link>
        ),
        default: (
          <button
            className="button _primary"
            onClick={() => setOpenReferralModal(true)}
          >
            <Icon type="refferal" size={24} />
            {t('inviteFriends')}
          </button>
        ),
      })}
    </div>
  );
};

export default ShareBlock;
