import { IMAGES } from '../../../constants/images';

export default () => [
  {
    img: IMAGES.onboarding.getYourFavourite,
    position: 'bottom',
  },
  {
    img: IMAGES.onboarding.expressDelivery,
    position: 'bottom',
  },
  {
    img: IMAGES.onboarding.discoverNew,
    position: 'top',
  },
  {
    img: IMAGES.onboarding.trackDeliveryNew,
    position: 'top',
  },
  {
    img: IMAGES.onboarding.affiliateProgram,
    position: 'top',
  },
  {
    img: IMAGES.onboarding.aiPowered,
    position: 'top',
  },
  {
    img: IMAGES.onboarding.stayUpdated,
    position: 'top',
  },
];
