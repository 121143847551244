import { IMAGES } from '~/constants/images';

export const LOCALE = {
  en: {
    img: IMAGES.locale?.en,
    title: 'EN',
  },
  ar: {
    img: IMAGES.locale?.ar,
    title: 'AR',
  },
};
