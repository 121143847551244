import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CheckPromocodeResponse } from '~/api/Order';
import { company } from '~/company/Company';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Icon from '~/components/Icon/Icon';
import { IMAGES } from '~/constants/images';
import useMediaQuery from '~/hooks/useMediaQuery';
import { useRewardedPromocodesStatus } from '~/hooks/useRewardedPromocodesStatus';
import { ReferralCode } from '~/pages/ReferralProgram/ReferralCode';
import { splitToSeparateLines } from '~/pages/ReferralProgram/utils';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { catalogStore } from '~/stores/CatalogStore';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import { BREAKPOINTS } from '~/breakpoints';

import { steps, breadcrumbItems, breadcrumbItemsVariant1 } from './constants';
import styles from './ReferralProgram.module.scss';

const ReferralProgram = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.phone}px)`);
  const [isRefereeCodeLoading, setRefereeCodeLoading] =
    useState<boolean>(false);
  const promotion = catalogStore.promotionStore;

  useEffect(() => {
    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_REFERRAL_PROGRAM_OPEN,
    );
  }, []);

  useEffect(() => {
    setRefereeCodeLoading(true);
    userStore.fetchReferralProgramInfo().finally(() => {
      setRefereeCodeLoading(false);
    });

    return () => {
      userStore.clearReferralProgramInfo();
    };
  }, []);

  useRewardedPromocodesStatus();

  const getDiscount = (instance?: Nullable<CheckPromocodeResponse>): string => {
    if (!instance) {
      return '';
    }

    const { type, value } = instance;

    if (type === 'PERCENTAGE') {
      return `${value}%`;
    }

    return mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(value));
  };

  const translationContext = {
    companyName: t(company.name),
    discount: getDiscount(userStore.refereeInfo),
    referralDiscount: getDiscount(userStore.referralInfo),
    amount: formatPriceWithCurrency(
      (userStore?.refereeInfo?.minimumPurchase || 0) / 100,
    ),
  };

  const pageTitle = (
    <>
      <h1 className={styles.title}>
        {splitToSeparateLines(t('phrases:referralProgram')).map((line) => (
          <span key={line}>{line}</span>
        ))}
      </h1>
      {translationContext.referralDiscount && (
        <p className={styles.description}>
          {t('phrases:referralProgramDescription', translationContext)}
        </p>
      )}
    </>
  );

  return (
    <div className={styles.root}>
      {isMobile && (
        <Link to="/cabinet" className={styles.goBack}>
          <Icon type="arrow" />
        </Link>
      )}
      <div className={styles.hero__wrapper}>
        <div className={styles.container}>
          <div className={styles.hero}>
            {!isMobile && (
              <div className={styles.title__wrapper}>
                <Breadcrumbs
                  items={company
                    .variant({
                      '1': breadcrumbItems,
                      '2': breadcrumbItemsVariant1,
                    })
                    .map((item) => ({
                      ...item,
                      text: t(item.text),
                    }))}
                />

                {pageTitle}

                <ReferralCode isLoading={isRefereeCodeLoading} />
              </div>
            )}
            <img
              src={IMAGES.referral.hero}
              alt="Refer a friend"
              className={styles.hero__image}
            />
          </div>
        </div>
      </div>

      {promotion.promocodeRewardStatus.total > 0 && (
        <div className={styles.awardStatusWr}>
          <div className={styles.container}>
            <div className={styles.awards} data-company={company.name}>
              <div className={styles.awards__counters}>
                <div className={styles.awards__counters__item}>
                  <h3>{promotion.promocodeRewardStatus.new}</h3>
                  <p>{t('new')}</p>
                </div>
                <div className={styles.awards__counters__item}>
                  <h3>{promotion.promocodeRewardStatus.total}</h3>
                  <p>{t('available')}</p>
                </div>
              </div>
              <Link
                to="/cabinet/referral/rewards/"
                className={classes([
                  'button',
                  '_primary',
                  '_big',
                  styles.viewAwardsLink,
                ])}
              >
                {t('referralProgram:viewRewards')}
              </Link>
            </div>
          </div>
        </div>
      )}

      <div className={styles.info}>
        <div className={styles.container}>
          {isMobile && pageTitle}

          <h2 className={styles.info__title}>
            {t('phrases:referralProgramSteps')}
          </h2>

          <div className={styles.steps}>
            {steps
              .filter((item) => {
                if (translationContext.referralDiscount) {
                  return true;
                }
                return item.step !== 3;
              })
              .map((item) => (
                <div className={styles.step} key={item.step}>
                  <div className={styles.step__numberWr}>
                    <span className={styles.step__number}>{item.step}</span>
                  </div>
                  <div className={styles.step__description}>
                    <p>{t(item.title, translationContext)}</p>
                    <span>{t(item.caption, translationContext)}</span>
                  </div>
                </div>
              ))}
          </div>

          {isMobile && <ReferralCode isLoading={isRefereeCodeLoading} />}
        </div>
      </div>
    </div>
  );
};

export default observer(ReferralProgram);
