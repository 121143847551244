import { useQuery } from '@tanstack/react-query';
import { classes } from 'html-classes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import geocoderRequest from '~/components/ModalDeliveryAddress/geocoderRequest';
import { useGlobal } from '~/hooks/useGlobal';

import styles from './AddressAutocomplete.module.scss';

interface Prediction {
  description: string;
  place_id: string;
}

interface AutocompleteResponse {
  data: {
    predictions: Prediction[];
  };
}

interface AddressAutocompleteProps {
  searchText: string;
  onSelect: (address: string) => void;
  searchSessionToken?: string;
  onLoadingStateChange?: (isLoading: boolean) => void;
}

const AddressAutocomplete = ({
  searchText,
  onSelect,
  searchSessionToken,
  onLoadingStateChange,
}: AddressAutocompleteProps) => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const {
    data: { predictions },
    isFetching,
  } = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!searchSessionToken && !!searchText,
    queryKey: ['autocomplete-search', searchText, searchSessionToken],
    initialData: {
      predictions: [],
    },
    queryFn: () =>
      geocoderRequest
        .post<AutocompleteResponse>('/autocomplete', {
          countryCode: company.config.countryCode || '',
          input: searchText,
          sessionToken: searchSessionToken,
        })
        .then(({ data: { data } }) => data),
  });

  useEffect(() => {
    onLoadingStateChange?.(isFetching);
  }, [isFetching]);

  return (
    <div className={styles.container}>
      {isMobile && (
        <div className={styles.elementContainer} onClick={() => onSelect('')}>
          <div className={classes([styles.element, styles.goBack])}>
            <Icon type="geolocation" size={24} />
            {t('selectOnMap')}
          </div>
        </div>
      )}
      {predictions.map(({ description, place_id: placeId }) => (
        <div
          key={placeId}
          className={styles.elementContainer}
          onClick={() => onSelect(placeId)}
        >
          <div className={styles.element}>{description}</div>
        </div>
      ))}
    </div>
  );
};

export default AddressAutocomplete;
