import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';

const cityDrinksDevConfig = {
  apiKey: 'AIzaSyCSnQTtW6MGE-_ad71dNaHeELCIZiAv-vU',
  authDomain: 'citydrinks-8316f.firebaseapp.com',
  projectId: 'citydrinks-8316f',
  storageBucket: 'citydrinks-8316f.appspot.com',
  messagingSenderId: '114787590870',
  appId: '1:114787590870:web:ab33de73c269b741db1f77',
  measurementId: 'G-KRTR439N8D',
};

const cityDrinksProdConfig = {
  apiKey: 'AIzaSyCSnQTtW6MGE-_ad71dNaHeELCIZiAv-vU',
  authDomain: 'citydrinks-8316f.firebaseapp.com',
  projectId: 'citydrinks-8316f',
  storageBucket: 'citydrinks-8316f.appspot.com',
  messagingSenderId: '114787590870',
  appId: '1:114787590870:web:46fdad0e1cbbac81db1f77',
  measurementId: 'G-478QEXEMPJ',
};

const cityDrinksConfig =
  import.meta.env.REACT_APP_ENV === 'production'
    ? cityDrinksProdConfig
    : cityDrinksDevConfig;

const configs = {
  [CompanyName.Localee]: null,
  [CompanyName.CircleK]: null,
  [CompanyName.CityDrinks]: cityDrinksConfig,
} satisfies Partial<Record<CompanyName, unknown>>;

export const config = configs[company.name];
