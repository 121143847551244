import { classes } from 'html-classes';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, JSX, KeyboardEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { company } from '~/company/Company';
import CategoryBlock, {
  CategoryDisplayType,
} from '~/components/CategoryBlocks/CategoryBlock';
import { IMAGES } from '~/constants/images';
import { useGlobal } from '~/hooks/useGlobal';
import { useSearchRecommendations } from '~/pages/SearchResults/hooks/useSearchRecommendations';
import { SearchLoader } from '~/pages/SearchResults/SearchLoader';
import { SearchRecommendations } from '~/pages/SearchResults/SearchRecommendations';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { Category, homeCategories } from '~/stores/CategoriesStore';
import { searchStore } from '~/stores/SearchStore';
import { copyWith } from '~/utils/copy';

import Icon from '../../components/Icon/Icon';
import ProductCard from '../../components/ProductCard/ProductCard';
import { userStore } from '../../stores/UserStore';

import styles from './SearchResults.module.scss';

const Header = observer(() => {
  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
    const value = (e.target as HTMLInputElement).value;

    if (e.code !== 'Enter' || !value) {
      return;
    }

    e.preventDefault();
    searchStore.paginationRequest.debounceLoadPage(1, value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    searchStore.setQuery(e.target.value);
    searchStore.paginationRequest.debounceLoadPage(1, e.target.value);
  };

  return (
    <div className={styles.mobileHeaderSearch}>
      <Link to="/">
        <Icon type="arrow" size={24} className="_mirror-x" />
      </Link>

      <div className="header-search">
        {searchStore.paginationRequest.isLoading ? (
          <div className="spinner header-search__icon" />
        ) : (
          <Icon className="header-search__icon" type="search" size={24} />
        )}

        <input
          type="text"
          value={searchStore.queryString}
          autoFocus
          className="header-search__input"
          placeholder={t('phrases:hiThere', {
            name: userStore.personalData.firstName || 'there',
          })}
          onKeyUp={handleKeyUp}
          onChange={handleInputChange}
        />
        <button
          className="button _no-padding"
          style={{ visibility: searchStore.queryString ? 'visible' : 'hidden' }}
          onClick={() => {
            searchStore.reset();
            window.scrollTo(0, 0);
          }}
        >
          <Icon type="close" />
        </button>
      </div>
    </div>
  );
});

const SearchResults = observer((): JSX.Element => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const { state } = useLocation();
  const [recommendations, selectRecommendations] = useSearchRecommendations();

  useEffect(() => {
    analyticsEventsEmitter.emit(
      EventsName.CATALOG_SEARCH_VISITED,
      state?.source || 'direct',
    );
    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_CATALOG_SEARCH_VISITED,
      state?.source || 'direct',
    );
  }, []);

  const getCategoryByName = (name: string) => {
    const category = homeCategories.list.find((category) =>
      category.name.toLowerCase().includes(name),
    );

    return category
      ? copyWith(category, { previewText: '', backgroundColor: null })
      : null;
  };

  const categories = [
    getCategoryByName('recently viewed'),
    getCategoryByName('recommended'),
  ].filter(Boolean) as Category[];

  return (
    <div className={classes(['page-catalog', styles.root])}>
      <div className="content-layout">
        <div className="two-columns">
          <div className="two-columns__content">
            <div>
              <div className={styles.sticky}>
                <div className="block-title-wrap">
                  {isMobile ? (
                    <Header />
                  ) : (
                    <div className="container-flex _v-baseline">
                      <h1
                        className={classes(['block-title', styles.pageTitle])}
                      >
                        {t('translation:searchResult')}
                      </h1>
                    </div>
                  )}
                </div>
              </div>
              {!searchStore.result.length &&
              searchStore.paginationRequest.isLoading ? (
                <SearchLoader />
              ) : searchStore.result.length !== 0 ? (
                <>
                  <div data-company={company.name} className="block-products">
                    {searchStore.result.map((product) => (
                      <ProductCard key={product.id} product={product} />
                    ))}
                  </div>
                  {searchStore.paginationRequest.canLoadMore && (
                    <SearchLoader
                      onLoadMore={() =>
                        searchStore.paginationRequest.loadMore(
                          searchStore.queryString,
                        )
                      }
                    />
                  )}
                </>
              ) : !searchStore.result.length &&
                searchStore.queryString.length > 0 ? (
                <div
                  className={styles.nothingFound}
                  data-company={company.name}
                >
                  <img
                    src={IMAGES.search.empty}
                    className={styles.emptyImage}
                  />
                  <h3>
                    {t('searchNotFound', { query: searchStore.queryString })}
                  </h3>
                  <p>{t('searchTryAnother')}</p>
                </div>
              ) : isMobile ? (
                <>
                  <div>
                    <SearchRecommendations
                      data={recommendations}
                      onSelect={selectRecommendations}
                    />
                  </div>
                  <div className={styles.categories}>
                    {categories.map((category) => (
                      <CategoryBlock
                        className={
                          category.displayType !==
                          CategoryDisplayType.CATEGORIES_CAROUSEL_SLIDER_TWO
                            ? 'content-layout'
                            : undefined
                        }
                        category={category}
                        key={category.id}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SearchResults;
