import { classes } from 'html-classes';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { BundleBadge } from '~/components/BundleBadge';
import Icon from '~/components/Icon/Icon';
import { MultibuyBadge } from '~/components/MultibuyBadge';
import { formatVolume, pluralizePcLabel } from '~/components/ProductCard/utils';
import { CartItem } from '~/stores/CatalogStore';
import {
  CalculateStockBundleError,
  CalculateStockOfferError,
} from '~/stores/shared/CartStockChanges/interfaces';
import { isProductBundle } from '~/types/Product';
import { formatPrice, formatPriceWithCurrency } from '~/utils/formaters';

import styles from './ModalStockChanged.module.scss';

interface StockProductCardProps {
  product: CartItem;
  stockInfo: CalculateStockOfferError;
  bundleStockInfo?: Nullable<CalculateStockBundleError>;
  className?: string;
}

const StockProductCard = (props: StockProductCardProps) => {
  const { product, stockInfo, bundleStockInfo, className } = props;

  const { t } = useTranslation();

  const isBundle = isProductBundle(product);
  const image = product.previewImageThumb;
  const isMultiBuy = !isBundle && Boolean(product.promoRequiredQuantity);
  const price =
    !isMultiBuy && product.discountPrice
      ? product.discountPriceFormatted
      : product.priceFormatted;
  const oldPrice =
    !isMultiBuy && product.discountPrice ? product.priceFormatted : null;

  const getProductStock = () => {
    if (isBundle) {
      if (!bundleStockInfo) {
        return 0;
      }

      return bundleStockInfo.sellable >= 0 ? bundleStockInfo?.sellable : 0;
    }

    if (stockInfo.on_stock >= 0) {
      return stockInfo.on_stock;
    }

    return 0;
  };

  const onStock = getProductStock();

  const rating = product.ratingAverage ? (
    <div className="product-card__rated">
      <Icon type="star-fill" size={12} />
      {product.ratingAverage}
      {product.ratingMarksCount && <span> ({product.ratingMarksCount})</span>}
    </div>
  ) : (
    <div className={classes([styles.no_rating, 'product-card__no-rated'])}>
      <Icon type="star-fill" size={12} className={styles.no_rating__star} />
      {t('noRatingShort')}
    </div>
  );

  return (
    <div className={classes(['product-card', className])}>
      <div className="product-card__img-wrap">
        {isBundle && <BundleBadge className={styles.bundleBadge} />}
        {isMultiBuy && (
          <MultibuyBadge
            value={product.promoRequiredQuantity}
            className={styles.multibuyCounter}
          />
        )}
        <div
          style={{ '--stock-product-image': `url(${image})` } as CSSProperties}
          className={classes([
            styles.productImage,
            onStock <= 0 && styles.disabledImage,
          ])}
        />
      </div>
      <div className="product-card__body">
        <div className="product-card__info">
          {!company.hideReviews && rating}
          <p className="product-card__info-volume">
            {formatVolume(product.properties)}
          </p>
        </div>
        <div className={classes(['product-card__name', styles.productName])}>
          {product.name}
        </div>
        <div className="product-card__footer">
          <div className="product-card__price-wrap">
            <div
              className={classes([
                'product-card__price',
                styles.productPrice,
                oldPrice && 'good',
                isMultiBuy && styles.multibuyOldPrice,
              ])}
            >
              {formatPriceWithCurrency(+price)}
              {isMultiBuy ? ' / 1x' : ''}
            </div>
            {oldPrice && (
              <div className="product-card__price _old">
                {formatPrice(+oldPrice)}
              </div>
            )}
          </div>
          {isMultiBuy && (
            <div className={styles.multibuyPriceWr}>
              <div className={styles.multibuyFromRule}>
                {t('phrases:fromPc', {
                  length: pluralizePcLabel(product.promoRequiredQuantity),
                })}
              </div>
              <div className="product-card__price good">
                {formatPriceWithCurrency(
                  product.promoQuantityDiscountPriceFormatted,
                )}
              </div>
            </div>
          )}
          <div className={styles.availableQty}>
            <span>
              {onStock > 0
                ? t('phrases:availableStock', { count: onStock })
                : t('outStock')}
            </span>
            <span className={styles.requestedQuantity}>
              {t('phrases:itemsCount', {
                count: stockInfo.requested_quantity,
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockProductCard;
