import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import { getCloudflareUrl } from '~/components/CloudflareResponsiveImage/utils';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { Category } from '~/stores/CategoriesStore';

import Icon from '../../Icon/Icon';

import { createCategoryUrl } from '../utils';

import styles from './ListOfCategoriesTwoBlock.module.scss';

interface ListOfCategoriesTwoBlockProps {
  category: Category;
}

const ListOfCategoriesTwoBlock = ({
  category,
}: ListOfCategoriesTwoBlockProps) => {
  const { handleClickCategory } = useCategoryAnalytics();

  const cfSrcForImage = (src: string) =>
    getCloudflareUrl(src, company.config.cloudflareImages.domain, {
      width: 386,
      height: 260,
    });

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        {category.subcategory.map(({ name, previewImage, id, previewText }) => (
          <Link
            key={id}
            className={styles.subcategory}
            onClick={handleClickCategory(id)}
            to={createCategoryUrl({ id })}
          >
            <div
              className={styles.image}
              style={{
                backgroundImage: `url('${encodeURI(
                  cfSrcForImage(previewImage || ''),
                )}')`,
              }}
            />
            <div className={styles.description}>
              <div className={styles.title}>{name}</div>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: previewText }}
              />
              <div className={styles.link}>
                Show <Icon type="arrow" size={24} />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default observer(ListOfCategoriesTwoBlock);
