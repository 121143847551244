import { MechanicType } from '~/api/ETA';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';

import { ENDPOINT } from './constants';
import { RequestAPI } from './Requests';

export interface AutocompleteSuggestion {
  address: string;
  postcode: string;
  location: {
    latitude: number;
    longitude: number;
  };
  country?: string;
  city?: string;
  place_id: string | null;
}

export interface Address {
  city: string;
  comment: string | null;
  customer_id: string;
  id: string;
  instructions: string[] | null;
  latitude: number;
  longitude: number;
  place_id: string;
  postcode: string;
  street_address_1: string | null;
  street_address_2: string | null;
  country_code: string;
  type: string | null;
}

interface AddressesResponse {
  data: Address[];
}

interface DeliveryZoneWarehouse {
  code: string;
  id: string;
}

export interface DeliveryZoneSegment {
  start: {
    latitude: number;
    longitude: number;
  };
  end: {
    latitude: number;
    longitude: number;
  };
}

export interface DeliveryMechanicSchedule {
  dayOfWeek: number;
  intervals: Array<{ start: string; end: string }>;
}

export interface DeliveryZoneMethod {
  deliveryMechanics: Partial<
    Record<
      ETADeliveryMethodType,
      Partial<
        Record<MechanicType, { schedule: Array<DeliveryMechanicSchedule> }>
      >
    >
  >;
  sellers: string[];
  shippingType: string;
}

export interface DeliveryZone {
  warehouseId?: string;
  deliveryMethod: DeliveryZoneMethod;
  name: string;
  color: string;
  priority: number;
  polygon: {
    segments: DeliveryZoneSegment[];
  };
}

export interface DeliveryZoneCoverage {
  warehouse: DeliveryZoneWarehouse;
  zones: DeliveryZone[];
}

interface DeliveryZonesResponse {
  data: {
    coverages: DeliveryZoneCoverage[];
  };
}

export interface AddAddressRequest {
  street_address_1: string;
  city: string;
  postcode: string;
  latitude: number;
  longitude: number;
  country: string;
  street_address_2?: string;
  comment?: string;
  instructions?: string[];
  type: string;
}

export interface UpdateAddressRequest {
  city?: string;
  comment?: string;
  instructions?: string[];
  latitude?: number;
  longitude?: number;
  postcode?: string;
  street_address_1?: string;
  street_address_2?: string;
  type?: string;
}

export interface AutocompleteRequest {
  sessionToken: string;
  input: string;
  placeId: string;
  countryCode: string;
  languageCode: string;
}

export interface AutocompletePrediction {
  description: string;
  place_id: string;
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface AutocompletePlace {
  address: string;
  postcode: string | null;
  location: Location;
}

export interface AutocompleteResponse {
  data: {
    result: AutocompletePlace | null;
    predictions: AutocompletePrediction[];
  };
}

interface DeliveryRequestsInterface {
  autocomplete(data: AutocompleteRequest): Promise<AutocompleteResponse>;

  fetchDeliveryZones(): Promise<DeliveryZonesResponse>;

  getAddresses(): Promise<AddressesResponse>;

  addAddress(data: AddAddressRequest): Promise<{ data: Address }>;

  updateAddress(
    id: string,
    data: UpdateAddressRequest,
  ): Promise<AddressesResponse>;

  deleteAddress(id: string): Promise<AddressesResponse>;
}

export const DeliveryRequests: DeliveryRequestsInterface = {
  autocomplete: (data) =>
    RequestAPI.post(ENDPOINT.deliveryAddress.autocomplete, data),

  fetchDeliveryZones: () =>
    RequestAPI.get(ENDPOINT.deliveryAddress.zones.request),

  getAddresses: () => RequestAPI.get(ENDPOINT.customer.address.request),

  addAddress: (data) => RequestAPI.post(ENDPOINT.customer.address.add, data),

  updateAddress: (id, data) =>
    RequestAPI.patch(ENDPOINT.customer.address.update + id, data),

  deleteAddress: (id) =>
    RequestAPI.delete(ENDPOINT.customer.address.delete + id),
};
