import { classes } from 'html-classes';
import { times } from 'lodash-es';
import { MouseEvent } from 'react';

import { IconTypes } from '~/components/Icon/IconTypes';

import Icon from '../Icon/Icon';
import Text from '../Text';

interface RatingStarsProps {
  rate: number;
  starSize: number;
  className?: string;
  getStarClassName?: (idx: number) => string;
  type?: 'star-fill' | 'star';
  onStarClick?: (idx: number, e: MouseEvent<HTMLSpanElement>) => void;
  fillIconType?: IconTypes;
}

export const RatingStars = ({
  rate,
  starSize,
  className,
  getStarClassName,
  type,
  onStarClick,
  fillIconType = 'star-fill',
}: RatingStarsProps) => {
  const roundedRate = Math.round(rate);

  return (
    <div className={classes(['star-line', className])}>
      {times(5, (idx) => {
        const starClassName = getStarClassName?.(idx);

        return (
          <span
            key={idx}
            className={starClassName}
            onClick={(e) => onStarClick?.(idx, e)}
          >
            <Icon
              type={idx < roundedRate ? fillIconType : type ?? 'star'}
              size={starSize}
            />
          </span>
        );
      })}
    </div>
  );
};

type Props = {
  rate: number;
  count?: number;
  starSize?: number;
  className?: string;
  classNameText?: string;
  classNameStart?: string;
  showStars?: boolean;
  hideText?: boolean;
  type?: 'star-fill' | 'star';
};

const Rating = ({
  rate,
  count,
  starSize = 10,
  className,
  classNameText,
  classNameStart,
  showStars,
  hideText,
  type,
}: Props) => {
  return (
    <div className={classes(['rating', className])}>
      {showStars ? (
        <RatingStars
          rate={rate}
          starSize={starSize}
          className={classNameStart}
          type={type}
        />
      ) : (
        <Icon
          type="star-fill"
          size={starSize}
          className={classes(['rating__star', classNameStart])}
        />
      )}
      {!hideText && (
        <>
          <Text
            size="12"
            weight="500"
            className={classes([
              'rating__rate',
              showStars && 'rating__count',
              classNameText,
            ])}
          >
            {rate}
          </Text>
          {count && (
            <Text
              size="12"
              weight="500"
              className={classes(['rating__count', classNameText])}
            >
              ({count})
            </Text>
          )}
        </>
      )}
    </div>
  );
};

export default Rating;
