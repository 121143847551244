import { useTranslation } from 'react-i18next';

import EmptyFavsImage from '../../assets/img/empty_fav.png';

const EmptyPurchased = () => {
  const { t } = useTranslation();

  return (
    <div className="page-empty">
      <img src={EmptyFavsImage} className="page-empty__image" alt="" />
      <p className="page-empty__title">{t('phrases:emptyPurchased')}</p>
    </div>
  );
};

export default EmptyPurchased;
