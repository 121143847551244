import { classes } from 'html-classes';
import { MouseEvent } from 'react';

import { company } from '~/company/Company';

import Icon from '../Icon/Icon';

type Props = {
  count?: number;
  onClick?: (count: number, action: 'add' | 'remove', e: MouseEvent) => void;
  size: 's' | 'm' | 'l';
  iconSize?: 's' | 'm' | 'l';
  limit: number;
};

const SIZES = {
  s: 16,
  m: 24,
  l: 30,
};

const Counter = ({ count = 0, onClick, size, iconSize, limit }: Props) => {
  return (
    <div
      data-company={company.name}
      className={classes(['counter', `_${size}`])}
    >
      <button
        type="button"
        className="button counter__button"
        onClick={(e) => onClick && onClick(count - 1, 'remove', e)}
      >
        <Icon type="minus" size={SIZES[iconSize || size]} />
      </button>
      <p className="counter__number">{count}</p>
      <button
        type="button"
        className={classes([
          'button counter__button',
          count >= limit && '_disabled',
        ])}
        onClick={(e) => onClick && onClick(count + 1, 'add', e)}
      >
        <Icon type="plus" size={SIZES[iconSize || size]} />
      </button>
    </div>
  );
};

export default Counter;
