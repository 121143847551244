import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';
import Select from '~/components/Select/Select';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';

interface CardSelectorProps {
  headerClasses?: string;
}

const CardSelector = ({ headerClasses }: CardSelectorProps) => {
  const { t } = useTranslation();
  const hasCards =
    Array.isArray(checkoutStore.payments) && checkoutStore.payments.length > 0;
  const handleNewCard = (flag: boolean) => {
    checkoutStore.setIsAddNewCard(flag);
  };

  useEffect(() => {
    if (!hasCards) {
      checkoutStore.setIsAddNewCard(true);
    }
  }, [hasCards]);

  const paymentsChange = (val: string) => {
    checkoutStore.setPaymentsMethodId(val);
    checkoutStore.setIsDisabled(false);
    mainStore.sendToRN('analytics', {
      name: 'Purchase: payment method filled in',
      params: {
        cart_id: undefined,
        payment_method: 'card',
        type: 'existing',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'add_payment_info',
      params: {
        currency: orderStore.currency.toUpperCase(),
        payment_type: 'credit card',
        value: mainStore.toFloat(catalogStore.finalPrice),
        items: catalogStore.cartForFirebase,
        coupon: catalogStore.promocode.value,
      },
    });
  };

  return (
    <div className={headerClasses}>
      <>
        <div
          className={classes([
            'panel-section__content-payment-add-title-container',
          ])}
        >
          <p className="panel-section__content-payment-add-title">
            {t('paymentDetails')}
          </p>
          {hasCards && (
            <>
              {!checkoutStore.isAddNewCard ? (
                <button
                  className="button _no-padding _no-color"
                  onClick={() => handleNewCard(true)}
                >
                  <Icon type="card-add" size={24} />
                  {t('addNewCard')}
                </button>
              ) : (
                <button
                  className="button _no-padding _no-color"
                  onClick={() => handleNewCard(false)}
                >
                  <Icon type="cards" size={24} />
                  {t('chooseCardInList')}
                </button>
              )}
            </>
          )}
        </div>
        {!checkoutStore.isAddNewCard && hasCards && (
          <Select
            className="panel-section__content-payment-add-select"
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            initialValue={checkoutStore.payments[0].text}
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            data={checkoutStore.payments}
            onChange={paymentsChange}
          />
        )}
        {checkoutStore.error && (
          <div className="alert _error mt-10 z-auto">{checkoutStore.error}</div>
        )}
      </>
    </div>
  );
};

export default observer(CardSelector);
