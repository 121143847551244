import isNil from 'lodash-es/isNil';

import { calcProductOfferFields, parseProperties } from '~/types/Product';

import type { Product } from './Product';
import type { ProductOfferAPI } from '~/api/Catalog';
import type { ProductProperties } from '~/types/Product/interface';

export class Offer {
  public readonly id: number;
  public readonly sku: string;
  public readonly name: string;
  public readonly description: string;
  public readonly metadataTitle: Nullable<string>;
  public readonly metadataDescription: Nullable<string>;
  public readonly ratingAverage: number;
  public readonly ratingMarksCount: number;
  public readonly priceFormatted: string;
  public readonly discountPriceFormatted: string;
  public readonly discountPercentage: string;
  public readonly pricePerUnit: string;
  public readonly previewImageThumb: string;
  public readonly promoRequiredQuantity: number;
  public readonly promoQuantityDiscountPrice: number;
  public readonly promoQuantityDiscountPriceFormatted: string;
  public readonly isBackorderAvailable: boolean;
  public readonly backorderLeadTime: number;
  public readonly properties: ProductProperties;
  public readonly images: string[];
  public readonly slug: string;
  public readonly productId: number;
  public readonly categoryId: Nullable<number>;
  public readonly categoryName: Nullable<string>;
  public readonly parentCategoryId: Nullable<number>;
  public readonly sellableWarehouses: Record<string, string>;
  public readonly bundleId: Nullable<number>;
  public readonly bundleQuantity: Nullable<number>;
  public readonly productSlug: Nullable<string>;
  public readonly badges: string[];

  // TODO: make field changes using the method of this class
  public price: number;
  public discountPrice: number;
  public sellable: number;

  constructor(offer: ProductOfferAPI, product: Product) {
    const { images, imagesThumb } = parseImages(offer.images);

    this.id = offer.id || product.id;
    this.sku = offer.code;
    this.name = offer.name;
    this.price = offer.price || 0;
    this.priceFormatted = '0';
    this.discountPrice = offer.discountPrice || 0;
    this.discountPriceFormatted = '0';
    this.discountPercentage = '';
    this.pricePerUnit = '';
    this.sellable = offer.sellable || 0;
    this.description = offer.description || product.description;
    this.metadataTitle = offer.metadataTitle;
    this.metadataDescription = offer.metadataDescription;
    this.isBackorderAvailable = offer.isBackorderAvailable;
    this.backorderLeadTime = offer.backorderLeadTime;
    this.promoQuantityDiscountPrice = offer.minBasketDiscountPrice || 0;
    this.promoQuantityDiscountPriceFormatted = '0';
    this.promoRequiredQuantity = offer.minBasketDiscountQuantity || 0;
    this.properties = {
      ...product.properties,
      ...parseProperties(offer.properties || []),
    };
    this.productId = product.id;
    this.slug = product.slug;
    this.categoryId = product.categoryId || -1;
    this.categoryName = product.categoryName || '';
    this.parentCategoryId = product.parentCategoryId || -1;
    this.sellableWarehouses = offer.sellableWarehouses || {};
    this.ratingAverage = product.ratingAverage || 0;
    this.ratingMarksCount = product.ratingMarksCount || 0;
    this.bundleId = offer.bundleId;
    this.bundleQuantity = offer.bundleQuantity || null;
    this.productSlug = offer.productSlug || null;

    this.badges = (offer.properties || [])
      .filter(({ type, value }) => type === 'badge' && value !== 'false')
      .map(({ name }) => name);

    this.images = images;
    this.previewImageThumb =
      imagesThumb[0] ||
      images[0] ||
      product.previewImageThumb ||
      product.previewImage;

    calcProductOfferFields(this);
  }

  public static validate(offer: ProductOfferAPI, product: Product) {
    const isBundle =
      'productType' in product && product.productType === 'bundle';
    const isValidDiscount = isBundle
      ? true
      : (offer.discountPrice || 0) < (offer.price || 0);

    return (
      Boolean(offer.code) &&
      Boolean(offer.name) &&
      !isNil(offer.price) &&
      offer.price > 0 &&
      (isNil(offer.discountPrice) || offer.discountPrice > 0) &&
      isValidDiscount &&
      (!offer.minBasketDiscountQuantity ||
        Boolean(offer.minBasketDiscountPrice))
    );
  }
}

const parseImages = (images: ProductOfferAPI['images']) => {
  const result = { images: [] as string[], imagesThumb: [] as string[] };

  if (!images) {
    return result;
  }

  return images.reduce((acc, { url, thumb }) => {
    if (url) {
      acc.images.push(url);
    } else if (thumb) {
      acc.imagesThumb.push(thumb);
    }

    return acc;
  }, result);
};
