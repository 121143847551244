import { JSX, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Legals.module.scss';

import './styles.scss';

type Props = {
  handleLink: (anchor: string) => void;
};

const LEGALS_NAV = [
  {
    link: 'cookie-policy',
    title: 'Cookie policy',
  },
  {
    link: 'payment-and-delivery',
    title: 'Payment and delivery T&C',
  },
  {
    link: 'privacy-policy',
    title: 'Privacy Policy',
  },
  {
    link: 'sales-and-returns-policies-and-consumer',
    title: 'Sales and Returns Policies and Consumer - Protection Procedures',
  },
  {
    link: 'terms-of-service',
    title: 'C D GENERAL TRADING LLC (“CDGT”) Terms Of Service',
  },
];

const LegalsCD = ({ handleLink }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();
  const { state } = useLocation();

  const source = state?.source || 'home';

  useEffect(() => {
    setTimeout(() => {
      const anchor = window.location.hash.slice(1);
      handleLink(anchor);
    }, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      <MobileHeaderLayout
        showTopCart
        content={<MobileHeaderTitle text={t('legals')} />}
      />
      <div className="content-layout legals">
        <div className={styles.root}>
          {!isTablet && (
            <>
              <Breadcrumbs
                items={
                  [
                    {
                      text: t('home'),
                      link: '/',
                    },
                    source === 'cabinet' && {
                      text: t('cabinet'),
                      link: '/cabinet',
                    },
                    {
                      text: t('legals'),
                      link: '/legals',
                    },
                  ].filter(Boolean) as BreadcrumbsItem[]
                }
              />

              <h2 className={styles.title}>{t('legals')}</h2>
            </>
          )}

          <div className={styles.page}>
            <div className={styles.page__links_container}>
              <ul className={styles.page__links}>
                {LEGALS_NAV.map((i) => (
                  <li className={styles.page__links_item} key={i.link}>
                    <a
                      href={`#${i.link}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLink(i.link);
                      }}
                    >
                      {i.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.page__content}>
              <section>
                <h2 id="cookie-policy">Cookie policy</h2>
                <p>
                  Cookie policy C D GENERAL TRADING LLC (&ldquo;CDGT&rdquo;)
                  Cookie Policy
                </p>
                <p>
                  At CDGT, we use cookies and other similar technologies like
                  pixels and tags to allow our website to work, to analyze and
                  improve it, to personalize your experience and to show you
                  relevant advertising. Here&apos;s a breakdown of what that
                  means for you.
                </p>
                <h3>1. What are cookies?</h3>
                <p>
                  Cookies are pretty cool. They make your online experience
                  personalized by helping us show you relevant CDGT adverts when
                  you browse other websites, helping us to understand how you
                  are using our own website, and saving you lots of time by
                  remembering your details. But how do they do it?
                </p>
                <p>
                  Cookies are actually small files that are stored on your
                  computer or phone. They store small bits of data so that when
                  you visit a website, it can remember your preferences and make
                  sure it shows you content that is relevant to you.
                </p>
                <p>
                  All cookies have expiry dates that determine how long they
                  stay in your browser:
                </p>
                <ul>
                  <li>
                    Session cookies are temporary cookies that expire
                    automatically whenever you close your browser or once your
                    session ends.
                  </li>
                  <li>
                    Persistent cookies usually stay in your browser for a set
                    period, until your browser erases them when the cookie
                    reaches its expiration date or until you manually delete
                    them.
                  </li>
                </ul>
                <h3>2. How we track emails</h3>
                <p>
                  Every email we send to you contains small files known as
                  tracking pixels.
                </p>
                <p>
                  These are small graphic files that contain unique identifiers
                  that enable us to recognize when our marketing subscribers
                  have opened an email or clicked certain links. This allows us
                  to record each subscriber&apos;s email address, IP address,
                  device ID, date, and time associated with each open and click
                  for a campaign.
                </p>
                <p>
                  We use this data to create reports about how interesting or
                  useful our marketing campaigns were to our users, then we can
                  adjust them to make our campaigns more relevant. For example,
                  if you never open emails about sushi, we&apos;ll start to
                  assume you don&apos;t want to receive emails about sushi!
                </p>
                <h3>3. Turning off cookies</h3>
                <p>
                  If you turn off all cookies, CDGT won&apos;t work properly. We
                  do not recommend turning off all cookies when using our
                  websites. But if you want to, you can delete your cookies and
                  manage how cookies are used for the browser you use.
                </p>
                <p>Here are the instructions for desktop browsers:</p>
                <ul>
                  <li>GoogleChrome</li>
                  <li>Firefox</li>
                  <li>Safari</li>
                  <li>InternetExplorer</li>
                </ul>
                <p>And here are the instructions for mobile browsers:</p>
                <ul>
                  <li>Google Chrome on Android</li>
                  <li>Firefox on Android</li>
                  <li>Safari on iOS</li>
                </ul>
                <p>
                  To opt out of being tracked by Google Analytics across all
                  websites, visit http://tools.google.com/dlpage/gaoptout.
                </p>
                <h3>4. Need more information?</h3>
                <p>
                  Our Customer and Rider Privacy Policies contain more
                  information about users&apos; data protection rights. If you
                  have any other questions about the way we use cookies, you can
                  contact our general customer services team at:
                  info@citydrinks.com.
                </p>
                <h3>5. Necessary Cookies</h3>
                <p>
                  Without cookies most websites wouldn&apos;t work, that
                  includes CDGT. Necessary cookies are essential for the
                  operation of our website, your browsing experience and they
                  enable us to authenticate you.
                </p>
                <p>The cookies necessary for CDGT to work allow us to:</p>
                <ul>
                  <li>Add items to your basket and create an order</li>
                  <li>Check your order status</li>
                  <li>Enable customer service agents to chat with you</li>
                  <li>Keep records of your consent to our use of Cookies</li>
                </ul>
                <p>
                  Our payment provider Stripe also uses cookies which are
                  necessary to remember your card details and process your
                  payments, without storing your card details on CDGT&apos;s
                  systems.
                </p>
                <p>
                  If you log in to CDGT using social login, the social login
                  partner (such as Google or Facebook) will use certain cookies
                  to allow you to log in. You can check Google and
                  Facebook&apos;s privacy and cookie policies on their websites.
                </p>
                <p>
                  Our customer support system also uses cookies for storing
                  context for your customer support chats.
                </p>
                <h3>6. Analytics cookies</h3>
                <p>
                  Analytics cookies help us understand how people are using our
                  website. This helps us to constantly improve things.
                </p>
                <p>The analytics cookies we use allow us to see:</p>
                <ul>
                  <li>if you&apos;ve visited CDGT before</li>
                  <li>
                    who&apos;s visited our website without being logged into an
                    account &mdash; this tells us how many people are new to
                    CDGT
                  </li>
                  <li>
                    how much time people spend on CDGT and when they close their
                    browser
                  </li>
                  <li>
                    how our customers got to CDGT &mdash; for example, if they
                    came to our website from a Google search
                  </li>
                  <li>
                    which parts of our website are being used and which parts
                    aren&apos;t
                  </li>
                </ul>
                <h3>7. Cookies to improve the content you see</h3>
                <p>
                  We&apos;re also always testing out new ways of making CDGT as
                  good as it can be.
                </p>
                <p>
                  We do this by trying out new ideas with small groups of
                  customers before rolling things out to everybody. In order to
                  do this we need to use&#8230; yes, you&apos;ve guessed it:
                  Cookies!
                </p>
                <p>The cookies we use for this allow us to:</p>
                <ul>
                  <li>know which versions of our website you are visiting</li>
                  <li>
                    control which versions you visit &mdash; to find out which
                    ones work best
                  </li>
                </ul>
                <h3>8. Advertising cookies</h3>
                <p>
                  We use third party cookies and pixels to collect data about
                  your browsing activity on our website. This means we can show
                  you relevant CDGT adverts when you browse other websites. This
                  might include ads for food types you&apos;ve ordered before
                  &mdash; or ads showing you what it&apos;s like to be a CDGT
                  rider or explaining how to apply if you&apos;ve previously
                  visited our rider apply page.
                </p>
                <p>
                  These partners might use cookies, attribution services or
                  similar technologies to check whether we&apos;ve shown you an
                  ad. These cookies are applied in accordance with the
                  partners&rsquo; cookie policy which we have no control over.
                  You can always opt out of third-party cookies through your
                  browser settings.
                </p>
                <p>
                  We do not share any data with third parties that may identify
                  you. Payment and delivery Terms and Conditions
                </p>
              </section>

              <section>
                <h2 id="payment-and-delivery">
                  Payment and delivery Terms and Conditions
                </h2>
                <h3>1. Information About Us</h3>
                <p>
                  CityDrinks is operated by C D General Trading L.L.C. a company
                  incorporated and registered in the UAE, whose registered
                  office is at Mamoura, Khalifa Industrial 9, Abu Dhabi, United
                  Arab Emirates.Our Company registration number is CN- 4854791.
                  You may contact us at info@citydrinks.com, or by using the
                  instant messaging facility on our Application.
                </p>
                <h3>2. Purpose</h3>
                <p>
                  Our objective is to link you to our local dark stores
                  (&quot;Stores&quot;) and allow you to order Items for delivery
                  (our &quot;Service&quot;). Where you order from one of our
                  Stores, citydrinks processes your order from our Application
                  or Website and manages your experience throughout the order
                  process. Once you have placed an order, your Items will be
                  delivered to you by citydrinks.
                </p>
                <h3>3. Service Availability</h3>
                <p>
                  Each Store has a prescribed delivery area. This delivery area
                  may change at any time due to factors such as weather, or
                  demand on our service or other. This is to ensure that Items
                  reach your door at their best. Each Store can also have
                  varying operating hours. That means that the availability of
                  our Service, and the range of Stores from which you can order,
                  depends on the Stores in your area and their opening hours. If
                  you try to order a delivery to a location outside the delivery
                  area or operating hours of a Store, or the Application is
                  otherwise unavailable for any reason, we will notify you that
                  ordering will not be possible.
                </p>
                <h3>4. Orders</h3>
                <p>
                  You have to log into your account, select the Items that you
                  wish to purchase by adding them to your shopping bag. You may
                  modify your selection throughout the purchase process but only
                  before payment has been made. When you place an order through
                  our Application, you are invited to enter a valid delivery
                  address and payment method. You validate the order by clicking
                  and pay. To minimise the risk of unauthorised access, credit
                  card details are encrypted. As soon as the order is received,
                  we request a pre-authorisation on the card to ensure that
                  there are sufficient funds to complete the transaction. Once
                  the payment method is verified, your order becomes accepted.
                  We will send you a notification when we start delivering your
                  order (the &quot;Confirmation Notice&quot;). The contract for
                  the supply of any Item you have ordered comes into existence
                  when we send the Confirmation Notice. You are responsible for
                  paying for all Items ordered using your account, and for
                  related delivery charges, and for complying with these Terms,
                  even if you have ordered the Item for someone else. Some
                  Stores can operate a minimum order value policy. This will be
                  displayed on our Application. All Items are subject to
                  availability, and should an Item be unavailable you will be
                  refunded for the amount paid for such Item. Items available
                  for purchase may contain nuts or other allergens. citydrinks
                  uses different sources of information to detect such items and
                  highlight allergens, but cannot guarantee that any of the
                  Items sold are free of allergens even if allergen information
                  is not present in Application. If you have any doubts about
                  allergens, please contact us using contacts above.
                </p>
                <h3>5. Delivery</h3>
                <p>
                  When you place an order we will tell you an estimated delivery
                  time for your Item before you place the order, but we will
                  attempt delivery as soon as possible; you must therefore be
                  available to accept delivery from the time you place the
                  order. Unfortunately, despite our best efforts, things do not
                  always go to plan and factors such as traffic and weather
                  conditions may prevent us from delivering your Item on time.
                  If your order is more than 25 minutes late, and we
                  haven&apos;t notified you giving you the option to cancel your
                  order, we will work with you to make things right unless you
                  have caused the delay (e.g. because you gave us the wrong
                  address or did not come to the door).
                </p>
                <p>
                  We will attempt delivery at the address you provide to us when
                  you place your order. You may also be charged for delivery,
                  which price is shown during purchase process in the
                  Application. You may be still be charged for the Item and for
                  delivery in the event of a failed delivery if you have caused
                  such failure for any reason. Reasons you might cause a
                  delivery to fail include (but are not limited to):
                </p>
                <p>
                  You do not come to the door, did not pick up the phone when
                  the rider contacted you using the contact information you have
                  provided us and/or you picked up the phone but then failed to
                  provide access within a reasonable amount of time, and the
                  rider is unable to find a safe location to leave the food.
                </p>
                <p>
                  The rider refuses to deliver the Item to you in accordance
                  with section 8 (Age Restricted Products).
                </p>
                <h3>6. Your Rights if Something is Wrong With Your Items</h3>
                <p>
                  You have a legal right to receive goods which comply with
                  their description, which are of satisfactory quality and which
                  comply with any specific requirements you tell us about (and
                  we agree to) before you place your order. If you believe that
                  the Items you have been delivered do not comply with these
                  legal rights, please let us know within 24 hours of receipt.
                  We may request a photograph showing the problem if it is
                  something that can be seen by inspecting the Items. We will
                  provide a refund or account credit in respect of the affected
                  part of the Item, and also in respect of delivery if the whole
                  order was affected, unless we have reasonable cause to believe
                  that the problem was caused after delivery.
                </p>
                <p>
                  Prior to processing your refund or account credit, we may take
                  into account relevant factors including the details of the
                  order, including your account history, what happened on
                  delivery.
                </p>
                <h3>7. Age Restricted and Regulated Products</h3>
                <p>
                  Age restricted products (including, without limitation,
                  alcohol, tobacco and cigarettes) can only be sold and
                  delivered to persons aged 18 or over for tobacco and
                  cigarettes and 21 or over for alcohol. By placing an order for
                  an age restricted product, you confirm that you comply with
                  the legal age required to buy the product. CityDrinks operates
                  an age verification policy. As an example, customers ordering
                  age restricted products will be asked by the rider to provide
                  proof that they are aged 18 or 21 or over before the delivery
                  is completed. The rider may refuse to deliver any age
                  restricted product to any person unless they can provide valid
                  photo ID proving that they are aged 18 or 21 or over. Other
                  verification methods could also be applied and may result in
                  the refusal of delivery of any age restricted products.
                  CityDrinks may refuse to deliver alcohol to any person who is,
                  or appears to be under the in&#64258;uence of alcohol.If the
                  driver cannot confirm your age and refuses to deliver any age
                  restricted product, you will get a refund of the amount paid
                  for the Items as long as the product is not perishable or has
                  been damaged but will not get a refund on delivery charges or
                  expenses incurred.
                </p>
                <h3>8. Cancellation</h3>
                <p>
                  You may cancel an order without charge at any time before we
                  accept the order. If you wish to cancel an order, please
                  contact us immediately, via our Application. If we confirm the
                  order has not started delivery yet, we will refund your
                  payment (excluding any discount, or Voucher or Promo code that
                  was applied to the order - see Voucher Terms for more detail).
                  If you cancel any order after it gets picked up by the rider,
                  you may be charged the full price for the Items, and if the
                  rider has been dispatched you will also be charged for
                  delivery.
                </p>
                <p>
                  CityDrinks may notify you that an order has been cancelled at
                  any time. You will not be charged for any orders cancelled by
                  us, and we will reimburse you for any payment already made
                  using the same method you used to pay for your order. We may
                  also apply credit to your account to re&#64258;ect the
                  inconvenience caused.
                </p>
                <h3>9. Prices, Payment and Offers</h3>
                <p>
                  The Price of the Items is the one displayed on the
                  Application. Prices include VAT. You confirm that you are
                  using our Service for personal, non-commercial use unless you
                  request a VAT invoice. CityDrinks may operate dynamic pricing
                  some of the time, which means that prices of Items and
                  delivery cost may change while you are browsing. Prices can
                  also change at any time at our discretion. We reserve the
                  right to charge a Service Fee, which may be subject to change,
                  for the provision of our Services. You will be notified of any
                  applicable Service Fee and taxes prior to purchase on the
                  checkout page on our Application. No changes will affect
                  existing confirmed orders. If there is an obvious pricing
                  mistake, we will notify you as soon as we can, and you will
                  have the choice of confirming the order at the original price
                  or cancelling the order without charge and with a full refund
                  of any money already paid. Where citydrinks makes a delivery,
                  we may also charge you a delivery fee. This will be notified
                  to you during the order process before you complete your
                  order.
                </p>
                <p>
                  The total price of your order will be set out on the checkout
                  page on our Application, including the prices of Items and
                  Delivery and applicable Service Fees and taxes. &#8232;
                  Payment for all Items and deliveries can be made on our
                  Application by credit or debit card, or other payment method
                  made available by citydrinks. Once your order has been
                  confirmed your credit or debit card will be authorized and the
                  total amount marked for payment. Payment is made directly to
                  citydrinks. Payment may also be made by using vouchers or
                  account credit. Use of these is subject to citydrinks&rsquo;
                  Voucher Terms. &#8232; Where cash payment is possible, this
                  will be made clear on our Application before you place your
                  order. &#8232; CityDrinks sometimes make special offers
                  available through Application. These offers are at the
                  discretion of each Store. Unless the offer terms state a fixed
                  or minimum period for which an offer will be available, it can
                  be withdrawn at any time, unless you have already placed an
                  order based on the offer, and we have sent the Confirmation
                  Notice.
                </p>
                <h3>10. Tips</h3>
                <p>
                  After you receive your order, you might have the option to pay
                  a tip to your driver or picker. CityDrinks will collect
                  payment on behalf of the rider, as their limited payment
                  collection agent, and payment of the tips shall be considered
                  a direct payment from you to the rider. Your rider will
                  receive 100% of any payment you choose to make. As this
                  payment is made after you receive your order, this payment is
                  non-refundable and does not form part of your order. Depending
                  on the payment method used for your original order, your tip
                  may show up on your bank/credit card statement as a separate
                  payment. Privacy Policy
                </p>
              </section>

              <section>
                <h2 id="privacy-policy">Privacy Policy</h2>
                <p>This privacy policy was last updated: 04/10/2023.</p>
                <p>
                  C D GENERAL TRADING LLC (&quot;we&quot;, &quot;our&quot;,
                  &quot;us&quot; or &quot;citydrinks&quot;) is committed to
                  protecting the privacy of all users of our website
                  https://citydrinks.com, or mobile applications (together, the
                  &quot;Sites&quot;). Please read the following privacy policy
                  that explains how we use and protect your information. We are
                  the &quot;data controller&quot; of the information we process,
                  unless otherwise stated.
                </p>
                <h3>1. Contact Details</h3>
                <p>
                  If you have any queries or requests concerning this privacy
                  policy or how we handle your data more generally, please get
                  in touch by contacting our general customer services team at:
                  info@citydrinks.com.
                </p>
                <h3>2. How We Collect Your Information</h3>
                <p>
                  We collect your personal information when you interact with us
                  or use our services, such as when you use our Sites to place
                  an order. We also look at how visitors use our Sites, to help
                  us improve our services and optimize customer experience.
                </p>
                <p>We collect information:</p>
                <ul>
                  <li>
                    when you create an account with us or you change your
                    account settings;
                  </li>
                  <li>
                    when you place an order with us and during the order process
                    (including for payment and order delivery);
                  </li>
                  <li>
                    when you give us your consent to contact you via email,
                    phone, post, message or via our chat function to send you
                    marketing campaigns, or to invite you to participate in
                    surveys about our services, or our partners&apos; services;
                  </li>
                  <li>
                    when you contact us directly via email, phone, post, message
                    or via our chat function;
                  </li>
                  <li>
                    when you browse and use our Sites (before and after you
                    create an account with us).
                  </li>
                </ul>
                <p>
                  We also collect information from third party sites, such as
                  advertising platforms and our fraud detection provider.
                </p>
                <h3>3. Information We Collect From You</h3>
                <p>
                  As part of our commitment to the privacy of our customers and
                  visitors to our Sites more generally, we want to be clear
                  about the sorts of information we will collect from you.
                </p>
                <p>
                  When you visit the Sites or make a citydrinks order through
                  the Sites, you are asked to provide information about yourself
                  including your name, contact details, delivery address, order
                  details and payment information such as credit or debit card
                  information. We may also collect your date of birth to verify
                  your age when you purchase age restricted items.
                </p>
                <p>
                  We also collect information about your usage of the Sites and
                  information about you from any messages you post to the Sites
                  or when you contact us or provide us with feedback, including
                  via e-mail, letter, phone or chat function. If you contact us
                  by phone, we record the call for training and service
                  improvement purposes, and make notes in relation to your call.
                </p>
                <p>
                  We collect technical information from your mobile device or
                  computer, such as its operating system, the device and
                  connection type and the IP address from which you are
                  accessing our Sites.
                </p>
                <p>
                  We also collect technical information about your use of our
                  services through a mobile device, for example, carrier,
                  location data and performance data such as mobile payment
                  methods, interaction with other retail technology such as use
                  of NFC Tags, QR Codes and/or use of mobile vouchers. Unless
                  you have elected to remain anonymous through your device
                  and/or platform settings, this information may be collected
                  and used by us automatically if you use the service through
                  your mobile device(s) via any citydrinks mobile application,
                  through your mobile&apos;s browser or otherwise.
                </p>
                <p>
                  We process health information about you only where you
                  volunteer and consent to this, for example if you specify any
                  food allergies.The health information you share with us will
                  be kept confidential and will not be used for non-health
                  purposes. The food allergies you indicated will not be
                  disclosed other than on a need-to-know basis and after
                  obtaining your consent to ensure to the best of our ability
                  not to suggest any foods you are allergic to.
                </p>
                <h3>4. Use Of Your Information</h3>
                <p>
                  We will only process the data we collect about you if there is
                  a reason for doing so, and if that reason is permitted under
                  Decree Law No.45 of 2021 on the Protection of Personal Data in
                  the UAE and its implementing regulations (the
                  &ldquo;PDPL&rdquo;).
                </p>
                <p>
                  Where we need to in order to provide you with the service you
                  have requested or to enter into a contract, we use your
                  information:
                </p>
                <ul>
                  <li>
                    to enable us to provide you with access to the relevant
                    parts of the Sites;
                  </li>
                  <li>to supply the services you have requested;</li>
                  <li>to enable us to collect payment from you;</li>
                  <li>
                    to contact you where necessary concerning our services, such
                    as to resolve issues you may have with your order or gather
                    feedback about your order.
                  </li>
                </ul>
                <p>
                  We also process your data when the processing is necessary to
                  perform a contract to which you and us are party to (use of
                  our services) for the personalization of our service,
                  including processing data to make it easier and faster for you
                  to place orders. We have listed these reasons below:
                </p>
                <ul>
                  <li>
                    to improve the effectiveness and quality of service that our
                    customers can expect from us in the future;
                  </li>
                  <li>
                    to tailor content that we or advertising partners display to
                    you, for example so that we can show you products which are
                    in your area or make sure you see the advertising which is
                    most relevant to you, based on characteristics determined by
                    us;
                  </li>
                  <li>
                    to enable our customer support team to help you with any
                    inquiries or complaints in the most efficient way possible
                    and to provide a positive customer experience;
                  </li>
                  <li>
                    to contact you for your views and feedback on our services
                    or our partners&apos; services and/or products and to notify
                    you if there are any important changes or developments to
                    the Sites or our services, including letting you know that
                    our services are operating in a new area, where you have
                    asked us to do so;
                  </li>
                  <li>
                    to send you information by post about our products, services
                    and promotions (if you do not want to receive these, you can
                    let us know by getting in touch (see Contact Details));
                  </li>
                  <li>
                    to analyse your activity on the Sites so that we can
                    administer, support, improve and develop our business and
                    for statistical and analytical purposes and to help us to
                    prevent fraud.
                  </li>
                </ul>
                <p>
                  We also process your data to enforce our contractual terms
                  with you and any other agreement, and for the exercise or
                  defence of legal claims and to protect the rights of
                  citydrinks, riders, or others (including to prevent fraud).
                </p>
                <p>
                  If you submit comments and feedback regarding the Sites and
                  the services, we may use such comments and feedback on the
                  Sites and in any marketing or advertising materials. We will
                  only identify you for this purpose by your first name and the
                  city in which you live. Where you have chosen to receive push
                  notifications from us through our mobile application, we may
                  send you push notifications relating to the services that you
                  have requested from us and information about our services and
                  offers. You can choose to stop receiving push notifications
                  from us at any time by changing your preferences on your
                  mobile device or by getting in touch (see Contact Details).
                </p>
                <p>
                  We will also analyse data about your use of our services from
                  your location data to create profiles relating to you and for
                  you. This means that we may make certain assumptions about
                  what you may be interested in and use this, for example, to
                  send you more tailored marketing communications, to present
                  you with restaurants that we think you will prefer, or to let
                  you know about special offers or products which we think you
                  may be interested in. This activity is referred to as
                  profiling. You have certain rights in relation to this type of
                  processing. Please see &apos;Your Rights&apos; section below
                  for more information.
                </p>
                <h3>5. Cookies</h3>
                <p>
                  You can set your browser to refuse all or some browser
                  cookies, or to alert you when websites set or access cookies.
                  If you disable or refuse cookies, please note that some parts
                  of the Site may become inaccessible or not function properly.
                  For more information about the cookies we use, please see our
                  Cookie Policy citydrinks.com/legals.
                </p>
                <h3>6. Direct Marketing</h3>
                <p>
                  Where you have given your consent we will use your information
                  to let you know about our other products and services that may
                  be of interest to you and we may contact you to do so by
                  email, post or phone. You can control your marketing
                  preferences by:
                </p>
                <ul>
                  <li>visiting our mobile application;</li>
                  <li>
                    clicking on &quot;Menu&quot; at the main page of the app
                  </li>
                  <li>scrolling down to &quot;Notification settings&quot;.</li>
                </ul>
                <p>
                  Personal data collected for direct marketing purposes can
                  either be shared directly by you or extracted from third party
                  service providers or systems used for the operation of the
                  application. You consent to our processing of your personal
                  data for promotional purposes and direct marketing by ticking
                  on the &ldquo;marketing consent box&rdquo; at the bottom of
                  the policy.&#160;
                </p>
                <p>
                  You acknowledge that your consent evidenced by ticking the
                  &ldquo;marketing consent box&rdquo; is clear, simple,
                  unambiguous and easily accessible.&#160; &#8232; You have the
                  right to object to and stop the processing of your data for
                  direct marketing purposes at any time by opting out of any
                  direct marketing emails, post, calls or messages.
                </p>
                <h3>7. Automated Decision Making</h3>
                <p>
                  We conduct fraud checks on all customers. Where we believe we
                  may detect fraudulent activity we may block you from placing
                  an order and using our Sites.
                </p>
                <p>
                  We undertake fraud checks on all customers because this is
                  necessary for us to perform our contracted services to
                  customers, by ensuring that the services we provide are duly
                  paid for, and also so that individuals themselves are
                  protected from fraudulent transactions on their cards.
                </p>
                <p>
                  Given the volumes of customers and orders we deal with, along
                  with manual checks, we may use automated systems including
                  third party fraud detection providers in order to make
                  automated decisions as to whether or not we will accept an
                  order.
                </p>
                <p>
                  The checks and decisions that are made look at various
                  components including known industry indicators of fraud which
                  our expert fraud detection provider makes available to us, as
                  well as fraud patterns we have detected on our Sites.
                </p>
                <p>
                  You have certain rights in respect of this activity - please
                  see &apos;Your Rights&apos; section below for more
                  information. Our fraud detection is in place to protect all of
                  our customers as well as citydrinks. You have the right to
                  contest any fraud decision made about you and to be given more
                  information about why any such decision was made by contacting
                  us as set out in section 1 above.
                </p>
                <h3>8. Retention Of Your Information</h3>
                <p>
                  We will not retain your information for any longer than
                  necessary to fulfill the process for which it was collected.
                </p>
                <p>
                  Information that we collect will be retained for as long as
                  needed to fulfil the purposes outlined in the &apos;Use of
                  your information&apos; section above or for a period
                  specifically required by applicable regulations or laws, such
                  as retaining the information for regulatory reporting purposes
                  as long as it is anonymized using the
                  &ldquo;Anonymization&rdquo; feature.
                </p>
                <p>
                  When determining the relevant retention periods, we will take
                  into account factors including:
                </p>
                <ul>
                  <li>
                    our contractual obligations and rights in relation to the
                    information involved;
                  </li>
                  <li>
                    legal obligation(s) under applicable law to retain data for
                    a certain period of time;
                  </li>
                  <li>statute of limitations under applicable law(s);</li>
                  <li>(potential) disputes;</li>
                  <li>
                    guidelines issued by relevant data protection authorities.
                  </li>
                </ul>
                <p>
                  Otherwise, we securely erase your information where we no
                  longer require your information for the purposes collected.
                </p>
                <h3>9. Disclosure Of Your Information</h3>
                <p>
                  The information we collect about you will be transferred to
                  and stored on our servers located within the UK. We are very
                  careful and transparent about who else your information is
                  shared with.
                </p>
                <p>
                  We share your information with third party service providers
                  which provide services on our behalf. The types of third party
                  service providers whom we share your information with include
                  for example:
                </p>
                <ul>
                  <li>
                    Payment providers (including online payment providers and
                    fraud detection providers) for the purposes of providing
                    services to us, for example when they process information
                    such as credit card payments for us, provide support
                    services to you or carry out fraud checks for us;
                  </li>
                  <li>
                    IT service providers (including cloud providers) for the
                    purposes of data storage and analysis;
                  </li>
                  <li>Riders so they can deliver your order to you;</li>
                  <li>
                    Customer support partners who will help us to resolve any
                    issues you may have with our services;
                  </li>
                  <li>
                    Marketing and advertising partners so that they can ensure
                    that you see advertising which is more relevant to you and
                    send you email and postal marketing on our behalf;
                  </li>
                </ul>
                <p>Other partners with your consent to the disclosure</p>
                <p>
                  CityDrinks will take all steps reasonably necessary to ensure
                  that your data is treated securely and in accordance with this
                  privacy policy when it is transferred to third parties.
                </p>
                <p>
                  If our business enters into a joint venture with, purchases or
                  is sold to or merged with another business entity, your
                  information may be disclosed or transferred to the target
                  company, our new business partners or owners or their
                  advisors.
                </p>
                <p>We may also share your information:</p>
                <ul>
                  <li>
                    if we are under a duty to disclose or share your information
                    in order to comply with (and/or where we believe we are
                    under a duty to comply with) any legal obligation or
                    regulatory requirement. This includes exchanging information
                    with other companies and other organisations for the
                    purposes of fraud protection and prevention;
                  </li>
                  <li>
                    in order to enforce our contractual terms with you and any
                    other agreement;
                  </li>
                  <li>
                    to protect the rights of citydrinks, riders, or others,
                    including to prevent fraud;
                  </li>
                  <li>
                    with such third parties as we reasonably consider necessary
                    in order to prevent crime, e.g. the police or for health and
                    safety purposes.
                  </li>
                </ul>
                <h3>10. International transfers of data</h3>
                <p>
                  Your personal information may be transferred to, and processed
                  in, countries other than the country in which you are resident
                  including to countries that are not recognized by the&#160;UAE
                  Data Office&#160;(to be established by virtue of the PDPL) as
                  providing an adequate level of data protection. These
                  countries may have data protection laws that are different to
                  the laws of your country. In this context, we ensure that the
                  level of protection your Personal Data is given is not
                  adversely affected by such transfers.
                </p>
                <p>
                  This specifically means that each of the intended transfers is
                  based on one of the following mechanisms at least:
                </p>
                <ul>
                  <li>
                    the existence of an adequacy decision issued by the UAE Data
                    Office for the country that your Personal Data is
                    transferred to; or, alternatively;
                  </li>
                  <li>
                    the existence of an exemption related to one of the specific
                    situations exhaustively provided for by the PDPL
                    (e.g.,&#160;where you have given your consent to such
                    transfer having been informed of the absence of safeguards,
                    where the transfer is necessary for the performance of a
                    contract concluded between us, where the transfer is
                    necessary for the conclusion or performance of a contract
                    concluded in your interest between us and a third party, or
                    where the transfer is necessary for the establishment,
                    exercise or defense of our legal claims, etc.). &#8232;
                    However, we have taken appropriate safeguards to require
                    that your personal information will remain protected in
                    accordance with this Privacy Notice.
                  </li>
                </ul>
                <h3>11. Security</h3>
                <p>
                  We adopt robust technologies and policies to ensure the
                  personal information we hold about you is suitably protected.
                </p>
                <p>
                  We take steps to protect your information from unauthorised
                  access and against unlawful processing, accidental loss,
                  destruction and damage.
                </p>
                <p>
                  Where you have chosen a password that allows you to access
                  certain parts of the Sites, you are responsible for keeping
                  this password confidential. We advise you not to share your
                  password with anyone.
                </p>
                <p>
                  Unfortunately, the transmission of information via the
                  internet is not completely secure. We will take steps to
                  protect your information, and use strict procedures and
                  security features to try to prevent unauthorised access.
                </p>
                <h3>12. Your Rights</h3>
                <p>
                  Subject to applicable law, you may have a number of rights
                  concerning the data we hold about you. If you wish to exercise
                  any of these rights, please contact us using the contact
                  details set out above. For additional information on your
                  rights please contact your data protection authority and see
                  below.
                </p>
                <p>
                  To the extent provided by the law of your jurisdiction, you
                  may request access to the personal information we maintain
                  about you or request that we correct, update, amend, or delete
                  your information, or that we restrict the processing of such
                  information by contacting us as indicated below.
                </p>
                <p>
                  You may have the right to obtain your personal information in
                  an accessible and transferable format so that you can re-use
                  it for your own purposes across different service providers.
                </p>
                <p>
                  Where provided by law, you may withdraw any consent you
                  previously provided to us or object at any time on legitimate
                  grounds to the processing of your personal information, and we
                  will apply your preferences going forward. This will not
                  affect the lawfulness of our use of your information based on
                  your consent before its withdrawal.
                </p>
                <p>
                  You can object by changing your marketing preferences,
                  disabling cookies as set out in sections 7 and 8 above or by
                  getting in touch (see Contact Details).
                </p>
                <h3>13. Changes To Our Privacy Policy</h3>
                <p>
                  Any changes to our privacy policy will be posted to the Sites
                  and, where appropriate, we may notify you of the changes for
                  example by email or push notification.
                </p>
                <h3>14. Complaints</h3>
                <p>
                  If you&apos;re not satisfied with our response to any
                  complaint or believe our processing of your information does
                  not comply with data protection law, you can make a complaint
                  to the UAE Data Office using the following details:
                </p>
                <p>
                  Address: KLP02 C-32 KEZAD &ndash; Khalifa Industrial Zone -
                  Abu Dhabi, United Arab Emirates
                </p>
                <p>
                  Website: citydrinks.com &#8232; C D General Trading L.L.C.,
                  Mamoura, Khalifa Industrial 9, Abu Dhabi, United Arab
                  Emirates, company registration number is CN-4854791.&#160;
                </p>
                <p>
                  Sales and Returns Policies and Consumer - Protection
                  Procedures
                </p>
              </section>

              <section>
                <h2 id="sales-and-returns-policies-and-consumer">
                  Sales and Returns Policies and Consumer - Protection
                  Procedures
                </h2>
                <h3>1. Information About Us</h3>
                <p>
                  CityDrinks is operated by C D General Trading L.L.C. a company
                  incorporated and registered in the UAE, whose registered
                  office is at Mamoura, Khalifa Industrial 9, Abu Dhabi, United
                  Arab Emirates.Our Company registration number is CN- 4854791.
                  You may contact us at info@citydrinks.com, or by using the
                  instant messaging facility on our Application.
                </p>
                <h3>2. Purpose</h3>
                <p>
                  Our objective is to link you to our local dark stores
                  (&quot;Stores&quot;) and allow you to order Items for delivery
                  (our &quot;Service&quot;). Where you order from one of our
                  Stores, citydrinks processes your order from our Application
                  or Website and manages your experience throughout the order
                  process. Once you have placed an order, your Items will be
                  delivered to you by citydrinks.
                </p>
                <h3>3. Your Rights if Something is Wrong With Your Items</h3>
                <p>
                  You have a legal right to receive goods which comply with
                  their description, which are of satisfactory quality and which
                  comply with any specific requirements you tell us about (and
                  we agree to) before you place your order. If you believe that
                  the Items you have been delivered do not comply with these
                  legal rights, please let us know within 24 hours of receipt.
                  We may request a photograph showing the problem if it is
                  something that can be seen by inspecting the Items. We will
                  provide a refund or account credit in respect of the affected
                  part of the Item, and also in respect of delivery if the whole
                  order was affected, unless we have reasonable cause to believe
                  that the problem was caused after delivery.
                </p>
                <p>
                  Prior to processing your refund or account credit, we may take
                  into account relevant factors including the details of the
                  order, including your account history, what happened on
                  delivery.
                </p>
                <h3>4. Age Restricted and Regulated Products</h3>
                <p>
                  Age restricted products (including, without limitation,
                  alcohol, tobacco and cigarettes) can only be sold and
                  delivered to persons aged 18 or over for tobacco and
                  cigarettes and 21 or over for alcohol. By placing an order for
                  an age restricted product, you confirm that you comply with
                  the legal age required to buy the product. CityDrinks operates
                  an age verification policy. As an example, customers ordering
                  age restricted products will be asked by the rider to provide
                  proof that they are aged 18 or 21 or over before the delivery
                  is completed. The rider may refuse to deliver any age
                  restricted product to any person unless they can provide valid
                  photo ID proving that they are aged 18 or 21 or over. Other
                  verification methods could also be applied and may result in
                  the refusal of delivery of any age restricted products. If the
                  driver cannot confirm your age and refuses to deliver any age
                  restricted product, you will get a refund of the amount paid
                  as long as the product is not perishable or has been damaged.
                </p>
                <h3>
                  5. Our Responsibility for Loss or Damage That You Suffer
                </h3>
                <p>
                  We are responsible to you for any loss or damage that you
                  suffer that is a foreseeable result of our breaking of our
                  Terms and Conditions (the &ldquo;Terms&rdquo;) or of failing
                  to use reasonable care and skill in relation to your use of
                  our Service. We are not responsible for any loss or damage
                  that is not foreseeable. Loss or damage is
                  &quot;foreseeable&quot; if it is either obvious that it will
                  happen, or if you told us that it might happen, for example if
                  you tell us about particular circumstances that might increase
                  the loss or damage arising from our breach of these Terms
                  before you place an order.
                </p>
                <p>
                  We do not exclude or limit our responsibility to you for loss
                  or damage where it would be unlawful to do so. This includes
                  any responsibility for death or personal injury caused by our
                  failure, or our employees&apos;, agents&apos; or
                  subcontractors&apos; failure, to use reasonable care and
                  skill; for fraud or fraudulent misrepresentation; for breach
                  of your legal rights in relation to the Items, as summarize at
                  part 7 above; or for defective Items under the Federal Law No.
                  15 of 2020 on Consumer Protection Law in the UAE and its
                  implementing regulations. Subject to the previous paragraph,
                  we are not responsible for any loss or damage that you suffer
                  as a result of your own breach of these Terms, or as a result
                  of any IT hardware or software failure other than a failure in
                  our Applications.
                </p>
                <h3>6. Data Protection</h3>
                <p>
                  We process your personal data in accordance with our Privacy
                  Policy which can be found here.
                </p>
                <h3>7. Chargebacks, refunds and disputes</h3>
                <p>
                  When you use a card for payment through your account the
                  issuer of that card is responsible for the settlement of the
                  transaction. You acknowledge that transaction errors relating
                  to such transactions may result in a reversal of the
                  transaction, fees, claims, penalty or chargeback from the
                  financial institution or payment services provider that has
                  issued the payment card. You acknowledge that the financial
                  institution or payment services provider which issues or
                  supports the designated payment method you have linked to the
                  wallet determines any amount reversed, returned or charged
                  back. CityDrinks is bound to follow the instructions of that
                  financial institution or payments services provider. You agree
                  that you will be responsible for resolving any disputes with
                  the financial institution which has issued your card.
                </p>
                <p>
                  If, notwithstanding the above, you have a complaint in
                  relation to any transactions you have made using your account
                  please contact us. Any dispute must be submitted within 30
                  days of the transaction in dispute. We may ask for information
                  about the disputed transaction but please note that we will
                  never ask you for any security information about your account.
                </p>
                <p>
                  CityDrinks reserves the right to investigate the circumstances
                  of each complaint and determine the most appropriate course of
                  action, including: a) declining your application for any or
                  all of the services, b) terminating any or all of the
                  services, c) reversing any relevant transaction, d)
                  withholding funds from you or restricting your access to your
                  account, or e) doing anything else we reasonably consider
                  necessary.
                </p>
                <p>
                  We will inform you of any such actions we take unless we have
                  reasonable belief that we are prevented from doing so by law
                  or regulation or we believe that doing so would compromise our
                  anti-fraud or security measures.
                </p>
                <p>
                  You acknowledge that citydrinks is under no obligation to
                  recall funds and will not be liable if it is unable to
                  partially or fully recall the funds subject to the dispute.
                  You may not be eligible to receive a refund if you failed to
                  keep the security details relating to your card safe or are a
                  victim of financial fraud, even if the transaction was done by
                  third parties without your knowledge or permission. C D
                  GENERAL TRADING LLC (&ldquo;CDGT&rdquo;) Terms Of Service
                </p>
              </section>

              <section>
                <h2 id="terms-of-service">
                  C D GENERAL TRADING LLC (“CDGT”) Terms Of Service
                </h2>
                <p>Last updated: 26.04.2022</p>
                <p>
                  Welcome to CDGT. This page (together with the documents
                  referred to in it) tells you the terms (the &quot;Terms&quot;)
                  which apply when you order any menu items (the
                  &quot;Items&quot;) from our citydrinks.com website or mobile
                  applications and related services (each referred to as an
                  &quot;Application&quot;). Please read these Terms carefully
                  before creating a CDGT account or using our Application. If
                  you have any questions relating to these Terms please contact
                  info@citydrinks.com. Your legal rights are not affected by
                  these Terms, which apply in addition to them and do not
                  replace them. Before making a purchase, the Customer will be
                  invited to read these Terms by clicking on a link, to print
                  them, to save them and to accept them. By setting up your CDGT
                  account, you confirm that you accept these Terms.
                </p>
                <h3>1. Information About Us</h3>
                <p>
                  CityDrinks is operated by C D General Trading L.L.C. a company
                  incorporated and registered in the UAE, whose registered
                  office is at Mamoura, Khalifa Industrial 9, Abu Dhabi, United
                  Arab Emirates.Our Company registration number is CN-4854791.
                  You may contact us at info@citydrinks.com, or by using the
                  instant messaging facility on our Application.
                </p>
                <h3>2. Purpose</h3>
                <p>
                  Our objective is to link you to our local dark stores
                  (&quot;Stores&quot;) and allow you to order Items for delivery
                  (our &quot;Service&quot;). Where you order from one of our
                  Online Stores, CDGT processes your order from our Application
                  or Website and manages your experience throughout the order
                  process. Once you have placed an order, your Items will be
                  delivered to you by CDGT.
                </p>
                <h3>3. Your Account</h3>
                <p>
                  Before you can place orders for Items using our Application,
                  you need to open a CDGT account. When you open an account you
                  may create a password, or other secure login method, and may
                  also have to provide credit card details. You must keep any
                  password you create, or other secure login method, secret, and
                  prevent others from accessing your email account or mobile
                  phone.You are advised not to use the same password as that
                  used for other sites. We will not be liable in the event of
                  loss, theft or fraudulent use of your account. You undertake
                  to inform us immediately of any unauthorised use of your
                  password or identification or threat of attack on your
                  account.If another person uses these methods to access your
                  account, you will be responsible to pay for any Items they
                  order, and we are not responsible for any other losses you
                  suffer, unless the person using your password obtained it
                  because we did not keep it secure.
                </p>
                <p>
                  You may close your account at any time by requesting to do so
                  by contacting us using the contact details above. We may
                  suspend your access to your account, or close it permanently,
                  if we believe that your account has been used by someone else.
                  We may also close your account if in our opinion you are
                  abusing our Service (for example, by applying for refunds or
                  credit to which we do not consider you are entitled, making
                  repeated unreasonable complaints, mistreating our staff or
                  riders, or any other good reason). If we close your account
                  permanently we will refund any remaining account credit you
                  have validly obtained from our customer service team or
                  Application following any issue with an order, by applying a
                  credit to your registered credit card, or if that is not
                  possible for any reason, by way of a bank transfer using bank
                  details (provided you have supplied them to us). You should
                  know that in case of termination or closure of the account,
                  all your data will be lost, and you will not be able to
                  reactivate your previous account.
                </p>
                <h3>4. Service Availability</h3>
                <p>
                  Each Store has a prescribed delivery area. This delivery area
                  may change at any time due to factors such as weather, or
                  demand on our service or other. This is to ensure that Items
                  reach your door at their best. Each Store can also have
                  varying operating hours. That means that the availability of
                  our Service, and the range of Stores from which you can order,
                  depends on the Stores in your area and their opening hours. If
                  you try to order a delivery to a location outside the delivery
                  area or operating hours of a Store, or the Application is
                  otherwise unavailable for any reason, we will notify you that
                  ordering will not be possible.
                </p>
                <h3>5. Orders</h3>
                <p>
                  You have to log into your account, select the Items that you
                  wish to purchase by adding them to your shopping bag. You may
                  modify your selection throughout the purchase process but only
                  before payment has been made. When you place an order through
                  our Application, you are invited to enter a valid delivery
                  address and payment method. You validate the order by clicking
                  and pay. To minimise the risk of unauthorised access, credit
                  card details are encrypted. As soon as the order is received,
                  we request a pre-authorisation on the card to ensure that
                  there are sufficient funds to complete the transaction. Once
                  the payment method is verified, your order becomes accepted.
                  We will send you a notification when we start delivering your
                  order (the &quot;Confirmation Notice&quot;). The contract for
                  the supply of any Item you have ordered comes into existence
                  when we send the Confirmation Notice. You are responsible for
                  paying for all Items ordered using your account, and for
                  related delivery charges, and for complying with these Terms,
                  even if you have ordered the Item for someone else. Some
                  Stores can operate a minimum order value policy. This will be
                  displayed on our Application. All Items are subject to
                  availability and should an Item be unavailable you will be
                  refunded for the amount paid for such Item. Items available
                  for purchase may contain nuts or other allergens. CDGT uses
                  different sources of information to detect such items and
                  highlight allergens, but cannot guarantee that any of the
                  Items sold are free of allergens even if allergen information
                  is not present in Application. If you have any doubts about
                  allergens, please contact us using contacts above.
                </p>
                <h3>6. Delivery</h3>
                <p>
                  When you place an order we will tell you an estimated delivery
                  time for your Item before you place the order, but we will
                  attempt delivery as soon as possible; you must therefore be
                  available to accept delivery from the time you place the
                  order. Unfortunately, despite our best efforts, things do not
                  always go to plan and factors such as traffic and weather
                  conditions may prevent us from delivering your Item on time.
                  If your order is more than 25 minutes late, and we
                  haven&apos;t notified you giving you the option to cancel your
                  order, we will work with you to make things right unless you
                  have caused the delay (e.g. because you gave us the wrong
                  address or did not come to the door).
                </p>
                <p>
                  We will attempt delivery at the address you provide to us when
                  you place your order. You may also be charged for delivery,
                  which price is shown during purchase process in the
                  Application. You may be still be charged for the Item and for
                  delivery in the event of a failed delivery if you have caused
                  such failure for any reason. Reasons you might cause a
                  delivery to fail include (but are not limited to): You do not
                  come to the door, did not pick up the phone when the rider
                  contacted you using the contact information you have provided
                  us and/or you picked up the phone but then failed to provide
                  access within a reasonable amount of time, and the rider is
                  unable to find a safe location to leave the food.
                </p>
                <p>
                  The rider refuses to deliver the Item to you in accordance
                  with section 8 (Age Restricted Products).
                </p>
                <h3>7. Your Rights if Something is Wrong With Your Items</h3>
                <p>
                  You have a legal right to receive goods which comply with
                  their description, which are of satisfactory quality and which
                  comply with any specific requirements you tell us about (and
                  we agree to) before you place your order. If you believe that
                  the Items you have been delivered do not comply with these
                  legal rights, please let us know within 24 hours of receipt.
                  We may request a photograph showing the problem if it is
                  something that can be seen by inspecting the Items. We will
                  provide a refund or account credit in respect of the affected
                  part of the Item, and also in respect of delivery if the whole
                  order was affected, unless we have reasonable cause to believe
                  that the problem was caused after delivery. Prior to
                  processing your refund or account credit, we may take into
                  account relevant factors including the details of the order,
                  including your account history, what happened on delivery.
                </p>
                <h3>8. Age Restricted and Regulated Products</h3>
                <p>
                  Age restricted products (including, without limitation,
                  alcohol, tobacco and cigarettes) can only be sold and
                  delivered to persons aged 18 or over for tobacco and
                  cigarettes and 21 or over for alcohol. By placing an order for
                  an age restricted product, you confirm that you comply with
                  the legal age required to buy the product. CDGT operates an
                  age verification policy. As an example, customers ordering age
                  restricted products will be asked by the rider to provide
                  proof that they are aged 18 or 21 or over before the delivery
                  is completed. The rider may refuse to deliver any age
                  restricted product to any person unless they can provide valid
                  photo ID proving that they are aged 18 or 21 or over. Other
                  verification methods could also be applied and may result in
                  the refusal of delivery of any age restricted products. CDGT
                  may refuse to deliver alcohol to any person who is, or appears
                  to be under the influence of alcohol.If the driver cannot
                  confirm your age and refuses to deliver any age restricted
                  product, you will get a refund of the amount paid for the
                  Items as long as the product is not perishable or has been
                  damaged but will not get a refund on delivery charges or
                  expenses incurred.
                </p>
                <h3>9. Cancellation</h3>
                <p>
                  You may cancel an order without charge at any time before we
                  accept the order. If you wish to cancel an order, please
                  contact us immediately, via our Application. If we confirm the
                  order has not started delivery yet, we will refund your
                  payment (excluding any discount, or Voucher or Promo code that
                  was applied to the order - see Voucher Terms for more detail).
                  If you cancel any order after it gets picked up by the rider,
                  you may be charged the full price for the Items, and if the
                  rider has been dispatched you will also be charged for
                  delivery. CDGT may notify you that an order has been cancelled
                  at any time. You will not be charged for any orders cancelled
                  by us, and we will reimburse you for any payment already made
                  using the same method you used to pay for your order. We may
                  also apply credit to your account to reflect the inconvenience
                  caused.
                </p>
                <h3>10. Prices, Payment and Offers</h3>
                <p>
                  The Price of the Items is the one displayed on the
                  Application. Prices include VAT. You confirm that you are
                  using our Service for personal, noncommercial use unless you
                  request a VAT invoice. CDGT may operate dynamic pricing some
                  of the time, which means that prices of Items and delivery
                  cost may change while you are browsing. Prices can also change
                  at any time at our discretion. We reserve the right to charge
                  a Service Fee, which may be subject to change, for the
                  provision of our Services. You will be notified of any
                  applicable Service Fee and taxes prior to purchase on the
                  checkout page on our Application. No changes will affect
                  existing confirmed orders. If there is an obvious pricing
                  mistake we will notify you as soon as we can and you will have
                  the choice of confirming the order at the original price or
                  cancelling the order without charge and with a full refund of
                  any money already paid. Where CDGT makes a delivery, we may
                  also charge you a delivery fee. This will be notified to you
                  during the order process before you complete your order.
                </p>
                <p>
                  The total price of your order will be set out on the checkout
                  page on our Application, including the prices of Items and
                  Delivery and applicable Service Fees and taxes.
                </p>
                <p>
                  Payment for all Items and deliveries can be made on our
                  Application by credit or debit card, or other payment method
                  made available by CDGT. Once your order has been confirmed
                  your credit or debit card will be authorized and the total
                  amount marked for payment. Payment is made directly to CDGT.
                  Payment may also be made by using vouchers or account credit.
                  Use of these is subject to CDGT&apos; Voucher Terms.
                </p>
                <p>
                  Where cash payment is possible, this will be made clear on our
                  Application before you place your order.
                </p>
                <p>
                  CDGT sometimes make special offers available through
                  Application. These offers are at the discretion of each Store.
                  Unless the offer terms state a fixed or minimum period for
                  which an offer will be available, it can be withdrawn at any
                  time, unless you have already placed an order based on the
                  offer, and we have sent the Confirmation Notice.
                </p>
                <h3>11. Tips</h3>
                <p>
                  After you receive your order, you might have the option to pay
                  a tip to your driver or picker. CDGT will collect payment on
                  behalf of the rider, as their limited payment collection
                  agent, and payment of the tips shall be considered a direct
                  payment from you to the rider. We&apos;ll share your first
                  name with your rider when we notify them of the tip. Your
                  rider will receive 100% of any payment you choose to make. As
                  this payment is made after you receive your order, this
                  payment is non-refundable and does not form part of your
                  order. Depending on the payment method used for your original
                  order, your tip may show up on your bank/credit card statement
                  as a separate payment.
                </p>
                <h3>
                  12. Our Responsibility for Loss or Damage That You Suffer
                </h3>
                <p>
                  We are responsible to you for any loss or damage that you
                  suffer that is a foreseeable result of our breaking these
                  Terms or of failing to use reasonable care and skill in
                  relation to your use of our Service. We are not responsible
                  for any loss or damage that is not foreseeable. Loss or damage
                  is &quot;foreseeable&quot; if it is either obvious that it
                  will happen, or if you told us that it might happen, for
                  example if you tell us about particular circumstances that
                  might increase the loss or damage arising from our breach of
                  these Terms before you place an order.
                </p>
                <p>
                  We do not exclude or limit our responsibility to you for loss
                  or damage where it would be unlawful to do so. This includes
                  any responsibility for death or personal injury caused by our
                  failure, or our employees&apos;, agents&apos; or
                  subcontractors&apos; failure, to use reasonable care and
                  skill; for fraud or fraudulent misrepresentation; for breach
                  of your legal rights in relation to the Items, as summarize at
                  part 7 above; or for defective Items under the Federal Law No.
                  15 of 2020 on Consumer Protection Law in the UAE and its
                  implementing regulations. Subject to the previous paragraph,
                  we are not responsible for any loss or damage that you suffer
                  as a result of your own breach of these Terms, or as a result
                  of any IT hardware or software failure other than a failure in
                  our Applications.
                </p>
                <h3>13. Data Protection</h3>
                <p>
                  We process your personal data in accordance with our Privacy
                  Policy which can be found here.
                </p>
                <h3>14. Intellectual Property Rights</h3>
                <p>
                  The Application and its related software are the intellectual
                  property of and are owned by CDGT. You agree that the software
                  and the authorship, systems, ideas, methods of operation,
                  documentation and other information contained in the software,
                  are proprietary intellectual property and/or the valuable
                  trade secrets of CDGT and that CDGT is protected by the UAE
                  Copyright Law.
                </p>
                <p>
                  You acknowledge that these Terms do not grant you any
                  intellectual property rights whatsoever in the Application and
                  its related software and all rights are reserved by CDGT.
                </p>
                <h3>15. Other Terms</h3>
                <p>
                  If either we or you have any right to enforce these Terms
                  against the other, that right will not be lost even if the
                  person who has the right delays enforcing it, or waives their
                  right to enforce it in any instance. If a court or other
                  authority decides that any part of these Terms is illegal or
                  ineffective, the rest of the terms will be unaffected and will
                  remain in force.
                </p>
                <p>
                  We may change these Terms from time to time. If we make any
                  changes which affect your rights in relation to our Service,
                  we will notify you. Changes to the Terms will not affect any
                  orders you have placed where we have sent the Confirmation
                  Notice. These Terms are governed by UAE Law and you can bring
                  legal proceedings in relation to our Service in the Dubai
                  courts in the UAE.
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalsCD;
