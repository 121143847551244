import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import { IMAGES } from '~/constants/images';
import { orderStore } from '~/stores/OrderStore';

import MobileHeaderAddress from '../Main/MobileHeader/MobileHeaderAddress';
import MobileHeaderEta from '../Main/MobileHeader/MobileHeaderEta';
import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileLogo from '../Main/MobileHeader/MobileLogo';

import styles from './NotFound.module.scss';

const NotFound = ({ embedded }: { embedded?: boolean }) => {
  const { t } = useTranslation();

  const {
    message: expressDeliveryMessage,
    args: expressDeliveryMessageArgs,
    showFlashIcon,
  } = orderStore.getDeliveryLabel({ minLabel: t('min') });

  const getPageHeader = () => {
    if (embedded) {
      return null;
    }

    if (DEFAULT_COMPANIES.includes(company.name)) {
      return (
        <MobileHeaderLayout
          className={styles.mobileHeaderLayoutClassName}
          showCart={true}
          isSticky={true}
          showSearch={true}
          content={
            <>
              <MobileHeaderAddress />
              {company.showETAInfoMobileHeader && (
                <MobileHeaderEta
                  {...{
                    expressDeliveryMessage,
                    expressDeliveryMessageArgs,
                    showFlashIcon,
                  }}
                />
              )}
            </>
          }
        />
      );
    } else {
      return (
        <>
          <MobileLogo />
          <MobileHeaderLayout showSearch />
        </>
      );
    }
  };

  return (
    <div className={styles.container} data-company={company.name}>
      {getPageHeader()}
      <div className={styles.fullWidthContainer} data-company={company.name}>
        <div
          className={classes([
            'content-layout',
            'not-found-content-layout',
            styles.root,
          ])}
          data-company={company.name}
        >
          <p className={styles.disclaimer}>{t('phrases:404WereHoping')}</p>
          <img src={IMAGES[404].notFound} className={styles.image} />
          <p className={styles.mainContent}>
            <Trans
              i18nKey="phrases:404ThisPage"
              components={{ a: <Link to="/" className={styles.inlineLink} /> }}
            />
          </p>
          <Link
            to="/"
            className={classes(['button _primary _big', styles.button])}
          >
            {t('goToHomepage')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default observer(NotFound);
