import { CLOUDFLARE_IMAGE_RESIZE_ENABLED } from '~/api/constants';

import { CompanyConfig } from '../interface';

// TODO: need update!!!
export const JiffyConfig: CompanyConfig = {
  id: '1eb53a13-5f9e-4deb-92d7-090a4b53fd21',
  lang: 'en',
  langs: ['en'],
  name: 'Jiffy',
  currency: '£',
  officialName: 'Jiffy',
  email: 'support@jiffygrocery.co.uk',
  phonePlaceholder: '+44',
  defaultPhoneCode: '+44',
  phoneNumber: '+44 00 0 000 0000',
  ageRestricted: 21,
  timeZone: 'Europe/London',
  minimalOrderFeeThreshold: 700,
  address:
    '25 Saint Bride Street, Farringdon Within, Blackfriars, City of London, London, England, GBR',
  canonical: 'https://jiffy.com',
  warehouse: {
    // default: 'NJSB1',
    default: 'DSKEZ',
    minimalOrderFeeWarehouseCodes: [],
  },
  map: {
    // center: { lat: 51.5147135, lng: -0.10608882 },
    center: { lat: 24.4539862, lng: 54.3775843 },
    zoom: 12,
  },
  links: {
    cookie: '',
    deliveryZones: '',
    forRiders: '',
    // whatsapp: 'https://wa.me/971501515042',
    // facebook: 'https://www.facebook.com/cheers.citydrinksuae/',
    // instagram: 'https://www.instagram.com/citydrinksuae/',
    // tiktok: 'https://www.tiktok.com/@citydrinksuae/',
    // youtube: 'https://www.youtube.com/channel/UCzwC746u4WxzAs0fPOMfSMA/',
    affiliateProgram: import.meta.env.REACT_APP_AFFILIATE_PROGRAM_LINK,
    legals: {
      cookiePolicy: {
        label: 'cookiePolicy',
        link: '/legals#cookie-policy',
      },
      paymentAndDeliveryPolicy: {
        label: 'paymentAndDeliveryPolicy',
        link: '/legals#payment-and-delivery',
      },
      privacyPolicy: {
        label: 'privacyPolicy',
        link: '/legals#privacy-policy',
      },
      salesAndReturnPolicy: {
        label: 'salesAndReturnPolicy',
        link: '/legals#sales-and-returns-policies-and-consumer',
      },
      generalTradingPolicy: {
        label: 'generalTradingPolicy',
        link: '/legals#terms-of-service',
      },
    },
  },
  referral: {
    code: 'SWIFFTUCDAVIS',
    discount: '15%',
    minBasket: '15',
    downloadAppLink: 'https://click.jiffy.shop/PZKk/f11c621',
  },
  points: {
    value: '1=1c.',
    pointsPerUnit: 15,
    icon: 'coins',
  },
  appStoreUrl: {
    ios: '',
    android: '',
  },
  apis: {},
  countryCode: 'GB',
  license: {
    name: 'Cloud Retail LTD',
    numberPrinary: 'License Number 99999',
  },
  cloudflareImages: {
    enabled: CLOUDFLARE_IMAGE_RESIZE_ENABLED,
    domain: import.meta.env.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
  },
};
