import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CartCalculationOrderResponse } from '~/api/Catalog';
import { company } from '~/company/Company';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import FreeDeliveryProgressBar from '~/components/FreeDeliveryProgressBar/FreeDeliveryProgressBar';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useDateLang } from '~/hooks/useDateLang';
import { useModal } from '~/hooks/useModal';
import { getSelectedDay } from '~/pages/Checkout/utils';
import { catalogStore } from '~/stores/CatalogStore';
import Delivery from '~/stores/CheckoutStore/Delivery';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { isProductBundle } from '~/types/Product';
import { formatPriceWithCurrency } from '~/utils/formaters';

import styles from './DeliveryMobile.module.scss';

interface DeliveryMobileProps {
  handleSelectDateModal: () => void;
  delivery: Delivery;
  slotNumber?: number;
  order: CartCalculationOrderResponse;
}

const DeliveryMobile = ({
  handleSelectDateModal,
  delivery,
  slotNumber,
  order,
}: DeliveryMobileProps) => {
  const { t } = useTranslation();
  const { handleClickProduct } = useCategoryAnalytics();
  const { locale } = useDateLang();

  const selectedDay = !delivery.slot
    ? t('phrases:deliveryInMin', {
        time: orderStore.etaCalculation?.duration.range,
      })
    : getSelectedDay(delivery.slot, locale);

  const deliveryPrice = formatPriceWithCurrency(
    mainStore.convertPenceToPounds(order.delivery_info.paid_price),
  );

  const isDeliveryFree = order.is_delivery_free;

  const freeDeliveryThreshold = order.delivery_info.threshold;

  const leftUntilFreeDelivery = mainStore.convertPenceToPounds(
    freeDeliveryThreshold - order.paid_without_delivery,
  );
  const { openModal } = useModal();
  const openDeliveryModal = () => {
    openModal(ModalType.DeliveryInfo, 'checkout');
  };
  const deliveryTooltip = company.isDeliveryInfoModalEnabled && (
    <div onClick={openDeliveryModal} className={styles.deliveryTooltip}>
      <Icon type="info" size={16} />
    </div>
  );
  return (
    <li className={classes(['slot-list__slot', styles.container])}>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          {!slotNumber ? (
            <p className="slot-list__slot-type">
              <Icon type="express-fill" size={16} /> {t('expressDelivery')}
              {deliveryTooltip}
            </p>
          ) : (
            <p className="slot-list__slot-type">
              {t('slot', { num: slotNumber })}
              {deliveryTooltip}
            </p>
          )}
          {isDeliveryFree ? (
            <p className={styles.deliveryFree}>{t('deliveryFree')}</p>
          ) : (
            <p className="slot-list__slot-fee">
              {t('deliveryFee')}: {deliveryPrice}
            </p>
          )}
        </div>
        <div className="slot-list__slot-select-container">
          <div className="slot-list__slot-select-item">
            <button
              className="slot-list__slot-select-item-control"
              onClick={handleSelectDateModal}
            >
              <p className="slot-list__slot-select-item-control-text">
                {selectedDay && selectedDay}
              </p>
              <Icon type="chevron" size={24} className={styles.calendarIcon} />
            </button>
          </div>
        </div>
        <ul className="slot-list__slot-images">
          <Swiper spaceBetween={4} slidesPerView="auto" modules={[Navigation]}>
            {catalogStore.selectedCartItems
              .filter(({ sku }) => delivery.itemsIds.includes(sku))
              .map((item) => (
                <SwiperSlide
                  className="banner-carousel__item1"
                  key={item.previewImageThumb}
                >
                  <Link
                    onClick={handleClickProduct(item)}
                    to={`/p/${item.slug}`}
                    state={{
                      ...(isProductBundle(item) ? '' : { offerSku: item.sku }),
                    }}
                  >
                    <div className="slot-list__slot-images-item">
                      <CloudflareResponseImage
                        src={item.previewImageThumb || ''}
                        srcParams={{ width: 80, height: 110 }}
                        srcSetParams={defaultSrcSetParams}
                        alt={item.name}
                        loading="lazy"
                      />
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
        </ul>
        {!isDeliveryFree && (
          <FreeDeliveryProgressBar
            leftUntilFreeDelivery={formatPriceWithCurrency(
              leftUntilFreeDelivery,
            )}
            cssWidth={`${
              (order.paid_without_delivery / freeDeliveryThreshold) * 100
            }%`}
          />
        )}
      </div>
    </li>
  );
};

export default observer(DeliveryMobile);
