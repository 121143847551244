import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { company, DEFAULT_COMPANIES } from '~/company/Company';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { PICKUP_ALCOHOL_COUPON_CODE } from '~/stores/constants';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { ApplyPromocodeCallerSource } from '~/stores/PromotionsStore/interfaces';

import Icon from '../Icon/Icon';

import { PromocodeProps } from './interfaces';
import styles from './Promocode.module.scss';
import Promocode1 from './Variant1/Promocode';

const Promocode = ({
  className,
  placeholder,
  spinnerClassName,
  submitButtonClassName,
}: PromocodeProps) => {
  const { t } = useTranslation();
  const [promocodeVal, setPromocodeVal] = useState(
    catalogStore.promocode.value || '',
  );
  const [warningMsg, setWarningMsg] = useState('');

  const isLoading = catalogStore.promotionStore.applyPromocodeMeta.isLoading;

  const handlePromocodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPromocodeVal(e.currentTarget.value.trim());
  };
  const handleDelPromocode = () => {
    setPromocodeVal('');
    catalogStore.promotionStore.resetPromocode();
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkPromocode(promocodeVal);
  };

  const checkPromocode = (val: string) => {
    if (!val.length) {
      return;
    }

    catalogStore.promotionStore
      .applyPromocode(val, ApplyPromocodeCallerSource.CART)
      .then(() => {
        if (catalogStore.promotionStore.promocode.deliveryMethod) {
          checkoutStore.setDeliveryMethod(
            catalogStore.promotionStore.promocode.deliveryMethod,
          );
        }
      });
  };

  useEffect(() => {
    if (
      !catalogStore.totalCartPrice.amountLeftToDiscount ||
      mainStore.isZero(catalogStore.totalCartPrice.amountLeftToDiscount)
    ) {
      setWarningMsg('');
      return;
    }

    if (
      !catalogStore.isPromoCodeApplied &&
      catalogStore.promocode.coupon?.type
    ) {
      let msg =
        catalogStore.promocode.coupon?.code === PICKUP_ALCOHOL_COUPON_CODE
          ? 'errors:promocodePriceLessNoAlcohol'
          : 'errors:promocodePriceLess';

      if (!company.isExceptionAlcohol) {
        msg = 'errors:promocodePriceLessNoAlcohol';
      }

      setWarningMsg(
        t(msg, {
          amountLeft: mainStore.addCurrencySymbol(
            catalogStore.totalCartPrice.amountLeftToDiscount,
          ),
          discount: catalogStore.formatPromocodeDiscountAmount,
        }),
      );
    } else {
      setWarningMsg('');
    }
    //eslint-disable-next-line
  }, [catalogStore.totalCartPrice]);

  useEffect(() => {
    if (!catalogStore.promocode.value) {
      return;
    }
    checkPromocode(catalogStore.promocode.value);
  }, [JSON.stringify(orderStore.etaCalculation?.cost)]);

  if ([...DEFAULT_COMPANIES].includes(company.name)) {
    return <Promocode1 />;
  }

  const promocodeExists =
    catalogStore.promocode.success !== null &&
    promocodeVal === catalogStore.promocode.value;

  return (
    <form
      className={classes(['promocode__form', styles.root, className])}
      onSubmit={handleSubmit}
    >
      <div
        className={classes([
          'promocode__form-control',
          promocodeVal.length &&
            catalogStore.promocode.success === null &&
            !isLoading &&
            '_filled',
          catalogStore.promocode.errorType === 'error' &&
            !isLoading &&
            '_error',
          (catalogStore.promocode.errorType === 'warning' || warningMsg) &&
            !isLoading &&
            '_warning',
          catalogStore.promocode.success === true &&
            !warningMsg &&
            !isLoading &&
            '_success',
        ])}
      >
        <div className="checkout-summary__promocode-content">
          <div className="input-container">
            <input
              className={classes([isLoading && '_loading', styles.input])}
              placeholder={placeholder || t('phrases:enterPromocode')}
              value={promocodeVal}
              onChange={handlePromocodeChange}
              disabled={isLoading}
            />
            {promocodeVal &&
              !isLoading &&
              company.promocodeInInputResetCheckout && (
                <button
                  className={classes([
                    'button',
                    '_no-padding',
                    '_no-color',
                    'button-icon',
                    isLoading && '_loading',
                    styles.resetButton,
                    styles.buttonIcon,
                  ])}
                  onClick={handleDelPromocode}
                  type="button"
                >
                  <Icon type="burger-close" size={28} />
                </button>
              )}
          </div>
          {company.promocodeInInputResetCheckout && (
            <button
              className={classes([
                'button',
                styles.button,
                submitButtonClassName,
              ])}
              type="submit"
              onClick={promocodeExists ? handleDelPromocode : undefined}
              disabled={isLoading}
            >
              {isLoading ? (
                <span className={classes(['spinner', spinnerClassName])} />
              ) : promocodeExists ? (
                <Icon size={24} type="trash" />
              ) : (
                <Icon type="arrow" size={24} />
              )}
            </button>
          )}
        </div>
        {!isLoading && catalogStore.promocode.message && (
          <p className="promocode__form-control-result">
            {catalogStore.promocode.message}
          </p>
        )}
      </div>
      {warningMsg && !isLoading && (
        <div className="alert _warning mt-16 z-1">{warningMsg}</div>
      )}
    </form>
  );
};

export default observer(Promocode);
