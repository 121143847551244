import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import CategoryBlock from '~/components/CategoryBlocks/CategoryBlock';
import LanguageSelector from '~/components/LanguageSelector/LanguageSelector';
import { useGlobal } from '~/hooks/useGlobal';
import ShopSidebar from '~/pages/Shop/ShopSidebar';
import { shopCategories } from '~/stores/CategoriesStore';
import { orderStore } from '~/stores/OrderStore';

import MobileHeaderAddress from '../Main/MobileHeader/MobileHeaderAddress';
import MobileHeaderEta from '../Main/MobileHeader/MobileHeaderEta';
import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';

import CategorySkeleton from './CategorySkeleton';
import styles from './Shop.module.scss';

const Shop = () => {
  const { isMobile } = useGlobal();
  const { t } = useTranslation();

  const {
    message: expressDeliveryMessage,
    args: expressDeliveryMessageArgs,
    showFlashIcon,
  } = orderStore.getDeliveryLabel({ minLabel: t('min') });

  return (
    <div className={styles.root} data-company={company.name}>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      <MobileHeaderLayout
        className={styles.mobileHeaderLayoutClassName}
        showCart={true}
        isSticky={true}
        content={
          <>
            <MobileHeaderAddress />
            <div className={styles.headerGroup}>
              {company.showETAInfoMobileHeader && (
                <MobileHeaderEta
                  {...{
                    expressDeliveryMessage,
                    expressDeliveryMessageArgs,
                    showFlashIcon,
                  }}
                />
              )}
              <LanguageSelector />
            </div>
          </>
        }
      />
      <div className={classes([styles.contentLayout, 'content-layout'])}>
        {!isMobile && <ShopSidebar />}
        <div className={styles.blocks}>
          {shopCategories.list.length > 0
            ? shopCategories.list.map((category) => {
                return <CategoryBlock category={category} key={category.id} />;
              })
            : [1, 2, 3, 4, 5, 6].map((i) => <CategorySkeleton key={i} />)}
        </div>
      </div>
    </div>
  );
};

export default observer(Shop);
