import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { company } from '~/company/Company';
import { SwiperWithSlidesPerView } from '~/components/CategoryBlocks/BannerProductBlock/interfaces';
import SwiperIcon from '~/components/Swiper/SwiperIcon';
import { userStore } from '~/stores/UserStore';

import CloudflareResponseImage from '../CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '../CloudflareResponsiveImage/constants';

import FullscreenWrapper from './FullscreenWrapper';
import { ProductImageSliderProps, SliderLayout } from './interfaces';
import styles from './ProductImageSlider.module.scss';
import SliderThumbs from './SliderThumbs';

import 'swiper/css';
import 'swiper/css/thumbs';

const ProductImageSlider = (props: ProductImageSliderProps) => {
  const {
    productTitle,
    images = [],
    className,
    badgesContent = false,
    layout = SliderLayout.DESKTOP,
  } = props;
  const [thumbSwiperRef, setThumbSwiperRef] =
    useState<SwiperWithSlidesPerView | null>(null);
  const [mainSwiperRef, setMainSwiperRef] =
    useState<SwiperWithSlidesPerView | null>(null);
  const [isImageFullscreen, setIsImageFullscreen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const openFullscreenSlider = (swiper?: SwiperWithSlidesPerView): void => {
    if (isImageFullscreen) {
      return;
    }

    setIsImageFullscreen(true);
    setThumbSwiperRef(null);
    setActiveIndex(swiper?.clickedIndex ?? 0);
  };

  const closeFullscreenSlider = () => {
    if (!isImageFullscreen) {
      return;
    }

    setIsImageFullscreen(false);
    setThumbSwiperRef(null);
  };

  const getSwiperContent = (showMainControls = false) => {
    const content = images.map((url) => (
      <SwiperSlide key={url}>
        <CloudflareResponseImage
          src={url}
          srcParams={{ width: 468, height: 620 }}
          srcSetParams={defaultSrcSetParams}
          alt=""
          className={classes([styles.mainImage, 'slider-image'])}
          loading="lazy"
        />
      </SwiperSlide>
    ));

    if (layout === SliderLayout.MOBILE) {
      return (
        <Swiper
          modules={[Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          className={classes([
            styles.mainSlider,
            !isImageFullscreen && styles.mainSliderMobile,
            isImageFullscreen && styles.mainSliderMobileFullScreen,
          ])}
          pagination={true}
          onClick={openFullscreenSlider}
          onSwiper={(swiper) => {
            swiper.slideTo(activeIndex);
          }}
          dir={userStore.dir}
          key={userStore.dir}
        >
          {content}
        </Swiper>
      );
    }

    return (
      <>
        <div
          className={classes([
            styles.mainSlider,
            isImageFullscreen && styles.fullScreenMode,
          ])}
        >
          {showMainControls && (
            <SwiperIcon
              position="left"
              variant="white"
              className={styles.leftControl}
              onClick={() => {
                if (!mainSwiperRef) {
                  return;
                }

                if (!mainSwiperRef.isBeginning) {
                  mainSwiperRef.slidePrev();
                }
              }}
            />
          )}
          <Swiper
            modules={[Thumbs]}
            spaceBetween={0}
            slidesPerView={1}
            thumbs={{
              swiper:
                thumbSwiperRef && !thumbSwiperRef.destroyed
                  ? thumbSwiperRef
                  : null,
            }}
            onClick={openFullscreenSlider}
            onSwiper={(swiper) => {
              swiper.slideTo(activeIndex);
              setMainSwiperRef(swiper);
            }}
            dir={userStore.dir}
            key={userStore.dir}
          >
            {content}
          </Swiper>
          {showMainControls && (
            <SwiperIcon
              position="right"
              variant="white"
              className={styles.rightControl}
              onClick={() => {
                if (!mainSwiperRef) {
                  return;
                }

                if (!mainSwiperRef.isEnd) {
                  mainSwiperRef.slideNext();
                }
              }}
            />
          )}
        </div>
        <SliderThumbs
          swiper={
            thumbSwiperRef && !thumbSwiperRef.destroyed ? thumbSwiperRef : null
          }
          isImageFullscreen={isImageFullscreen}
          onSwiper={setThumbSwiperRef}
        >
          {images.map((url) => (
            <SwiperSlide key={url}>
              <CloudflareResponseImage
                src={url}
                srcParams={{ width: 75 }}
                srcSetParams={defaultSrcSetParams}
                alt={productTitle}
                className="slider-image"
                loading="lazy"
              />
            </SwiperSlide>
          ))}
        </SliderThumbs>
      </>
    );
  };

  const getFullscreenContent = () => {
    const content = (
      <FullscreenWrapper
        wrapperClassName={classes([images.length === 1 && styles.flex])}
        productTitle={productTitle}
        onClose={closeFullscreenSlider}
      >
        {images.length === 1 && (
          <CloudflareResponseImage
            src={images[0]}
            srcParams={{ width: 640 }}
            srcSetParams={defaultSrcSetParams}
            alt={productTitle}
            className={styles.fullScreenSingleImage}
            loading="lazy"
          />
        )}
        {images.length > 1 && getSwiperContent(true)}
      </FullscreenWrapper>
    );

    if (typeof document === 'undefined') {
      return content;
    }

    return createPortal(content, document.body);
  };

  return (
    <>
      <div
        className={classes([styles.sliderWr, className])}
        data-company={company.name}
      >
        {badgesContent && (
          <div
            className={classes([
              styles.badgeContainer,
              images.length > 1 && styles.offset,
            ])}
          >
            {badgesContent}
          </div>
        )}
        {!isImageFullscreen && images.length === 1 && (
          <CloudflareResponseImage
            src={images[0]}
            srcParams={{ width: 468, height: 620 }}
            srcSetParams={defaultSrcSetParams}
            alt={productTitle}
            className={styles.singleImage}
            onClick={() => openFullscreenSlider()}
            loading="lazy"
          />
        )}
        {!isImageFullscreen && images.length > 1 && getSwiperContent()}
        {isImageFullscreen && <div className={styles.singleImage} />}
      </div>

      {isImageFullscreen && getFullscreenContent()}
    </>
  );
};

export default observer(ProductImageSlider);
