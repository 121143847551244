import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import { debounce, isEqual, omit } from 'lodash-es';
import mitt, { Emitter } from 'mitt';
import {
  IObservableArray,
  makeAutoObservable,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';

import {
  CartBundlesCalculationItemRequest,
  CartCalculationItemRequest,
  CartCalculationItemResponse,
  CartCalculationOrderResponse,
  CartCalculationRequest,
  CatalogRequests,
  ICatalogPrices,
  ProductCommodityGroupAPI,
  ProductPropertiesKeyAPI,
  RecommendationSource,
  RecommendProductsSource,
  SortingFieldName,
  SortingOrder,
} from '~/api/Catalog';
import { FavoritesRequest } from '~/api/Favorites';
import { GiftItem } from '~/api/Order';
import { ApiErrorResponse } from '~/api/Requests';
import { company } from '~/company/Company';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { Category, Offer, Product } from '~/stores/CategoriesStore';
import { CalculateStockErrorInstance } from '~/stores/shared/CartStockChanges/interfaces';
import { MetaStore } from '~/stores/shared/MetaStore';
import { calcProductOfferFields, isProductBundle } from '~/types/Product';
import { ProductPropertiesFilter } from '~/types/Product/interface';

import { checkoutStore } from '../CheckoutStore/CheckoutStore';
import { PURCHASED_ITEMS_COUNT } from '../constants';
import { storage as localStorage } from '../LocalStorage';
import { mainStore } from '../MainStore';
import { orderStore } from '../OrderStore';
import { PromotionStore } from '../PromotionsStore';
import { CartStockChanges } from '../shared/CartStockChanges';
import { userStore } from '../UserStore';

import {
  CartItem,
  Promocode,
  TotalCartPrice,
  FirebaseCartItem,
  CalculationProcess,
  ProductListValue,
  ProductPropertyFilterCode,
  ProductRangeFilter,
  ProductFilters,
  CatalogStorePartial,
  PriceMinMax,
  Pagination,
  ShutterColumn,
  ProductRecommendationResponse,
} from './interfaces';

export const PAGE_SIZE = 48;

export class CatalogStore implements CatalogStorePartial {
  calculationProcess: CalculationProcess = {
    isLoading: false,
    isError: false,
    requestId: 0,
  };
  cart: CartItem[] = [];
  purchasedItems = new Map<number, Product>();
  purchasedItemsPage = 0;
  // FIXME: save only product ID instead of whole object
  favorites: Record<string, Product> = {};
  recommendItems: Product[] = [];

  // FIXME: do we need this?
  changePriceProductList: CartItem[] = [];
  changeCountProductList: CartItem[] = [];

  addToCartCatch: {
    cartItem: Offer | Product;
    count: number;
    source: string;
  } | null = null;

  addToGiftCatch: { gift: GiftItem; callback: () => void } | null = null;
  productsOutOfStockExpandedList: string[] = [];
  totalCartPrice: TotalCartPrice = {
    amountLeftToDiscount: '',
    base: '',
    discount: '',
    paid: '',
    paidWithDiscount: '',
    promocodeDiscount: '',
    paidBonusesPence: 0,
    paidWithoutDiscount: '',
    taxAmount: 0,
    taxPercent: 0,
  };
  filters: ProductPropertiesFilter[] = [];
  currentFilters: ProductFilters = {
    properties: {},
  };
  currentSorting: [SortingFieldName, SortingOrder] = ['salesQuantity', 'DESC'];
  currentBadges: string[] = [];
  selectedCategoryId?: string;
  categoryShutter: [boolean, number | string] = [false, 0];
  categoryShutterData: Record<number | string, ShutterColumn[]> = {};
  pricesminmax: PriceMinMax = {};
  feedbackProduct: Product | null = null;
  feedbackProductId: string | null = null;
  page: Pagination = {
    current: 1,
    size: PAGE_SIZE,
    total: 0,
  };
  productRecommendations: ProductRecommendationResponse = {};

  deliveriesOrders: CartCalculationOrderResponse[] = [];

  readonly cartStockChangesStore: CartStockChanges;
  readonly promotionStore: PromotionStore;
  readonly productRecommendationsMeta: MetaStore = new MetaStore();

  readonly applyFiltersEventEmitter: Emitter<{ submit: undefined }> = mitt<{
    submit: undefined;
  }>();

  constructor() {
    this.promotionStore = new PromotionStore(this);
    this.cartStockChangesStore = new CartStockChanges(this);

    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CatalogStore',
      properties: [
        'cart',
        'favorites',
        'currentFilters',
        'currentSorting',
        'currentBadges',
      ],
      storage: localStorage,
    }).catch((error) => error && console.error(error));
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  get isRestrictedItemInCart(): boolean {
    const commodityGroupList: ProductCommodityGroupAPI[] = [
      ProductCommodityGroupAPI.TOBACCO,
      ProductCommodityGroupAPI.ALCOHOL,
    ];

    for (let i = 0; i < this.cart.length; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (commodityGroupList.includes(this.cart[i].commodityGroup)) {
        return true;
      }
    }

    return false;
  }

  get isAdultItemInCart(): boolean {
    for (let i = 0; i < this.cart.length; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (this.cart[i].properties.age_restriction) {
        return true;
      }
    }
    return false;
  }

  get isCharityPromocode(): boolean {
    return false;
  }

  get totalCartCount(): number {
    let totalCount = 0;
    for (let i = 0; i < this.cart.length; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      totalCount += this.cart[i].count;
    }
    return totalCount;
  }

  get leftUntilFreeDelivery(): [string, string] {
    const zero: [string, string] = ['0', '100%'];
    if (userStore.personalData.freeDeliveryDaysLeft > 0) {
      return zero;
    }

    return this.leftUntilFreeFee(
      mainStore.convertPoundsToPence(this.totalCartPrice.paidWithDiscount),
      orderStore.fee.threshold || 0,
    );
  }

  get leftUntilFreeMinimalOrderFee(): [string, string] {
    const zero: [string, string] = ['0', '100%'];
    if (userStore.isStaff) {
      return zero;
    }

    if (orderStore.isFirstOrder) {
      return zero;
    }

    const paid = mainStore.convertPoundsToPence(
      this.totalCartPrice.paidWithDiscount,
    );

    return this.leftUntilFreeFee(paid, company.config.minimalOrderFeeThreshold);
  }

  get finalPrice() {
    return this.totalCartPrice.paid || '0';
  }

  get favoritesList(): Product[] {
    const favoritesKeys = Object.keys(this.favorites);
    if (!favoritesKeys.length) {
      return [];
    }
    return Object.values(this.favorites);
  }

  get purchasedItemsList(): Product[] {
    if (!this.purchasedItems.size) {
      return [];
    }
    return Array.from(this.purchasedItems, ([, value]) => value);
  }

  get cartForFirebase(): FirebaseCartItem[] {
    return this.cart.map((item, i): FirebaseCartItem => {
      return this.convertToFirebase(item, i);
    });
  }

  get isPromoCodeApplied() {
    return (
      this.promocode.success &&
      parseFloat(this.totalCartPrice.promocodeDiscount) > 0 &&
      mainStore.isZero(this.totalCartPrice.amountLeftToDiscount)
    );
  }

  get isFixedPromoCode() {
    return this.promocode.coupon?.type === 'FIXED';
  }

  get isStoreInMinimalOrderList() {
    return company.config.warehouse.minimalOrderFeeWarehouseCodes.includes(
      orderStore.etaWarehouseCode,
    );
  }

  get isMinimalOrderFeePassed() {
    if (userStore.isStaff) {
      return true;
    }

    if (orderStore.isFirstOrder) {
      return true;
    }

    if (!this.isStoreInMinimalOrderList) {
      return true;
    }

    if (this.cart.length === 0) {
      return false;
    }

    return mainStore.isZero(this.leftUntilFreeMinimalOrderFee[0]);
  }

  get formatPromocodeDiscountAmount() {
    const amount = this.promocode.coupon?.value || 0;

    if (mainStore.isZero(amount)) {
      return '0';
    }

    if (this.promocode.coupon?.type === 'PERCENTAGE') {
      return `${amount}%`;
    }

    return mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(amount));
  }

  get isSelectedAll() {
    return this.cart.every(({ selected }) => selected);
  }

  get selectedItemsCount() {
    return this.cart.filter(({ selected }) => selected).length;
  }

  get selectedCartItems() {
    return this.cart.filter(({ selected }) => selected);
  }

  get selectedItemsTotalCount() {
    return this.selectedCartItems.reduce<number>(
      (total, { count }) => total + count,
      0,
    );
  }

  get promocode(): Promocode {
    return this.promotionStore.promocode;
  }

  // Setters
  setPrices(data?: ICatalogPrices) {
    this.pricesminmax = {
      priceMin: data?.priceMin,
      priceMax: data?.priceMax,
      priceMinPounds: Math.floor((data?.priceMin || 0) / 100),
      priceMaxPounds: Math.ceil((data?.priceMax || 0) / 100),
    };
  }

  setCartItemCountByProduct(
    cartItem: Offer | Product,
    count: number,
    action: 'add' | 'remove',
    source: string,
    lvl3category?: number,
  ) {
    const isBundle = isProductBundle(cartItem);

    analyticsEventsEmitter.emit(EventsName.COMMON_ANALYTICS_CART_CHANGED);
    if (count < 0) {
      count = 0;
    }

    if (
      cartItem.properties.age_restriction &&
      !userStore.personalData.isAdult &&
      action === 'add' &&
      count
    ) {
      this.addToCartCatch = { cartItem, count, source };
      mainStore.setIsAgeRestrictionPopover(true);
      return;
    }

    if (
      action === 'add' &&
      !isBundle &&
      cartItem.promoRequiredQuantity > count
    ) {
      mainStore.popAlert();
      mainStore.pushAlert(
        'success',
        i18n.t('phrases:addMoreToDiscount', {
          count: cartItem.promoRequiredQuantity - count,
        }),
      );
    }

    if (
      action === 'add' &&
      !isBundle &&
      cartItem.promoRequiredQuantity === count
    ) {
      mainStore.popAlert();
      mainStore.pushAlert('success', i18n.t('phrases:discountReached'));
    }
    const itemIndex = this.cart.findIndex(({ id, sku }) =>
      isBundle
        ? (id && id === cartItem.id) || (sku && sku === cartItem.sku)
        : sku && sku === cartItem.sku,
    );
    const isNewProduct = itemIndex === -1;
    if (!isNewProduct) {
      if (!count) {
        this.cart.splice(itemIndex, 1);

        if (!this.cart.length) {
          this.promotionStore.resetPromocode();
        }
      } else {
        const item = this.cart[itemIndex];

        if (!item) {
          return;
        }

        this.cart[itemIndex] = { ...item, count };
        (this.cart as IObservableArray<CartItem>).replace(this.cart);
      }
    }

    if (count && isNewProduct) {
      this.cart.push({
        ...cartItem,
        count,
        selected: true,
        sku: isBundle ? `${cartItem.id}` : cartItem.sku,
      });
      mainStore.sendToRN('sendTags', {
        cart_product_name: cartItem.name,
      });
      mainStore.sendToRN('sendTags', {
        cart_product_price: cartItem.discountPrice
          ? cartItem.discountPriceFormatted
          : cartItem.priceFormatted,
      });
      mainStore.sendToRN('sendTags', {
        cart_product_image: cartItem.previewImageThumb,
      });
    }

    mainStore.sendToRN('sendTags', {
      cart_update_time: Math.floor(Date.now() / 1000),
    });

    this.cartStockChangesStore.closeNotifierModal();

    const analyticEventName =
      action === 'add'
        ? 'Purchase: product added to cart'
        : 'Purchase: product removed from cart';
    const firebaseEventName =
      action === 'add' ? 'add_to_cart' : 'remove_from_cart';
    const productsAmount = this.cart.reduce((sum, item) => sum + item.count, 0);

    mainStore.sendAnalytics(['BI', 'analytics', 'yaMetrika'], {
      name: analyticEventName,
      params: {
        product_id: cartItem.id,
        category_id: lvl3category || cartItem.categoryId,
        lvl1_category_id: undefined,
        lvl2_category_id: cartItem.parentCategoryId,
        source,
        quantity: count,
        cart_id: undefined,
        products_amount: productsAmount,
        items_amount: this.cart.length,
        price: this.totalCartPrice.base,
        final_price: this.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      },
    });

    mainStore.sendToRN('firebaseAnalytics', {
      name: firebaseEventName,
      params: {
        currency: orderStore.currency.toUpperCase(),
        items: [
          {
            item_id: isBundle ? cartItem.id : (cartItem as Offer).sku,
            item_name: cartItem.name,
            quantity: 1,
            promotion_id: '',
            promotion_name: '',
            affiliation: '',
            coupon: this.promocode.value,
            creative_name: '',
            creative_slot: '',
            discount: cartItem.discountPrice
              ? mainStore.toFloat(
                  mainStore.convertPenceToPounds(
                    cartItem.price - cartItem.discountPrice,
                  ),
                )
              : 0,
            index: 0,
            item_brand: '',
            item_category: cartItem.categoryName,
            item_list_name: cartItem.categoryName,
            item_list_id: cartItem.categoryId,
            item_variant: '',
            location_id: '',
            tax: 0,
            price: mainStore.toFloat(cartItem.priceFormatted),
            currency: orderStore.currency.toUpperCase(),
          },
        ],
        value: mainStore.toFloat(cartItem.priceFormatted),
      },
    });
  }

  emptyCart() {
    this.cart = [];
    this.promotionStore.resetPromocode();
    mainStore.sendToRN('removeTag', 'cart_update_time');
    mainStore.sendToRN('removeTag', 'cart_product_name');
    mainStore.sendToRN('removeTag', 'cart_product_price');
    mainStore.sendToRN('removeTag', 'cart_product_image');
  }
  removeCartItemByOfferSKU(skuToRemove: string) {
    this.cart = this.cart.filter(({ sku }) => sku !== skuToRemove);
  }
  removeCartItemByOfferId(offerId: number) {
    this.cart = this.cart.filter(({ id }) => id !== offerId);
  }

  emptySelectedItemsFromCart() {
    if (this.selectedItemsCount === 0) {
      return;
    }
    if (this.cart.length === this.selectedItemsCount) {
      this.cart = [];
    } else {
      this.cart = this.cart.reduce((acc: CartItem[], item) => {
        if (!item.selected) {
          acc.push({ ...item, selected: true });
        }
        return acc;
      }, []);
    }
  }

  setFilters(val: ProductPropertiesFilter[]) {
    this.filters = val;
  }

  toggleFavorite(product: Product, source: string) {
    if (this.favorites[product.id]) {
      if (userStore.isAuthorized) {
        this.removeFavorite(product.id);
      }
      delete this.favorites[product.id];
      return;
    }
    if (userStore.isAuthorized) {
      this.addFavorite([product.id]);
    }
    this.favorites[product.id] = product;
    mainStore.sendToRN('analytics', {
      name: 'General: product added to favorites',
      params: {
        product_id: product.id,
        category_id: product.categoryId,
        lvl1_category_id: undefined,
        lvl2_category_id: product.parentCategoryId,
        source, // (search / category / homepage / product_main / product_reco / cart / calculate)
        price: product.priceFormatted,
        final_price: product.discountPrice
          ? product.discountPriceFormatted
          : product.priceFormatted,
      },
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: favourites number': Object.keys(this.favorites).length,
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'add_to_wishlist',
      params: {
        currency: orderStore.currency.toUpperCase(),
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            quantity: 1,
            promotion_id: '',
            promotion_name: '',
            affiliation: '',
            coupon: this.promocode.value,
            creative_name: '',
            creative_slot: '',
            discount: product.discountPrice
              ? mainStore.toFloat(
                  mainStore.convertPenceToPounds(
                    product.discountPrice - product.price,
                  ),
                )
              : 0,
            index: 0,
            item_brand: '',
            item_category: product.categoryName,
            item_list_name: product.categoryName,
            item_list_id: product.categoryId,
            item_variant: '',
            location_id: '',
            tax: 0,
            price: mainStore.toFloat(product.priceFormatted),
            currency: orderStore.currency.toUpperCase(),
          },
        ],
        value: mainStore.toFloat(product.priceFormatted),
      },
    });
  }

  addProductsOutOfStockExpandedList(id: string) {
    this.productsOutOfStockExpandedList.push(id);
  }

  resetProductsOutOfStockExpandedList() {
    this.productsOutOfStockExpandedList = [];
  }

  handleSelectedCartItem = (id: number) => {
    const currentItemIndex = this.cart.map((i) => i.id).indexOf(id);
    const currentItem = this.cart[currentItemIndex];

    if (!currentItem) {
      return;
    }

    this.cart[currentItemIndex] = {
      ...currentItem,
      selected: !currentItem.selected,
    };
  };

  handleSelectAllCartItems = () => {
    this.cart = this.cart.map((item) => ({
      ...item,
      selected: !this.isSelectedAll,
    }));
  };

  setCalculationProcess(val: CalculationProcess) {
    this.calculationProcess = val;
  }

  setRecommendItems(products: Product[]) {
    this.recommendItems = products;
  }

  setFavorites(obj: Record<string, Product>) {
    this.favorites = obj;
  }

  setPagination(val: Pagination) {
    this.page = val;
  }

  // Actions
  getCartItemCountById(item: Offer | Product): number {
    if (!item) {
      return 0;
    }

    const id = item.id;
    const sku = item.sku;
    const isBundle = isProductBundle(item);
    const cartItem = this.cart.find((item) => {
      const typeIsEqual = isBundle
        ? (item as Product).productType === 'bundle'
        : (item as Product).productType !== 'bundle';
      const idIsEqual = isBundle
        ? (item.id && item.id === id) || (item.sku && item.sku === sku)
        : item.sku && item.sku === sku;

      return idIsEqual && typeIsEqual;
    });

    return cartItem ? cartItem.count : 0;
  }

  // FIXME: remove side effects
  /**
   * @return
   * true - cart items changed
   *
   * false - cart items not changed
   * */
  updateCartItems(
    skuList: string[],
    cartObj: Record<string, CartItem>,
    items: CartCalculationItemResponse[],
  ): boolean {
    this.changePriceProductList = [];
    this.changeCountProductList = [];

    items.forEach((item) => {
      if (item.is_gift) {
        return;
      }
      const cartItem = cartObj[item.sku];
      if (!cartItem) {
        return;
      }

      const originCartItem = toJS(cartItem);
      let isPriceChanged = false;

      skuList = skuList.filter((sku) => sku !== item.sku);

      if (item.sellable !== undefined) {
        cartItem.sellable = item.sellable;
      }

      const paidPrice =
        item.discount_price !== undefined
          ? item.discount_price
          : item.paid_price;

      if (item.discount_amount && cartItem.discountPrice !== paidPrice) {
        cartItem.discountPrice = paidPrice;
        if (paidPrice > cartItem.discountPrice) {
          isPriceChanged = true;
        }
      }

      if (!item.discount_amount && cartItem.discountPrice) {
        cartItem.discountPrice = 0;
        isPriceChanged = true;
      }

      if (cartItem.price !== item.base_price) {
        cartItem.price = item.base_price;
        if (item.base_price > cartItem.price) {
          isPriceChanged = true;
        }
      }

      if (isPriceChanged) {
        this.changePriceProductList.push(originCartItem);
      }
    });

    skuList.forEach((sku) => {
      const cartItem = cartObj[sku];
      if (!cartItem) {
        return;
      }
      cartItem.count = 0;
    });

    const cartItems = Object.values(cartObj);
    if (this.cart.length === cartItems.length) {
      this.cart = cartItems.map(calcProductOfferFields) as CartItem[];
    } else {
      this.cart = this.cart.map((cartItem) => {
        const updatedItem = cartItems.find(({ id }) => id === cartItem.id);
        return updatedItem ?? cartItem;
      });
    }

    return !!(
      this.changePriceProductList.length || this.changeCountProductList.length
    );
  }

  public getProductFilters(
    filters?: ProductFilters,
    sorting?: [SortingFieldName, SortingOrder],
    page?: number,
  ) {
    const excluded: ProductPropertiesKeyAPI[] = ['type'];
    const properties = Object.entries(filters?.properties ?? {}) as [
      ProductPropertiesKeyAPI,
      any,
    ][];

    return {
      page: {
        size: this.page.size,
        current: page ?? this.page.current,
      },
      price: this.convertPriceFilterToPences(filters?.price),
      discountPrice: this.convertPriceFilterToPences(filters?.discountPrice),
      properties: properties.length
        ? {
            ...Object.fromEntries(
              properties.filter((p) => !excluded.includes(p[0])),
            ),
          }
        : {},
      sort: sorting ? [{ field: sorting[0], order: sorting[1] }] : undefined,
      ...(this.currentFilters.properties.type
        ? {
            subCategoryId: this.currentFilters.properties.type?.map(
              (i: string) => i.split('-').at(-1),
            ),
          }
        : {}),
    };
  }

  async requestProductRecommendations(
    skus: string[],
    sources: RecommendationSource[],
  ): Promise<ProductRecommendationResponse> {
    if (!skus.length || !sources.length) {
      return {};
    }

    try {
      const responses = await Promise.all(
        sources.map((source) =>
          CatalogRequests.getRecommendations({
            skus,
            warehouseCode: orderStore.etaWarehouseCode,
            source,
          }),
        ),
      );

      return sources.reduce<ProductRecommendationResponse>(
        (memo, source, idx) => {
          const response = responses[idx];

          if (!response) {
            return memo;
          }

          const { data, pagination } = response;

          return {
            ...memo,
            [source]: {
              data: data.map((product) => new Product(product)),
              pagination,
            },
          };
        },
        {},
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        this.errorHandler(error, 'recommendations');
      }
      return {};
    }
  }

  async getProductRecommendations(
    skus: string[],
    sources: RecommendationSource[],
  ): Promise<void> {
    this.productRecommendationsMeta.setLoading(true);

    const recommendations = await this.requestProductRecommendations(
      skus,
      sources,
    );

    runInAction(() => {
      this.productRecommendations = {
        ...this.productRecommendations,
        ...recommendations,
      };

      this.productRecommendationsMeta.setLoading(false);
    });
  }

  readonly getProductRecommendationDebounced = debounce(
    this.getProductRecommendations.bind(this),
    350,
  );

  clearProductRecommendations(source: RecommendationSource): void {
    if (source in this.productRecommendations) {
      this.productRecommendations = omit(this.productRecommendations, source);
    }
  }

  async requestFilters() {
    try {
      const { data } = await CatalogRequests.getFilters();
      this.setFilters(data);

      return data;
    } catch (e) {
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'requestFilters',
      );
      return [];
    }
  }

  async requestRecommendProducts(
    sku: string[],
    source: RecommendProductsSource,
  ): Promise<Product[] | null> {
    try {
      const { data } = await CatalogRequests.getRecommendProducts(
        orderStore.etaWarehouseCode,
        {
          sku,
          source,
          isSafeRequest: mainStore.isSafeRequest || undefined,
        },
      );
      const products: Product[] = [];
      data.forEach((item) => {
        const product = new Product(item);
        if (!product) {
          return;
        }
        products.push(product);
      });
      if (source === 'purchased_items') {
        this.setRecommendItems(products);
      }
      return products;
    } catch (e) {
      return null;
    }
  }

  addFavorite(ids: number[]) {
    FavoritesRequest.addManyFavorites({ ids }).catch((e) => {
      this.errorHandler(e, 'addFavorite').catch(
        (error) => error && console.error(error),
      );
    });
  }

  removeFavorite(id: number) {
    FavoritesRequest.removeFavorite(id).catch((e) => {
      this.errorHandler(e, 'removeFavorite').catch(
        (error) => error && console.error(error),
      );
    });
  }

  async fetchFavorites() {
    if (!userStore.isAuthorized) {
      return;
    }
    try {
      const { data } = await FavoritesRequest.getFavorites({
        warehouseCode: orderStore.etaWarehouseCode,
        isSafeRequest: mainStore.isSafeRequest || undefined,
      });
      if (!data?.length) {
        return;
      }
      const forEntries = data
        .map<[number, Product | null]>((product) => [
          product.id,
          new Product(product),
        ])
        .filter(([, product]) => product) as [number, Product][];
      this.setFavorites(Object.fromEntries(forEntries));
    } catch (e) {
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'fetchFavorites',
      );
    }
  }

  async fetchPurchasedItems(isForce?: boolean): Promise<boolean> {
    if (!userStore.isAuthorized) {
      return false;
    }

    try {
      if (isForce) {
        /**
         * Code below will reset uploaded items
         *
         * It is specific case for situation when user makes an order, and was navigated to home page,
         * On main page user should see at the beginning of carousel products that was bought in last order.
         *
         * To achieve that we need to reset all loaded purchase items and fetch it again.
         * */
        this.purchasedItemsPage = 1;
        this.purchasedItems = new Map<number, Product>();
      } else {
        this.purchasedItemsPage += 1;
      }

      const { data = [] } = await FavoritesRequest.getPurchasedItems(
        orderStore.etaWarehouseCode,
        this.purchasedItemsPage,
        PURCHASED_ITEMS_COUNT,
      );

      runInAction(() => {
        data.forEach((product) => {
          this.purchasedItems.set(product.id, new Product(product));
        });
      });

      return data?.length > 0;
    } catch (e) {
      e && console.error(e);
    }

    return false;
  }

  async requestPurchasedItems(limit: number): Promise<Product[]> {
    if (!userStore.isAuthorized) {
      return [];
    }

    try {
      const { data = [] } = await FavoritesRequest.getPurchasedItems(
        orderStore.etaWarehouseCode,
        1,
        limit,
      );

      return data
        .map((item) => new Product(item))
        .filter((item) => item) as Product[];
    } catch (e) {
      e && console.error(e);
    }

    return [];
  }

  debouncedCalculateCart = debounce(() => {
    this.calculateCart();
  }, 500);

  // TODO: refactor. Method has side effects
  /**
   * @return
   * true - cart items changed
   * false - cart items not changed
   * */
  async calculateCart(): Promise<boolean> {
    if (!this.cart.length) {
      return false;
    }

    const currentRequestId = this.calculationProcess.requestId + 1;

    this.setCalculationProcess({
      requestId: currentRequestId,
      isError: false,
      isLoading: true,
    });

    const cartObj: Record<string, CartItem> = {};

    try {
      const requestedItems: CartCalculationItemRequest[] = [];
      const requestedBundles: CartBundlesCalculationItemRequest[] = [];
      const skuList: string[] = [];
      const productIdList: number[] = [];

      const selectedItems = this.cart
        .filter((item) => item.selected)
        .map((item) => toJS(item));

      for (let i = 0; i < selectedItems.length; i++) {
        // TODO asinkov cart
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        const isProductOffer = !isProductBundle(selectedItems[i]);
        if (isProductOffer) {
          requestedItems.push({
            sku: (selectedItems[i] as Offer).sku,
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            requested_quantity: selectedItems[i].count,
          });
          skuList.push((selectedItems[i] as Offer).sku);
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          cartObj[(selectedItems[i] as ProductOffer).sku] = selectedItems[i];
        } else {
          requestedBundles.push({
            id: (selectedItems[i] as Product).id,
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            requested_quantity: selectedItems[i].count,
          });
          productIdList.push((selectedItems[i] as Product).id);
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          cartObj[(selectedItems[i] as Product).id] = selectedItems[i];
        }
      }

      const deliveries =
        checkoutStore.deliveries?.map(({ itemsIds, slot }) => {
          const requestedItems: CartCalculationItemRequest[] = selectedItems
            .filter(
              (item) => itemsIds.includes(item.sku) && !isProductBundle(item),
            )
            .map((item) => {
              return {
                sku: (item as Offer).sku,
                requested_quantity: item.count,
              };
            });

          const requestedBundles: CartBundlesCalculationItemRequest[] =
            selectedItems
              .filter(
                (item) => itemsIds.includes(item.sku) && isProductBundle(item),
              )
              .map((item) => {
                return {
                  id: item.id,
                  requested_quantity: item.count,
                };
              });

          return {
            bundles: requestedBundles,
            items: requestedItems,
            slot_delivery_details: slot && {
              schedule_slot_id: slot.schedule_slot_id,
              current_date: slot.current_date,
            },
          };
        }) ?? [];

      const requestData: CartCalculationRequest = {
        address: {
          latitude:
            userStore.deliveryAddress?.coordinates.lat ??
            company.config.map.center.lat,
          longitude:
            userStore.deliveryAddress?.coordinates.lng ??
            company.config.map.center.lng,
        },
        delivery_method: checkoutStore.deliveryMethod,
        deliveries,
        seller: 'jiffy',
        should_use_bonuses: checkoutStore.useBonuses,
        warehouse_code: orderStore.etaWarehouseCode,
        // user can use bonuses or promocode only
        promocode: checkoutStore.useBonuses
          ? undefined
          : this.promocode.coupon?.code,
        tips_amount: mainStore.convertPoundsToPence(orderStore.orderTipsValue),
      };

      Sentry.withScope((scope) => {
        scope.setExtras({
          context: 'Calculate ',
          data: requestData,
        });
        Sentry.captureMessage('[Checkout] CALCULATE REQUEST DATA', 'warning');
      });

      const {
        base_total: totalBasePence,
        discount_total: totalDiscountPence,
        paid_total: paidPence,
        promocode_discount: promocodeDiscountPence,
        paid_bonuses,
        tax_percent: taxPercent,
        tax_amount: taxAmount,
        orders,
      } = await CatalogRequests.calculateCart(requestData);

      Sentry.withScope((scope) => {
        scope.setExtras({
          context: 'Calculate ',
          data: {
            base_total: totalBasePence,
            discount_total: totalDiscountPence,
            paid_total: paidPence,
            promocode_discount: promocodeDiscountPence,
            paid_bonuses,
            tax_percent: taxPercent,
            tax_amount: taxAmount,
            orders,
          },
        });
        Sentry.captureMessage('[Checkout] CALCULATE RESPONSE DATA', 'warning');
      });

      if (!orders[0]) {
        throw new Error('Something went wrong');
      }

      const { promocode, promocodeError } = orders[0];

      if (this.calculationProcess.requestId > currentRequestId) {
        // this means that user sends another request to server and this one no longer valid, so it should be ignored
        return false;
      }

      mainStore.sendToRN('sendTags', {
        cart_price: mainStore.toFloat(paidPence),
      });

      const amountLeftToDiscount = this.calculateAmountLeftToDiscount(
        totalBasePence,
        totalDiscountPence,
      );

      let isCartChanged = false;

      runInAction(() => {
        this.deliveriesOrders = orders;

        this.totalCartPrice = {
          amountLeftToDiscount,
          base: mainStore.convertPenceToPounds(totalBasePence),
          discount: mainStore.convertPenceToPounds(totalDiscountPence),
          // all discounts that was applied for current cart
          paidWithDiscount: mainStore.convertPenceToPounds(
            totalBasePence -
              (totalDiscountPence + promocodeDiscountPence + paid_bonuses),
          ),
          paidWithoutDiscount: mainStore.convertPenceToPounds(
            totalBasePence - (totalDiscountPence + paid_bonuses),
          ),
          paid: mainStore.convertPenceToPounds(paidPence),
          promocodeDiscount: mainStore.convertPenceToPounds(
            promocodeDiscountPence,
          ),
          paidBonusesPence: paid_bonuses,
          taxPercent,
          taxAmount,
        };

        this.setCalculationProcess({
          ...this.calculationProcess,
          isLoading: false,
        });

        if (this.cart.length) {
          const slugsList = this.cart.reduce<Record<string, string>>(
            (acc, item) => {
              if (!item.slug) {
                return acc;
              }
              acc[item.sku] = item.slug;
              return acc;
            },
            {},
          );

          // TODO: change update cart logic
          isCartChanged = this.updateCartItems(
            skuList,
            cartObj,
            orders
              .flatMap(({ items }) => items)
              .map((item) => {
                item.slug = slugsList[item.sku];
                return item;
              }),
          );
        }

        if (promocode) {
          this.promotionStore.handleCalculateWithPromocodeResponse(
            promocodeError,
          );
        }

        if (!promocode && this.promotionStore.applyPromocodeMeta.isLoading) {
          this.promotionStore.applyPromocodeMeta.setLoading(false);
        }
      });

      return isCartChanged;
    } catch (error) {
      if (this.calculationProcess.requestId > currentRequestId) {
        // this means that user sends another request to server and this one no longer valid, so it should be ignored
        return false;
      }

      if (this.calculationProcess.requestId === currentRequestId) {
        this.setCalculationProcess({
          requestId: currentRequestId,
          isError: true,
          isLoading: false,
        });
      }

      runInAction(() => {
        // TODO: Seems that this 👇🏻 handler doesn't using in CD. Need to double check and remove it if unnecessary
        if (error instanceof AxiosError && error.response?.data?.data?.length) {
          this.changeCountProductList = [];
          for (let i = 0; i < error.response.data.data.length; i++) {
            if (error.response.data.data[i].on_stock <= 0) {
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              cartObj[error.response.data.data[i].sku].count = 0;
            } else {
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              cartObj[error.response.data.data[i].sku].count =
                error.response.data.data[i].on_stock;
              this.changeCountProductList.push(
                // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                toJS(cartObj[error.response.data.data[i].sku]),
              );
            }
          }
          this.cart = Object.values(cartObj);
        }

        if (
          error instanceof AxiosError &&
          error?.response?.data?.data?.data?.items?.length
        ) {
          const { response } = error;
          const errorResponse: CalculateStockErrorInstance = {
            items: [],
            bundles: null,
            ...response?.data?.data?.data,
          };

          this.cartStockChangesStore.openNotifierModal(errorResponse);
          this.changeCountProductList.push(
            ...(errorResponse.items
              .map((item) => cartObj[item.sku])
              .filter(Boolean) as CartItem[]),
          );
        }

        if (this.promotionStore.applyPromocodeMeta.isLoading) {
          this.promocode.success = false;
          this.promocode.errorType = 'error';
          this.promocode.message = i18n.t('errors:promocodeError');

          this.promotionStore.applyPromocodeMeta.setLoading(false);
        }
      });

      if (
        error instanceof AxiosError &&
        error.response?.status === 500 &&
        error.response?.data?.errors?.includes('Calculate Cart Api Error')
      ) {
        runInAction(() => {
          orderStore.reCalculateDeliveries();
        });
      }

      if (this.changeCountProductList.length) {
        return true;
      }
    }
    return false;
  }

  convertToFirebase(
    item: (Product | Offer) & { count?: number },
    i: number = 0,
  ): FirebaseCartItem {
    return {
      item_id: isProductBundle(item) ? item.id.toString() : (item as Offer).sku,
      item_name: item.name,
      quantity: item.count || 1,
      promotion_id: '',
      promotion_name: '',
      affiliation: '',
      coupon: this.promocode.value,
      creative_name: '',
      creative_slot: '',
      discount: item.discountPrice
        ? mainStore.toFloat(
            mainStore.convertPenceToPounds(item.price - item.discountPrice),
          )
        : 0,
      index: i,
      item_brand: '',
      item_category: item.categoryName || '',
      item_list_name: item.categoryName || '',
      item_list_id: item.categoryId?.toString() || '',
      item_variant: '',
      location_id: '',
      tax: 0,
      price: mainStore.toFloat(item.priceFormatted),
      currency: orderStore.currency.toUpperCase(),
    };
  }

  calculateAmountLeftToDiscount(
    baseTotalPence: number,
    discountTotalPence: number,
  ): string {
    const totalDiscountPence = baseTotalPence - discountTotalPence;

    if (
      !this.promocode.success ||
      !this.promocode.coupon ||
      this.isCharityPromocode
    ) {
      return '0';
    }

    const {
      type,
      minimumPurchase,
      value: discountValuePence,
    } = this.promocode.coupon;

    if (totalDiscountPence < minimumPurchase) {
      const result = minimumPurchase - totalDiscountPence;
      return result < 0 ? '0' : mainStore.convertPenceToPounds(result);
    }

    if (type === 'FIXED' && totalDiscountPence < discountValuePence) {
      const result = discountValuePence - totalDiscountPence;
      return result < 0 ? '0' : mainStore.convertPenceToPounds(result);
    }

    return '0';
  }

  leftUntilFreeFee(totalPrice: number, feeThreshold: number): [string, string] {
    const zero: [string, string] = ['0', '100%'];
    if (!this.cart.length) {
      return zero;
    }

    if (totalPrice >= feeThreshold) {
      return zero;
    }
    const remainder = feeThreshold - totalPrice;
    const remainderPercent = 100 - (remainder * 100) / (feeThreshold || 1);
    return [mainStore.convertPenceToPounds(remainder), remainderPercent + '%'];
  }

  setCurrentFilters = (filters: ProductFilters) => {
    this.currentFilters = filters;
  };

  applyCatalogFilter(code: ProductPropertyFilterCode, value?: any) {
    this.page.current = 1;
    if (code === 'price' || code === 'discountPrice') {
      if (!value) {
        delete this.currentFilters[code];
      } else {
        this.currentFilters = {
          ...this.currentFilters,
          [code]: value,
        };
      }

      return;
    }

    /**
     * FYI: for backend puproses.
     *  it's required 3 state checkbox (no value, true, false) to able handle such cases for toggle type properties (Mark E.)
     * What is mean 'false' value for toggle property?
     *  - strict check false value?
     *  - property not added to product / offer?
     */

    if (!value || (typeof value === 'boolean' && !value)) {
      delete this.currentFilters.properties[code];
      return;
    }

    let propertyValue = value;

    if (Array.isArray(value)) {
      propertyValue = value.filter((i) => !!i || i === 0);
      if (value.length === 0) {
        delete this.currentFilters.properties[code];
        this.currentFilters = { ...this.currentFilters };
        return;
      }
    }

    this.currentFilters = {
      ...this.currentFilters,
      properties: {
        ...this.currentFilters.properties,
        [code]: propertyValue,
      },
    };
  }

  resetCatalog() {
    runInAction(() => {
      this.resetCatalogBadges();
      this.resetCatalogBadges();
      this.resetSorting();
    });
  }

  resetCatalogFilters() {
    this.currentFilters = {
      properties: {},
    };
  }

  applyCatalogSorting(sort: [SortingFieldName, SortingOrder]) {
    this.currentSorting = sort;
  }

  addCatalogBadge(id: string, code: ProductPropertyFilterCode, value: any) {
    if (this.currentBadges.includes(id)) {
      return this.currentBadges;
    }

    this.currentBadges.push(id);
    this.applyCatalogFilter(code, value);
  }

  removeCatalogBadge(id: string, code: ProductPropertyFilterCode) {
    this.currentBadges = this.currentBadges.filter((b) => b !== id);
    this.applyCatalogFilter(code);
  }

  resetCatalogBadges() {
    this.currentBadges = [];
  }

  // Errors
  errorHandler = (
    error: AxiosError<ApiErrorResponse>,
    context: string,
  ): Promise<AxiosError> => mainStore.errorHandler(error, context);

  getSortProductsFn() {
    const sortingKey = this.currentSorting.join('.');

    switch (sortingKey) {
      case 'price.ASC':
        return (a: Product, b: Product) => a.price - b.price;
      case 'price.DESC':
        return (a: Product, b: Product) => b.price - a.price;
      case 'createdAt.DESC':
        return (a: Product, b: Product) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      case 'salesQuantity.DESC':
        return (a: Product, b: Product) => b.salesQuantity - a.salesQuantity;
      case 'rating.DESC':
        return (a: Product, b: Product) => a.ratingAverage - b.ratingAverage;
    }
  }

  prepareProducts(category: Category): Product[] {
    const fn = this.getSortProductsFn();
    const type = this.currentFilters.properties?.['type'] as string;
    const subcategories = type
      ? category.subcategory.filter(({ id }) => type.includes(String(id)))
      : null;

    const getNestedProducts = (category: Category): [Product[], unknown[]] => [
      category.ownProducts,
      category.subcategory.map(getNestedProducts),
    ];

    const products = (
      subcategories?.length
        ? subcategories.map(getNestedProducts).flat(Infinity)
        : getNestedProducts(category).flat(Infinity)
    ) as Product[];

    return fn ? products.sort(fn) : products;
  }

  resetSorting() {
    this.currentSorting = ['salesQuantity', 'DESC'];
  }

  setCategoryShutterData(category: ProductListValue) {
    this.categoryShutterData[category.categoryId] = category.filters
      .filter(({ code }) => ['type', 'region', 'country'].includes(code))
      .map(({ code, type, values }) => ({
        header: `properties:${code}`,
        categoryId: category.categoryId,
        items: values.map(({ label, value }) => ({
          label,
          filter: {
            code,
            value: type === 'selector' ? [value] : value,
          },
        })),
      }));
  }

  getCurrentCategoryShutterData() {
    return this.categoryShutterData[this.categoryShutter[1]];
  }

  openCategoryShutter(categoryId: string | number) {
    this.categoryShutter = [true, categoryId];
  }

  closeCategoryShutter() {
    this.categoryShutter = [false, 0];
  }

  private convertPriceFilterToPences(
    filter?: ProductRangeFilter,
  ): ProductRangeFilter | undefined {
    if (!filter) {
      return;
    }

    return {
      from: filter.from
        ? mainStore.convertPoundsToPence(filter.from)
        : filter.from,
      to: filter.to ? mainStore.convertPoundsToPence(filter.to) : filter.to,
    };
  }

  destroy() {
    // Implement this method to fall within the interface
    // We'll add necessary cleanups later.
    this.cartStockChangesStore.destroy();
  }
}

export const catalogStore = new CatalogStore();

reaction(
  () =>
    catalogStore.cart.map(({ id, sku, count, selected }) => ({
      id,
      sku,
      count,
      selected,
    })),
  () => {
    catalogStore.setCalculationProcess({
      ...catalogStore.calculationProcess,
      requestId: catalogStore.calculationProcess.requestId + 1,
    });
    checkoutStore.calculateDeliveries(checkoutStore.sortedDeliverySlotsList);
  },
  { equals: isEqual },
);

reaction(
  () =>
    catalogStore.cart
      .filter(({ count }) => count <= 0)
      .map((item) => {
        const isBundle = isProductBundle(item);
        return { id: item.id, sku: item.sku, isBundle };
      }),
  (offerIds) => {
    offerIds.forEach((offerId) => {
      const { id, sku, isBundle } = offerId;
      if (isBundle && id) {
        catalogStore.removeCartItemByOfferId(id);
      } else if (sku) {
        catalogStore.removeCartItemByOfferSKU(sku);
      }
    });
  },
  { equals: isEqual },
);
