import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CD_Policies from '~/assets/img/cd_policies.png';
import CD_Policies_2 from '~/assets/img/cd_policies_2.png';
import Logo from '~/assets/img/logo_cd_without_text.svg';
import { company } from '~/company/Company';
import Collapsible from '~/components/Collapsible/Collapsible';
import { ContactInformation } from '~/components/ContactInformation/ContactInformation';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './ShippingReturn.module.scss';

interface AccordionItem {
  id: number;
  title: string;
  content: string;
}

const data = {
  shopping_info: [
    {
      id: 0,
      title: 'Where does CityDrinks deliver?',
      content:
        'CityDrinks currently delivers to locations in Abu Dhabi, with plans for expansion to other areas soon.',
    },
    {
      id: 1,
      title: 'What is the delivery timeframe?',
      content:
        'We strive to provide swift delivery within the minimum time of <span style="color: #AA263E">30 minutes in the express delivery areas</span>, depending on your location. <br /><br /> Live tracking is available for your convenience.',
    },
    {
      id: 2,
      title: 'Are there delivery charges?',
      content: `You can find the terms of delivery on the website <a href=${company.config.canonical} target="_blank">Citydrinks.com</a>`,
    },
    {
      id: 3,
      title: 'Is there an age restriction for delivery?',
      content:
        'To place an order with CityDrinks, you must be at least 21 years of age. ',
    },
  ],
  return_policy: [
    {
      id: 4,
      title: `What is CityDrinks' return policy?`,
      content: `We take pride in the quality of our products. If you are not satisfied with your purchase, please email us at <a href="mailto:${company.config.email}">${company.config.email}</a> within 24h of receiving your order.`,
    },
    {
      id: 5,
      title: 'Can I return opened items?',
      content:
        'For health and safety reasons, we cannot accept returns on opened or partially consumed items unless there is a quality issue. If you believe your product is defective or damaged, please <a href="/contact-us" target="_blank">contact us</a> for assistance.',
    },
    {
      id: 6,
      title: 'How do I return a product?',
      content: `To initiate a return, please email us <a href="mailto:${company.config.email}">${company.config.email}</a> with your order number and a brief explanation of the reason for the return.<br /> Our customer service team will guide you through the process.`,
    },
    {
      id: 7,
      title: 'What if my order arrives damaged?',
      content: `If your order arrives damaged, please contact us immediately at <a href="mailto:${company.config.email}">${company.config.email}</a>.<br/> We will work with you to arrange for a replacement or refund.`,
    },
    {
      id: 8,
      title: 'How long does it take to process a return?',
      content:
        'Once we receive your return request, we will process it within 5-10 business days. The refund will be issued to the original payment method.',
    },
    {
      id: 9,
      title: 'Do I have to pay for return shipping?',
      content:
        'Return shipping costs may be applicable, and we will provide you with details based on the circumstances of your return.',
    },
  ],
};

export default observer(() => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();
  const { state } = useLocation();
  const source = state?.source || 'home';
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      {isTablet && (
        <MobileHeaderLayout
          showTopCart
          content={<MobileHeaderTitle text={t('policies')} />}
        />
      )}
      <div className={classes(['content-layout', styles.root])}>
        {!isTablet && (
          <>
            <Breadcrumbs
              items={
                [
                  {
                    text: t('home'),
                    link: '/',
                  },
                  source === 'cabinet' && {
                    text: t('cabinet'),
                    link: '/cabinet',
                  },
                  {
                    text: t('policies'),
                    link: '/policies',
                  },
                ].filter(Boolean) as BreadcrumbsItem[]
              }
              className={styles.breadcrumbs}
            />
          </>
        )}
        <section className={styles.header}>
          <p className={styles.header__welcome}>
            Shipping <br />& Return Policies
          </p>

          <img src={CD_Policies} className={styles.image} />

          <h3 className={styles.content__article_first}>
            Shipping Information
          </h3>
          {data?.shopping_info?.map((item: AccordionItem) => (
            <div
              key={item.id}
              className={styles.header_accordion_item}
              onClick={() => toggleAccordion(item.id)}
            >
              <Collapsible
                titleElem={
                  <div className={styles.header__title}>
                    <span>{item.title}</span>
                    <Icon type="chevron" size={16} />
                  </div>
                }
                isCollapsed={activeIndex !== item?.id}
                bodyContainerClassName={styles.header__wrapper}
              >
                <div className={styles.header_accordion_content}>
                  <img src={Logo} />
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              </Collapsible>
            </div>
          ))}

          <img src={CD_Policies_2} className={styles.image} />

          <h3 className={styles.content__article_first}>Return Policy</h3>
          {data?.return_policy?.map((item: AccordionItem) => (
            <div
              key={item.id}
              className={styles.header_accordion_item}
              onClick={() => toggleAccordion(item?.id)}
            >
              <Collapsible
                titleElem={
                  <div className={styles.header__title}>
                    <span>{item.title}</span>
                    <Icon type="chevron" size={16} />
                  </div>
                }
                isCollapsed={activeIndex !== item?.id}
                bodyContainerClassName={styles.header__wrapper}
              >
                <div className={styles.header_accordion_content}>
                  <img src={Logo} />
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              </Collapsible>
            </div>
          ))}
          <ContactInformation
            content={`If you have any further questions or concerns, <br/> please don't hesitate to contact us at`}
            email={company.config.email}
            className={styles.contactInfo}
          />
        </section>
      </div>
    </>
  );
});
