import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Address } from '~/api/DeliveryAddress';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { orderStore } from '~/stores/OrderStore';

import { useModal } from '../../hooks/useModal';
import { userStore } from '../../stores/UserStore';

import Icon from '../Icon/Icon';

export default observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [currentAddress, setCurrentAddress] = useState<Address | null>(null);

  const submitDeleteAddress = async () => {
    try {
      if (
        userStore.addressToDelete &&
        (await userStore.deleteAddress(userStore.addressToDelete))
      ) {
        if (userStore.addressList.length > 0) {
          try {
            const address = userStore.addressList.at(-1);
            if (address) {
              const etaCalculation = await orderStore.fetchETA(
                `${address.latitude},${address.longitude}`,
              );
              if (!etaCalculation) {
                return;
              }
              userStore.setDeliveryAddress({
                zip: address.postcode || '',
                country: 'GB',
                city: address.city || 'London',
                region: 'Greater London',
                address1: address.street_address_1 || '',
                address2: address.street_address_2 || '',
                type: address.type ?? 'home',
                shortAddress: address.street_address_1 || '',
                coordinates: {
                  lat: address.latitude,
                  lng: address.longitude,
                },
                placeId: '',
                addressId: address.id,
                comment: address.comment || '',
                instructions: toJS(address.instructions || []),
              });
              orderStore.setEtaCalculation(etaCalculation);
              checkoutStore.setDeliverySlots(
                etaCalculation.deliverySlots || null,
              );
            }
          } catch (e) {
            return;
          }
        }
        backHandler();
        // list.push(deletingList[i]);
      }
      // else isError = true;
    } catch (e) {
      // isError = true;
    }
  };

  const backHandler = () => {
    userStore.setAddressToDelete(null);
    closeModal();
  };

  useEffect(() => {
    const address = userStore.addressList.find(
      (i) => i.id === userStore.addressToDelete,
    );
    if (address) {
      setCurrentAddress(address);
    }
  }, []);

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className="button _no-padding _no-color input-form__back"
          onClick={backHandler}
        >
          <Icon type="arrow" className="icon__rotate-180" size={24} />
        </button>
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={backHandler}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <div className="input-form__form">
          <div className="input-form__form-header">
            <p className="input-form__form-header-title">
              {t('phrases:wantToDeleteAddress')}
            </p>
            <p className="input-form__form-header-subtitle">
              {currentAddress?.street_address_1}
            </p>
          </div>
          <div className="input-form__form-footer">
            <div className="input-form__form-footer-buttons">
              <button className="button _bordered _med" onClick={backHandler}>
                {t('goBack')}
              </button>
              <button
                className="button _primary _med"
                onClick={submitDeleteAddress}
              >
                {t('delete')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
