import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { company } from '~/company/Company';
import { useOnWarehouseChange } from '~/hooks/useOnWarehouseChange';
import { shopCategories } from '~/stores/CategoriesStore';

import AuthorizationGuard from '../../components/AuthorizationGuard/AuthorizationGuard';
import { useGlobal } from '../../hooks/useGlobal';

import Address from '../Address/Address';
import Brand from '../Brand';
import Cabinet from '../Cabinet/Cabinet';
import Careers from '../Careers';
import Cart from '../Cart/Cart';
import Catalog from '../Catalog/Catalog';
import Checkout from '../Checkout/Checkout';
import ContactUs from '../ContactUs/ContactUs';
import Events from '../Events/Events';
import FAQ from '../FAQ';
import Favs from '../Favs/Favs';
import Icons from '../Icons/Icons';
import Influencers from '../Influencers';
import Legals from '../Legals/Legals';
import Main from '../Main/Main';
import NotFound from '../NotFound/NotFound';
import OrderPage from '../Order/Order';
import Orders from '../Orders/Orders';
import OurStory from '../OurStory';
import { PaymentPage } from '../PaymentMethod/PaymentMethod';
import PersonalData from '../PersonalData/PersonalData';
import Product from '../Product/Product';
import ReferralProgram from '../ReferralProgram/ReferralProgram';
import Refund from '../Refund';
import RewardedPromocodes from '../RewardedPromocodes/RewardedPromocodes';
import { SearchResults } from '../SearchResults';
import ShippingReturn from '../ShippingReturn';
import Shop from '../Shop/Shop';
import Subscription from '../Subscription/Subscription';

import { ROUTES } from './constants';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

export const NOT_FOUND_PAGE = '/not-found';

export default observer(() => {
  const { isMobile } = useGlobal();
  const { pathname } = useLocation();
  const layoutRef = useRef<HTMLDivElement>(null);

  const LayoutComponent = isMobile ? MobileLayout : DesktopLayout;

  useEffect(() => {
    if (layoutRef?.current) {
      layoutRef.current.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }
  }, [pathname, layoutRef]);

  useOnWarehouseChange((code, isChanged) => {
    if (isChanged || !shopCategories.list.length) {
      shopCategories.request.run(code);
    }
  });

  return (
    <>
      <LayoutComponent layoutRef={layoutRef}>
        <Routes>
          <Route path={ROUTES.index} element={<Main />} />
          <Route path={ROUTES.catalog} element={<Catalog />} />
          <Route path={ROUTES.cart} element={<Cart />} />
          <Route path={ROUTES.favs} element={<Favs />} />
          <Route
            path={ROUTES.checkout}
            element={
              <AuthorizationGuard>
                <Checkout />
              </AuthorizationGuard>
            }
          />
          <Route path={ROUTES.shop} element={<Shop />} />
          <Route path={ROUTES.product} element={<Product />} />
          {/* TODO: It should be in /cabinet scope. */}
          <Route
            path={ROUTES.order}
            element={
              <AuthorizationGuard>
                <OrderPage />
              </AuthorizationGuard>
            }
          />
          <Route path={ROUTES.cabinet}>
            <Route
              index
              element={
                <AuthorizationGuard>
                  <Cabinet />
                </AuthorizationGuard>
              }
            />
            <Route path={ROUTES.cabinet_orders}>
              <Route
                index
                element={
                  <AuthorizationGuard>
                    <Orders />
                  </AuthorizationGuard>
                }
              />
              <Route
                path={ROUTES.cabinet_orders_tab}
                element={
                  <AuthorizationGuard>
                    <Orders />
                  </AuthorizationGuard>
                }
              />
            </Route>
            <Route
              path={ROUTES.cabinet_personalData}
              element={
                <AuthorizationGuard>
                  <PersonalData />
                </AuthorizationGuard>
              }
            />
            <Route
              path={ROUTES.cabinet_address}
              element={
                <AuthorizationGuard>
                  <Address />
                </AuthorizationGuard>
              }
            />
            <Route
              path={ROUTES.cabinet_events}
              element={
                <AuthorizationGuard>
                  <Events />
                </AuthorizationGuard>
              }
            />
            <Route
              path={ROUTES.cabinet_subscriptions}
              element={
                <AuthorizationGuard>
                  <Subscription />
                </AuthorizationGuard>
              }
            />
            <Route
              path={ROUTES.cabinet_paymentMethod}
              element={
                <AuthorizationGuard>
                  <PaymentPage />
                </AuthorizationGuard>
              }
            />
            <Route
              path={ROUTES.cabinet_referral}
              element={
                <AuthorizationGuard requireVerified>
                  <ReferralProgram />
                </AuthorizationGuard>
              }
            />
            <Route
              path={ROUTES.cabinet_referral_rewards}
              element={
                <AuthorizationGuard requireVerified>
                  <RewardedPromocodes />
                </AuthorizationGuard>
              }
            />
          </Route>
          <Route path={ROUTES.search} element={<SearchResults />} />
          <Route path={ROUTES.legals} element={<Legals />} />

          {company.match({
            citydrinks: (
              <>
                <Route
                  path={ROUTES.shippingReturn}
                  element={<ShippingReturn />}
                />
                <Route path={ROUTES.refund} element={<Refund />} />
                <Route path={ROUTES.faq} element={<FAQ />} />
                <Route path={ROUTES.influencers} element={<Influencers />} />
                <Route path={ROUTES.careers} element={<Careers />} />
                <Route path={ROUTES.contactUs} element={<ContactUs />} />
                <Route path={ROUTES.brand} element={<Brand />} />
                <Route path={ROUTES.ourStory} element={<OurStory />} />
              </>
            ),
          })}
          {import.meta.env.DEV && <Route path="/icons" element={<Icons />} />}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </LayoutComponent>
    </>
  );
});
