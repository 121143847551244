import { format, set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';
import Modal from '~/components/Modal/Modal';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import styles from './ModalDeliveryInfo.module.scss';

interface DeliveryBannerModalProps {
  isShow: boolean;
}
const ModalDeliveryInfo = observer(({ isShow }: DeliveryBannerModalProps) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  if (!isShow) {
    return null;
  }
  const expressDelivery = orderStore.expressDelivery;
  const scheduledDelivery = orderStore.scheduledDelivery;
  const closestSlot = orderStore.closestSlot;
  const deliveryScheduleLimits = orderStore.slotDeliverySchedule;
  const expressCost = orderStore.fee;

  const parseTime = (time?: string) => {
    if (!time) {
      return '';
    }

    const [hours, minutes] = time.split(':');

    const result = format(
      set(Date.now(), { hours: +hours!, minutes: +minutes! }),
      'hh:mm a',
    ).replace(/AM|PM/gi, (match) => match.toLowerCase().split('').join('.'));

    return result.endsWith('.') ? result : result + '.';
  };

  const convertPrice = (price: number) =>
    formatPriceWithCurrency(mainStore.convertPenceToPounds(price));

  return (
    <Modal isShow={isShow} className={styles.deliveryTerms}>
      <div className={styles.deliveryTerms}>
        <header className={styles.deliveryTerms__header}>
          <h2>{t('deliveryTerms')}</h2>
          <button className="button" onClick={closeModal}>
            <Icon type="close" size={isMobile ? 24 : 40} />
          </button>
        </header>
        <div className={styles.deliveryTerms__body}>
          <div className={styles.section}>
            {expressDelivery && expressCost && scheduledDelivery && (
              <p>{t('deliveryOffers')}:</p>
            )}
            <ul>
              <li>
                <div>
                  <span className={styles.express}>{t('expressDelivery')}</span>{' '}
                  - {t('hasSuchIcon')} <Icon type="express-fill" size={20} />
                  {!expressDelivery && (
                    <div className={styles.unavailable}>
                      ({t('deliveryUnavailable')})
                    </div>
                  )}
                </div>
              </li>
              <li>
                <div>
                  <span className={styles.scheduled}>
                    {t('standardDelivery')}
                  </span>{' '}
                  - {t('standardDeliveryInfo')}
                  {!scheduledDelivery && (
                    <div className={styles.unavailable}>
                      ({t('deliveryUnavailable')})
                    </div>
                  )}
                </div>
              </li>
            </ul>
            {expressDelivery && (
              <p>
                <span className={styles.express}>{t('expressDelivery2')}</span>{' '}
                {t('expressDeliveryTime', {
                  from: parseTime(expressDelivery.opening),
                  to: parseTime(expressDelivery.closing),
                })}
              </p>
            )}
            {scheduledDelivery && (
              <p>
                <span className={styles.scheduled}>
                  {t('standardDelivery')}
                </span>{' '}
                {t('scheduleDeliveryTime', {
                  from: parseTime(deliveryScheduleLimits.opening),
                  to: parseTime(deliveryScheduleLimits.closing),
                })}
              </p>
            )}
            <p>{t('orderMayBeSplit')}</p>
            <p>{t('deliveryFeeDescription')}</p>
          </div>

          <h3>{t('deliveryPaymentTerms')}</h3>
          {expressCost && expressDelivery && (
            <>
              <h4 className={styles.express}>
                <Icon type="express-fill" size={20} />
                {t('expressDelivery')}
              </h4>
              <div className={styles.keyValue}>
                <span>{t('deliveryFeeLong')}</span>
                <span>{convertPrice(expressCost.shipping)}</span>
              </div>
            </>
          )}
          {closestSlot && (
            <>
              <h4>{t('standardDelivery')}</h4>
              <div className={styles.keyValue}>
                <span>{t('deliveryFeeLong')}</span>
                <span>{convertPrice(closestSlot.delivery_price)}</span>
              </div>
            </>
          )}
          <p className={styles.small}>{t('prepareShowId')}</p>
        </div>
        <footer className={styles.deliveryTerms__footer}>
          <button className="button _primary" onClick={closeModal}>
            {t('okButton')}
          </button>
        </footer>
      </div>
    </Modal>
  );
});

export default ModalDeliveryInfo;
