import { makeAutoObservable } from 'mobx';

import { CatalogRequests, LandingPage } from '~/api/Catalog';
import { company } from '~/company/Company';
import { Category } from '~/stores/CategoriesStore/Category';
import { shopCategories } from '~/stores/CategoriesStore/ShopCategories';
import { mainStore } from '~/stores/MainStore';
import { Pagination } from '~/stores/shared/Pagination';
import toSpliced from '~/utils/toSpliced';

const PAGE_SIZE = 6;

export class HomeCategories {
  public readonly paginationRequest = new Pagination(
    this.loadCategories.bind(this),
  );

  constructor() {
    this.init();

    makeAutoObservable(this);
  }

  public static request(page: number, warehouse: string, serverLang?: string) {
    return CatalogRequests.getCategories(
      {
        warehouseCode: warehouse,
        landingPage: [LandingPage.HOME],
        includes: { products: 15 },
        page: { size: PAGE_SIZE, current: page },
      },
      serverLang,
    );
  }

  public get list() {
    const data = this.paginationRequest.result;

    if (!data.length) {
      return [];
    }

    const startShopping = shopCategories.startShoppingCategory;

    return startShopping && company.showStartShopping
      ? toSpliced(data, 2, 0, [startShopping])
      : data;
  }

  private async loadCategories(page: number, warehouse: string) {
    try {
      const response = await HomeCategories.request(page, warehouse);

      return this.convert(response);
    } catch (error) {
      return mainStore.errorHandler(error as never, 'requestCategories');
    }
  }

  private init() {
    if (
      typeof window !== 'undefined' &&
      window.__INITIAL_STATE__?.homeCategories
    ) {
      this.paginationRequest.setState(
        this.convert(window.__INITIAL_STATE__.homeCategories),
      );
    }
  }

  private convert({
    data,
    pagination,
  }: Awaited<ReturnType<typeof CatalogRequests.getCategories>>) {
    return {
      pagination,
      data: data.filter(Category.validate).map((data) => new Category(data)),
    };
  }
}

export const homeCategories = new HomeCategories();
