import { useSearchParams } from 'react-router-dom';

import { ModalType } from '~/components/Modal/interface';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';

let reopenModal: () => ModalType | null = () => null;

export const MODAL_EDIT_ADDRESS_PARAM = 'editAddress';

export const useModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const openModal = (name: ModalType, source?: string) => {
    if (
      name === ModalType.Auth &&
      (!userStore.deliveryAddress || !orderStore.etaCalculation)
    ) {
      name = ModalType.DeliveryAddress;
      reopenModal = () =>
        orderStore.etaCalculation &&
        userStore.deliveryAddress &&
        ModalType.Auth;
    }
    if (name === ModalType.DeliveryInfo) {
      mainStore.sendToRN('logAmplitudeEvent', {
        name: 'Delivery terms open',
        params: { source },
      });
    }
    searchParams.set('modal', name);
    setSearchParams(searchParams, { replace: true });
    return searchParams;
  };

  const closeModal = () => {
    const reopenModalName = reopenModal();
    if (reopenModalName) {
      searchParams.set('modal', reopenModalName);
      reopenModal = () => null;
      setSearchParams(searchParams, { replace: true });
    } else {
      searchParams.delete('modal');
      // TODO: make it more generic
      searchParams.delete(MODAL_EDIT_ADDRESS_PARAM);
      searchParams.delete('source');
      setSearchParams(searchParams, { replace: true });
    }
    return searchParams;
  };

  return {
    openModal,
    closeModal,
  };
};
