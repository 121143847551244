import { observer } from 'mobx-react-lite';

import { company } from '~/company/Company';
import ShopSidebarItem from '~/pages/Shop/ShopSidebarItem';
import { shopCategories } from '~/stores/CategoriesStore';

import styles from './ShopSidebar.module.scss';

const ShopSidebar = () => {
  const categories = shopCategories.list.flatMap(
    ({ subcategory }) => subcategory,
  );

  return (
    <div className={styles.container} data-company={company.name}>
      {categories.map((category) => {
        return <ShopSidebarItem key={category.id} category={category} />;
      })}
    </div>
  );
};

export default observer(ShopSidebar);
