import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import Icon from '~/components/Icon/Icon';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useDateLang } from '~/hooks/useDateLang';
import { getSelectedDay } from '~/pages/Checkout/utils';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';

import { DeliverySlotItemProps } from './interfaces';
import styles from './ModalSlotsChangedEvent.module.scss';

const DeliverySlotItem = ({ deliveryDifference }: DeliverySlotItemProps) => {
  const { t } = useTranslation();
  const { handleClickProduct } = useCategoryAnalytics();
  const swiperRef = useRef<SwiperRef | null>(null);
  const { locale } = useDateLang();

  const selectedDay = deliveryDifference.slot ? (
    getSelectedDay(deliveryDifference.slot, locale)
  ) : (
    <p className={styles.expressDeliveryLabel}>
      <Icon type="flash-fill" />
      <span>
        {t('phrases:deliveryInMin', {
          time: orderStore.etaCalculation?.duration.range,
        })}
      </span>
    </p>
  );

  const previousSelectedDate = deliveryDifference.previousDeliverySlot ? (
    getSelectedDay(deliveryDifference.previousDeliverySlot, locale)
  ) : (
    <p className={styles.expressDeliveryLabel}>
      <Icon type="flash-fill" />
      <span>
        {t('phrases:deliveryInMin', {
          time:
            checkoutStore.slotsChangedEventStore.previousExpressTime ??
            orderStore.etaCalculation?.duration.range,
        })}
      </span>
    </p>
  );

  const deliveryItems = catalogStore.selectedCartItems.filter(({ sku }) =>
    deliveryDifference.itemsIds.includes(sku),
  );

  return (
    <li className={styles.slotItem}>
      <div className={styles.slotDate}>
        <span className={styles.previousDate}>{previousSelectedDate}</span>
        <div className={styles.actualDate}>{selectedDay}</div>
      </div>
      <ul className="slot-list__slot-images">
        <Swiper
          ref={swiperRef}
          spaceBetween={4}
          slidesPerView="auto"
          modules={[Navigation]}
          dir={userStore.dir}
          key={userStore.dir}
        >
          {deliveryItems.map((offer) => (
            <SwiperSlide
              className="banner-carousel__item1"
              key={offer.images[0]}
            >
              <Link
                onClick={handleClickProduct(offer)}
                to={`/p/${offer.productId}`}
                state={{ offerSku: offer.sku }}
              >
                <div
                  key={offer.images[0]}
                  className="slot-list__slot-images-item"
                >
                  <img alt={offer.name} src={offer.images[0]} />
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </ul>
    </li>
  );
};

export default observer(DeliverySlotItem);
