import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';

import styles from './MobileHeaderEta.module.scss';

type Props = {
  expressDeliveryMessage: string;
  expressDeliveryMessageArgs: Record<string, string>;
  showFlashIcon?: boolean;
};

const MobileHeaderEta = ({
  expressDeliveryMessage,
  expressDeliveryMessageArgs,
  showFlashIcon,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={classes([styles.container, showFlashIcon && styles.active])}
      data-company={company.name}
    >
      {showFlashIcon && (
        <Icon type="express-fill" size={16} className={styles.icon} />
      )}
      <div className={styles.header_eta__text}>
        {t(expressDeliveryMessage, expressDeliveryMessageArgs)}
      </div>
      {/* <Icon type="info" size={16} className={styles.iconInfo} /> */}
    </div>
  );
};

export default observer(MobileHeaderEta);
