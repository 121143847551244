import { makeAutoObservable, runInAction } from 'mobx';

import { CatalogRequests, LandingPage } from '~/api/Catalog';
import { Category } from '~/stores/CategoriesStore/Category';
import { mainStore } from '~/stores/MainStore';
import { Task } from '~/stores/shared/Task';
import { copyWith } from '~/utils/copy';

export class ShopCategories {
  public readonly request = new Task(this.loadCategories.bind(this));

  private data: Category[] = [];

  constructor() {
    this.init();

    makeAutoObservable(this);
  }

  public static request(warehouse: string, serverLang?: string) {
    return CatalogRequests.getCategories(
      {
        warehouseCode: warehouse,
        landingPage: [LandingPage.SHOP],
        depth: 3,
      },
      serverLang,
    ).then(({ data }) => data);
  }

  public get list(): Readonly<Category[]> {
    return this.data;
  }

  get startShoppingCategory() {
    const category = this.data.find(({ name }) =>
      name.toLowerCase().includes('start shopping'),
    );

    if (!category) {
      return null;
    }

    return copyWith(category, {
      externalLink: '/shop',
      displayType: 'categories_grid_1',
    });
  }

  private async loadCategories(warehouse: string) {
    try {
      const data = await ShopCategories.request(warehouse);

      runInAction(() => {
        this.data = data
          .filter(Category.validate)
          .map((data) => new Category(data));
      });

      return data;
    } catch (error) {
      return mainStore.errorHandler(error as never, 'requestCategories');
    }
  }

  private init() {
    const data =
      typeof window !== 'undefined'
        ? window.__INITIAL_STATE__?.shopCategories || []
        : [];

    this.data = data
      .filter(Category.validate)
      .map((data) => new Category(data));
  }
}

export const shopCategories = new ShopCategories();
