import { isArray } from 'lodash-es';

import { BackorderLogic } from '~/api/Settings';
import { company } from '~/company/Company';
import { OFFERS_LIMIT } from '~/constants/variables';
import { orderStore } from '~/stores/OrderStore';
import { settingStore } from '~/stores/Settings';

import type { Offer, Product } from '~/stores/CategoriesStore';

export const useProductLimit = ({
  offer,
}: {
  offer?: Offer | Product | null;
}) => getProductLimit(offer);

export const getProductLimit = (offer?: Offer | Product | null) => {
  const isOriginalLogic =
    settingStore.settings.backorderLogic === BackorderLogic.ORIGINAL;

  if (!offer) {
    return {
      currentWhSellable: 0,
      parentWhSellable: 0,
      limit: 0,
    };
  }

  const isAvailableExpress = orderStore.isExpressAvailableNow;
  const whCode =
    orderStore.etaCalculation?.warehouse?.code ||
    company.config.warehouse.default;
  const sellableInWh =
    offer && offer?.sellableWarehouses && Object.keys(offer.sellableWarehouses);

  const parentExist = isArray(sellableInWh) && sellableInWh.length > 1;

  const sellableItemsByWh = isArray(sellableInWh)
    ? sellableInWh.reduce(
        (acc, key) => ({
          ...acc,
          [key === whCode ? 'current' : 'parent']:
            (Number(offer?.sellableWarehouses[key]) || 0) <= 0
              ? 0
              : offer.sellableWarehouses[key],
        }),
        { current: 0, parent: 0 },
      )
    : { current: sellableInWh, parent: 0 };

  const getLimitStockOnly = (): number => {
    if (!offer) {
      return 0;
    }

    if (isAvailableExpress) {
      return sellableItemsByWh.current > sellableItemsByWh.parent
        ? sellableItemsByWh.current
        : sellableItemsByWh.parent;
    }

    if (!parentExist) {
      return sellableItemsByWh.current;
    }

    return sellableItemsByWh.parent;
  };

  const getLimitOriginal = (): number => {
    if (!offer) {
      return 0;
    }

    if (offer.isBackorderAvailable) {
      return OFFERS_LIMIT;
    }

    if (offer.promoRequiredQuantity) {
      return Math.min(OFFERS_LIMIT, sellableItemsByWh.current || 0);
    }

    return sellableItemsByWh.current > OFFERS_LIMIT
      ? OFFERS_LIMIT
      : sellableItemsByWh.current;
  };

  const limit = isOriginalLogic ? getLimitOriginal() : getLimitStockOnly();

  const currentWhSellable = sellableItemsByWh.current ?? 0;

  const parrentSellable = parentExist
    ? sellableItemsByWh.parent ?? 0
    : sellableItemsByWh.current ?? 0;

  const parentWhSellable = isOriginalLogic
    ? getLimitOriginal()
    : parrentSellable;

  const laterCount = isOriginalLogic
    ? `${sellableItemsByWh.current}+`
    : parrentSellable;

  return {
    currentWhSellable,
    parentWhSellable,
    limit,
    laterCount,
  };
};
