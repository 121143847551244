import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { noop } from 'lodash-es';

import { config } from './configs';

const isSSR = typeof window === 'undefined';
const app = !isSSR && config ? initializeApp(config!) : null;

export const analytics = app ? getAnalytics(app) : null;

export const sendAnalytics: (
  ...args: Tail<Parameters<typeof logEvent>>
) => void = analytics ? (...args) => logEvent(analytics, ...args) : noop;
