import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { RecommendationSource } from '~/api/Catalog';
import { company } from '~/company/Company';
import { BundleBadge } from '~/components/BundleBadge';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import { CollapsibleList } from '~/components/CollapsibleList/CollapsibleList';
import CollapsibleText from '~/components/CollapsibleText/CollapsibleText';
import Counter from '~/components/Counter';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { MultibuyBadge } from '~/components/MultibuyBadge';
import Price from '~/components/Price';
import { formatVolume, pluralizePcLabel } from '~/components/ProductCard/utils';
import { ProductImageSlider } from '~/components/ProductImageSlider';
import { SliderLayout } from '~/components/ProductImageSlider/interfaces';
import Rating from '~/components/Rating';
import { Recommendations } from '~/components/Recommendations';
import { ReviewItem } from '~/components/ReviewItem/ReviewItem';
import Section from '~/components/Section';
import SkeletonListRows from '~/components/Skeleton/SkeletonList';
import Text from '~/components/Text';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import MobileHeaderLayout from '~/pages/Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '~/pages/Main/MobileHeader/MobileHeaderTitle';
import NotFound from '~/pages/NotFound/NotFound';
import { ProductViewProps } from '~/pages/Product/Product';
import styles from '~/pages/Product/Product.module.scss';
import { ProductBadges } from '~/pages/Product/ProductBadges';
import { catalogStore } from '~/stores/CatalogStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';
import { ProductReview } from '~/types/Product/interface';
import { formatPriceWithCurrency } from '~/utils/formaters';

export const ProductPageCD = observer(
  ({
    tabs,
    product,
    isExpress,
    deliveryToday,
    backorderDate,
    isBackorderAvailable,
    isBundle,
    isMultiBuy,
    volumes,
    currentItem,
    currentPrice,
    oldPrice,
    count,
    title,
    limit,
    tab,
    propertyList,
    similarProducts,
    matchingProducts,
    bundleImages,
    handleCopyCode,
    handleFavorite,
    addMultiBuyProductToCart,
    handleChangeSection,
    handleChangeCount,
    handleChangeOffer,
  }: ProductViewProps) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobal();
    const { pathname } = useLocation();
    const { openModal } = useModal();
    const mobileBottomContainer = useRef<HTMLDivElement | null>(null);
    const reviews = product.reviews;

    const bundleList =
      isBundle && !product.request.isLoading ? (
        <div className={styles.bundleList}>
          <p className={styles.bundleList_title}>{t('bundleList')}</p>
          <ul className={styles.bundleList_content}>
            {product.data?.offers.map((i) => {
              const measure = formatVolume(i.properties, {
                defaultVolume: '1x',
              });
              const price = i.discountPrice
                ? i.discountPriceFormatted
                : i.priceFormatted;
              const bundleCount = i.bundleQuantity ?? 1;

              return (
                <Link key={i.id} to={`/p/${i.productSlug}`}>
                  <li className={styles.bundleList_contentItem}>
                    <CloudflareResponseImage
                      src={i.previewImageThumb || ''}
                      srcParams={{ width: 100, height: 140 }}
                      srcSetParams={defaultSrcSetParams}
                      className={styles.bundleList_contentItem_image}
                      loading="lazy"
                    />
                    <div
                      className={
                        styles.bundleList_contentItem_contentDescriptionContainer
                      }
                    >
                      <div
                        className={
                          styles.bundleList_contentItem_contentDescription
                        }
                      >
                        <p className={styles.name}>{i.name}</p>
                        <p className={styles.measure}>
                          {formatPriceWithCurrency(price)}
                          {' • '}
                          {measure ?? '1x'}
                        </p>
                        <p className={styles.price}>
                          <span>
                            {formatPriceWithCurrency(
                              +price * (i.bundleQuantity ?? 1),
                            )}
                          </span>
                        </p>
                      </div>
                      <div
                        className={styles.bundleList_contentItem_contentCount}
                      >
                        <p>{bundleCount}x</p>
                      </div>
                    </div>
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      ) : null;

    const {
      message: expressDeliveryLabel,
      args: expressDeliveryLabelArgs,
      showFlashIcon,
    } = orderStore.getDeliveryLabel({ minLabel: t('min') });

    const recommendationsBlock = (
      <div
        style={
          {
            '--value': `${mobileBottomContainer?.current?.offsetHeight || 0}px`,
          } as CSSProperties
        }
        className={classes([
          'content-layout',
          (matchingProducts?.data.length ||
            similarProducts?.data.length ||
            catalogStore.productRecommendationsMeta.isLoading) &&
            styles.recommendationsWr,
        ])}
      >
        <Recommendations
          className="carousel-scroll1"
          source={RecommendationSource.CARD_ASSOCIATIONS}
        />
        <Recommendations
          className="carousel-scroll1"
          source={RecommendationSource.CARD_SIMILAR}
        />
      </div>
    );
    const offerBlock = product.selectedOffer && (
      <div
        ref={mobileBottomContainer}
        className="mobile-bottom-button-container _h"
      >
        <div>
          {isMultiBuy && (
            <div className={styles.multiBuyFromRule}>
              {t('phrases:fromPc', {
                length: pluralizePcLabel(
                  product.selectedOffer.promoRequiredQuantity,
                ),
              })}
            </div>
          )}
          <Price
            price={
              isMultiBuy
                ? product.selectedOffer.promoQuantityDiscountPriceFormatted
                : currentPrice ?? ''
            }
            discount={isMultiBuy ? '' : oldPrice ?? ''}
            classNameCurrentPrice={
              isMultiBuy ? styles.multiBuyMobileCurrentPrice : undefined
            }
          />
          {isMultiBuy && (
            <div className={styles.multiBuyActualPrice}>
              {formatPriceWithCurrency(currentPrice)}&nbsp;/&nbsp;1x
            </div>
          )}
        </div>
        {count > 0 ? (
          <Counter
            size="m"
            count={count}
            onClick={handleChangeCount}
            limit={limit}
          />
        ) : (
          <button
            type="button"
            className={classes([
              'button _primary _med',
              limit === 0 && '_disabled',
            ])}
            onClick={() => handleChangeCount(count + 1, 'add')}
          >
            {limit > 0 ? t('addToCart') : t('soldOut')}
          </button>
        )}
      </div>
    );
    const productInfo = (
      <div className="product-content">
        <div className={classes(['slider', styles.imageContainer])}>
          {!product.selectedOffer ? (
            <Skeleton className="slider-image" />
          ) : (
            <>
              <div className="slider__product-info">
                <div className="slider__product-info-bages">
                  {isExpress ? (
                    <div className="badge__container desktop-hide">
                      <Icon
                        type="express-fill"
                        size={16}
                        className="badge__icon"
                      />{' '}
                      {t('expressDelivery')}
                    </div>
                  ) : isBackorderAvailable ? (
                    <div className="badge__container desktop-hide">
                      {deliveryToday
                        ? t('deliveryTodayMobile')
                        : t('slotDeliveryInShort', { backorderDate })}
                    </div>
                  ) : null}
                </div>
                <div className="slider__product-info-actions">
                  <div className="slider__product-info-actions">
                    <button
                      className="button slider__product-info-actions-btn"
                      onClick={handleCopyCode}
                    >
                      <Icon size={24} type="share" />
                    </button>
                    <button
                      className="button slider__product-info-actions-btn"
                      onClick={handleFavorite}
                    >
                      <Icon
                        size={24}
                        type={
                          catalogStore.favorites[product.data?.id || '']
                            ? 'fav-fill'
                            : 'fav'
                        }
                        className={classes(
                          catalogStore.favorites[product.data?.id || ''] &&
                            '_fill',
                        )}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <ProductImageSlider
                productTitle={product.data?.name}
                images={isBundle ? bundleImages : product.selectedOffer.images}
                className={classes([
                  styles.swiperContainer,
                  isMultiBuy && styles.multiBuySlider,
                ])}
                layout={SliderLayout.MOBILE}
                badgesContent={
                  isMultiBuy && (
                    <MultibuyBadge
                      className={styles.multiBuyBadge}
                      value={product.selectedOffer?.promoRequiredQuantity}
                      onClick={addMultiBuyProductToCart}
                    />
                  )
                }
              />
              {isBundle && <BundleBadge className={styles.bundleBadge} />}
            </>
          )}
        </div>
        {title ? <h1 className="page-title">{title}</h1> : <Skeleton />}
        <div className="short-info">
          {product.data?.ratingAverage ? (
            <Rating rate={product.data.ratingAverage} starSize={13} showStars />
          ) : (
            <div className={styles.no_rating}>
              <Icon
                type="star-fill"
                size={16}
                className={styles.no_rating__star}
              />
              {t('noRatingShort')}
            </div>
          )}
          <span />
          {!product.selectedOffer ? (
            <Skeleton className="short-info__status-skeleton" />
          ) : (
            limit > 0 &&
            !!isExpress && (
              <p className="short-info__status-in-stock">{t('inStock')}</p>
            )
          )}
        </div>
        <div className={styles.volume}>
          {!product.selectedOffer ? (
            <SkeletonListRows rows={5}>
              <div
                className={classes([
                  styles.skeletonContainer,
                  styles.volumeBubble,
                ])}
              >
                <Skeleton
                  className={classes([styles.skeleton])}
                  containerClassName={classes([styles.skeleton])}
                />
              </div>
            </SkeletonListRows>
          ) : (
            !isBundle && (
              <Swiper
                spaceBetween={20}
                slidesPerGroup={4}
                slidesPerView="auto"
                className={styles.swiper}
                dir={userStore.dir}
                key={userStore.dir}
              >
                {volumes?.map((item) => (
                  <SwiperSlide className={styles.swiperItem} key={item.id}>
                    <div
                      key={item.id}
                      onClick={handleChangeOffer(item.id)}
                      className={classes([
                        styles.volumeBubble,
                        product.selectedOffer?.sku === item.id &&
                          styles.selected,
                      ])}
                    >
                      {item.value}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )
          )}
        </div>
        <ProductBadges data={product.selectedOffer?.badges} />
        {bundleList}
        <ul className="content__list">
          {currentItem?.description && (
            <li className="content__list-item product-property">
              <div className="product-property__item expendable">
                <CollapsibleText
                  className="description"
                  text={currentItem.description}
                  isLoading={product.request.isLoading}
                />
              </div>
            </li>
          )}
          {product.selectedOffer?.properties ? (
            <>
              {!isBundle && (
                <>
                  {product.selectedOffer.properties.producer && (
                    <li className="content__list-item product-property">
                      <div className="product-property__item">
                        <Text className="product-property__item-name">
                          {t('producer')}
                        </Text>
                        <Text className="product-property__item-value">
                          {product.selectedOffer.properties.producer.value}
                        </Text>
                      </div>
                      {/* <img alt='' src={product.producer?.logo} className='product-property__addition'/> */}
                    </li>
                  )}
                  {product.selectedOffer.properties.region && (
                    <li className="content__list-item product-property">
                      <div className="product-property__item">
                        <Text className="product-property__item-name">
                          {t('region')}
                        </Text>
                        <Text className="product-property__item-value">
                          {product.selectedOffer.properties.region.value}
                        </Text>
                      </div>
                      {/* <Text className="product-property__addition">{t('showOnMap')}</Text> */}
                    </li>
                  )}
                </>
              )}

              {recommendationsBlock}

              {reviews.list.length > 0 ? (
                <div
                  className={classes([
                    'content-layout',
                    styles.shortListContainer,
                  ])}
                >
                  <Section
                    title="Reviews"
                    subtitle={
                      <Rating
                        rate={product.data?.ratingAverage ?? 0}
                        starSize={16}
                        showStars={true}
                        classNameText="review-rating-text"
                        className="review-rating"
                      />
                    }
                    className={classes(['review-list', styles.shortList])}
                    headerClassName={styles.reviewSectionHeader}
                    horizontalHeader
                  >
                    <>
                      {userStore.isAuthorized && reviews.canCreateReview && (
                        <button
                          className={classes([
                            'button _primary',
                            styles.productInfoReviewsShortAddReviewButton,
                          ])}
                          onClick={() => {
                            catalogStore.feedbackProduct = product.data ?? null;
                            openModal(ModalType.Feedback);
                          }}
                        >
                          {t('writeReview')}
                        </button>
                      )}
                      {reviews.list.slice(0, 2).map((review: ProductReview) => (
                        <ReviewItem
                          key={review.id}
                          {...review}
                          isMobile={isMobile}
                        />
                      ))}
                      {reviews.list.length > 2 && (
                        <div className={styles.shortListButtonContainer}>
                          <button
                            className={classes([
                              'button _bordered',
                              styles.shortListButton,
                            ])}
                            onClick={() =>
                              handleChangeSection(tabs.reviews.alias)
                            }
                          >
                            {t('readMoreReviews')}{' '}
                            <Icon
                              type="chevron-2-fill"
                              className={styles.shortListButtonIcon}
                              size={16}
                            />
                          </button>
                        </div>
                      )}
                    </>
                  </Section>
                </div>
              ) : (
                <div
                  className={classes([
                    'content-layout',
                    styles.noReviewsContainer,
                  ])}
                >
                  <div className={styles.noReviews}>
                    {userStore.isAuthorized && reviews.canCreateReview ? (
                      <>
                        <div className={styles.noReviewsContent}>
                          <p className={styles.noReviewsContentTitle}>
                            {t('noReviewsYet')}
                          </p>
                          <Rating
                            rate={product.data?.ratingAverage ?? 0}
                            starSize={20}
                            hideText
                            showStars
                            type="star-fill"
                            classNameStart={styles.noReviewsStars}
                          />
                        </div>
                        <button
                          className={classes([
                            'button _primary',
                            styles.noReviewsButton,
                          ])}
                          onClick={() => {
                            catalogStore.feedbackProduct = product.data ?? null;
                            openModal(ModalType.Feedback);
                          }}
                        >
                          {t('writeReview')}
                        </button>
                      </>
                    ) : (
                      <div className={styles.noReviewsContent}>
                        <p className={styles.noReviewsContentTitle}>
                          {t('noRating')}
                        </p>
                        <Rating
                          rate={product.data?.ratingAverage ?? 0}
                          starSize={20}
                          hideText
                          showStars
                          type="star-fill"
                          classNameStart={styles.noReviewsStars}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <SkeletonListRows rows={5}>
              <li className="content__list-item product-property">
                <div className="product-property__item">
                  <Text className="product-property__item-name">
                    <Skeleton width="100px" />
                  </Text>
                  <Text className="product-property__item-value">
                    <Skeleton width="100px" />
                  </Text>
                </div>
              </li>
            </SkeletonListRows>
          )}

          {/* <li className='content__list-item product-property'>
          <Section
            title="Reviews"
            subtitle={
              <Rating rate={product.rating.rate} count={product.rating.count} starSize={13} showStars={true} />}
            className="review-list"
            horizontalHeader
          >
            {product.reviews.map(i => (
              <Tile key={i.id} className="review">
                <div className="review-header">
                  <div className="review-header-info">
                    <div className="review-header-info__name">
                      <p>{i.person}</p>
                      <Icon type="badge" size={24} className="icon" />
                    </div>
                    <p className="review-header-info__date">{i.date}</p>
                  </div>
                  <Rating rate={i.rate} showStars={true} hideText={true} />
                </div>
                <p className="review-content">{i.comment}</p>
                <div className="review-footer">
                  <div className="review-footer__stat-item">
                    <Icon type={i.stat.vote && i.stat.vote > 0 ? 'like-fill' : 'like'} size={24} />
                    {i.stat.minus > 0 && <div className="review-footer__stat-item-count">
                      {i.stat.minus}
                    </div>}
                  </div>
                  <div className="review-footer__stat-item">
                    <Icon type={i.stat.vote && i.stat.vote < 0 ? 'like-fill' : 'like'} className="icon__rotate-180" size={24} />
                    {i.stat.plus > 0 && <div className="review-footer__stat-item-count">
                      {i.stat.plus}
                    </div>}
                  </div>
                </div>
              </Tile>
            ))}
          </Section>
        </li> */}
          {/* <li className="content__list-item">
          <Link to="#" className="product-property">
            <Text size="14" weight="700">
              More Sparkling Wines
            </Text>
            <Icon type="chevron" size={24} className="icon__rotate-270" />
          </Link>
        </li>
        <li className="content__list-item">
          <Link to="#" className="product-property">
            <Text size="14" weight="700">
              Show similar products
            </Text>
            <Icon type="chevron" size={24} className="icon__rotate-270" />
          </Link>
        </li> */}
        </ul>
        {offerBlock}
        {/* <CarouselContainer title="Matching products" linkText="View all" linkTo="#" />
      <CarouselContainer title="Recently Viewed" linkText="View all" linkTo="#" /> */}
      </div>
    );

    const productInfoReviews = (
      <div
        className={classes([
          'content-layout',
          styles.shortListContainer,
          styles.desktop,
        ])}
      >
        <Section
          title={t('reviews')}
          className={classes(['review-list', styles.shortList])}
          headerClassName={styles.reviewSectionHeader}
          horizontalHeader
        >
          {reviews.list.length > 0 ? (
            <div className={styles.productInfoReviewsContainer}>
              <div className={styles.productInfoReviewsShort}>
                <Rating
                  rate={product.data?.ratingAverage ?? 0}
                  starSize={20}
                  showStars={true}
                  classNameText="review-rating-text"
                  className="review-rating"
                />
                {userStore.isAuthorized && reviews.canCreateReview && (
                  <div className={styles.productInfoReviewsShortAddReview}>
                    <p className={styles.productInfoReviewsShortAddReviewText}>
                      {t('phrases:forReview')}
                    </p>
                    <button
                      className={classes([
                        'button _primary',
                        styles.productInfoReviewsShortAddReviewButton,
                      ])}
                      onClick={() => {
                        catalogStore.feedbackProduct = product.data ?? null;
                        openModal(ModalType.Feedback);
                      }}
                    >
                      {t('writeReview')}
                    </button>
                  </div>
                )}
              </div>
              <div className={styles.productInfoReviewsContent}>
                {reviews.list.length > 0 && (
                  <div className={styles.reviewListContent}>
                    <CollapsibleList
                      sublistClassName={styles.reviewListContent}
                      itemsToShow={reviews.list
                        .slice(0, 4)
                        .map((review: ProductReview) => (
                          <ReviewItem key={review.id} {...review} />
                        ))}
                      itemsToHide={reviews.list
                        .slice(4)
                        .map((review: ProductReview) => (
                          <ReviewItem key={review.id} {...review} />
                        ))}
                      showMoreText={t('readMoreReviews')}
                      showLessText={t('lessReviews')}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.noReviews}>
              <Rating
                rate={product.data?.ratingAverage ?? 0}
                starSize={24}
                hideText
                showStars
                type="star-fill"
                classNameStart={styles.noReviewsStars}
              />
              {userStore.isAuthorized && reviews.canCreateReview ? (
                <>
                  <div className={styles.noReviewsContent}>
                    <p className={styles.noReviewsContentTitle}>
                      {t('noReviewsYet')}
                    </p>
                    <p className={styles.noReviewsContentDescription}>
                      {t('phrases:noRatingTellUs')}
                    </p>
                  </div>
                  <button
                    className={classes([
                      'button _primary',
                      styles.noReviewsButton,
                    ])}
                    onClick={() => {
                      catalogStore.feedbackProduct = product.data ?? null;
                      openModal(ModalType.Feedback);
                    }}
                  >
                    {t('writeReview')}
                  </button>
                </>
              ) : (
                <div className={styles.noReviewsContent}>
                  <p className={styles.noReviewsContentTitle}>
                    {t('noRating')}
                  </p>
                  <p className={styles.noReviewsContentDescription}>
                    {t('phrases:noRewiewsTellUs')}
                  </p>
                </div>
              )}
            </div>
          )}
        </Section>
      </div>
    );

    const productReviewMobile = (
      <div className="product-content">
        <Section
          title={t('reviews')}
          subtitle={
            <Rating
              rate={product.data?.ratingAverage ?? 0}
              starSize={16}
              showStars={true}
              classNameText="review-rating-text"
              className="review-rating"
            />
          }
          className="review-list"
          headerClassName={styles.reviewSectionHeader}
          horizontalHeader
        >
          <>
            {userStore.isAuthorized && reviews.canCreateReview && (
              <button
                className={classes([
                  'button _primary',
                  styles.productInfoReviewsShortAddReviewButton,
                ])}
                onClick={() => {
                  catalogStore.feedbackProduct = product.data ?? null;
                  openModal(ModalType.Feedback);
                }}
              >
                {t('writeReview')}
              </button>
            )}
            {reviews.list.map((review: ProductReview) => (
              <ReviewItem key={review.id} {...review} isMobile={isMobile} />
            ))}
          </>
        </Section>
        {offerBlock}
      </div>
    );

    const productCharacteristics = (
      <div className="product-content characteristic">
        <Section>
          {/* <div className="characteristic-line">
          <div className="characteristic-line__item">
            <p className="characteristic-line__item-text">Light</p>
          </div>
          <div className="characteristic-line__item selected">
            <p className="characteristic-line__item-text">Medium</p>
          </div>
          <div className="characteristic-line__item">
            <p className="characteristic-line__item-text">Full</p>
          </div>
        </div>
        <div className="characteristic-line">
          <div className="characteristic-line__item">
            <p className="characteristic-line__item-text">Dry</p>
          </div>
          <div className="characteristic-line__item">
            <p className="characteristic-line__item-text">Semi-Sweet</p>
          </div>
          <div className="characteristic-line__item selected">
            <p className="characteristic-line__item-text">Sweet</p>
          </div>
        </div> */}
          <ul className="characteristic-list">
            {propertyList.map((prop) => (
              <li className="characteristic-list-item" key={prop.name}>
                <div className="characteristic-list-item__name">
                  <p className="">{prop.name}</p>
                </div>
                <div className="characteristic-list-item__value">
                  <p className="">{prop.value}</p>
                </div>
              </li>
            ))}
          </ul>
          {currentItem?.description && (
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: currentItem.description }}
            />
          )}
        </Section>
        {offerBlock}
      </div>
    );

    const getMobileBody = () => {
      if (isBundle && tab?.alias === tabs.characteristics.alias) {
        handleChangeSection(tabs.product.alias);
      }

      switch (tab?.alias) {
        case tabs.characteristics.alias:
          return productCharacteristics;
        case tabs.reviews.alias:
          return productReviewMobile;
        default:
        case tabs.product.alias:
          return productInfo;
      }
    };

    if (product.notFound) {
      return <NotFound />;
    }

    const pageTitle =
      product.selectedOffer?.metadataTitle || product.data?.metadataTitle || '';
    const pageDescription =
      product.selectedOffer?.metadataDescription ||
      product.data?.metadataDescription ||
      '';
    const canonical = company.config.canonical + (pathname ?? '');

    return (
      <>
        <Helmet>
          <title>
            {pageTitle && `${pageTitle} - `}
            {company.config.name}
          </title>
          <meta name="description" content={pageDescription} />
          <meta
            data-react-helmet="true"
            name="robots"
            content="index, follow"
          />
          <link data-react-helmet="true" rel="canonical" href={canonical} />
        </Helmet>
        {/* TODO: correct link */}
        <MobileHeaderLayout
          showCart
          showSearch
          content={
            <MobileHeaderTitle text={product.data?.categoryName || ''} />
          }
        />
        <div className="content-layout product-mobile-layout">
          <div className="tab-filter content-type">
            <div className="tab-filter__inner">
              {Object.values(tabs)
                .filter(({ alias }) => {
                  if (!isBundle) {
                    return true;
                  }
                  return alias !== tabs.characteristics.alias;
                })
                .map((i) => (
                  <Link
                    to="#"
                    key={i.alias}
                    onClick={() => handleChangeSection(i.alias)}
                  >
                    <div
                      className={classes([
                        'tab-filter__tab',
                        i.alias === tab?.alias && 'tab__selected',
                      ])}
                    >
                      <Text size="14" weight="700" className="tab__inner">
                        {i.title}
                      </Text>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
          {getMobileBody()}
        </div>
        <div className="content-layout product-desktop-layout grid-product">
          <div className={classes(['slider', styles.imageContainer])}>
            <div className="slider__product-info">
              <div className="slider__product-info-bages">
                {!isBundle &&
                  product.selectedOffer?.properties?.express_delivery
                    ?.value && (
                    <div className="badge__container">
                      <Icon
                        type="express-fill"
                        size={16}
                        className="badge__icon"
                      />{' '}
                      {t('expressDelivery')}
                    </div>
                  )}
              </div>
            </div>
            {product.selectedOffer && !isMobile ? (
              <ProductImageSlider
                productTitle={product.data?.name}
                images={isBundle ? bundleImages : product.selectedOffer.images}
                className={classes([
                  styles.swiperContainer,
                  isMultiBuy && styles.multiBuySlider,
                ])}
                layout={SliderLayout.DESKTOP}
                badgesContent={
                  <>
                    {isMultiBuy && (
                      <MultibuyBadge
                        className={styles.multiBuyBadge}
                        value={currentItem?.promoRequiredQuantity}
                        onClick={addMultiBuyProductToCart}
                      />
                    )}
                    {isBundle && <BundleBadge className={styles.bundleBadge} />}
                  </>
                }
              />
            ) : (
              <Skeleton className="skeleton" height="60%" />
            )}
          </div>
          <div className="product-info">
            <h1 className="product-title title">
              {title ? title : <Skeleton />}
            </h1>
            {product.data && (
              <div className="rating-container">
                {product.data?.ratingAverage ? (
                  <>
                    <div className="rating-rate">
                      {product.data.ratingAverage}
                    </div>
                    <Rating
                      rate={product.data.ratingAverage}
                      showStars={true}
                      hideText={true}
                      starSize={16}
                    />
                    {/* <div className="rating-count">
                    {productState?.ratingMarksCount}
                  </div> */}
                  </>
                ) : (
                  <div className={styles.no_rating}>
                    <Icon
                      type="star-fill"
                      size={16}
                      className={styles.no_rating__star}
                    />
                    {t('noRatingShort')}
                  </div>
                )}
              </div>
            )}
            {product.selectedOffer ? (
              !isBundle && (
                <div className={styles.volume}>
                  <Swiper
                    spaceBetween={20}
                    slidesPerGroup={4}
                    slidesPerView="auto"
                    className={styles.swiper}
                    dir={userStore.dir}
                    key={userStore.dir}
                  >
                    {volumes?.map((item) => (
                      <SwiperSlide className={styles.swiperItem} key={item.id}>
                        <div
                          key={item.id}
                          onClick={handleChangeOffer(item.id)}
                          className={classes([
                            styles.volumeBubble,
                            product.selectedOffer?.sku === item.id &&
                              styles.selected,
                          ])}
                        >
                          {item.value}
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )
            ) : (
              <div className={styles.volume_skeleton}>
                <Skeleton width="100%" className={styles.skeleton} />
              </div>
            )}
            {product.selectedOffer ? (
              <div className="price-info">
                <div className="price-info-container">
                  <div className="price-info-container__price">
                    <div>
                      {isMultiBuy && (
                        <div className={styles.multiBuyFromRule}>
                          {t('phrases:fromPc', {
                            length: pluralizePcLabel(
                              product.selectedOffer.promoRequiredQuantity,
                            ),
                          })}
                        </div>
                      )}
                      <p
                        className={classes([
                          'price-info__current',
                          (oldPrice || isMultiBuy) && '_with-discount',
                        ])}
                      >
                        {formatPriceWithCurrency(
                          isMultiBuy
                            ? product.selectedOffer
                                .promoQuantityDiscountPriceFormatted
                            : currentPrice ?? 0,
                        )}
                      </p>
                      {isMultiBuy && (
                        <p className={styles.multiBuyActualPrice}>
                          {formatPriceWithCurrency(currentPrice ?? 0)}
                          &nbsp;/&nbsp;1x
                        </p>
                      )}
                    </div>
                    {oldPrice && (
                      <p className="price-info__old">
                        {formatPriceWithCurrency(oldPrice)}
                      </p>
                    )}
                  </div>
                  {limit > 0 && !!isExpress && (
                    <p className="price-info__status-in-stock">
                      {t('inStock')}
                    </p>
                  )}
                </div>
                <div className="price-info-buttons">
                  {count > 0 ? (
                    <div className="counter-container">
                      <Icon
                        type="check-round"
                        size={36}
                        className="counter-container__icon"
                      />
                      <p className="counter-container__text">
                        {t('addedToCart')}
                      </p>
                      <Counter
                        size="m"
                        count={count}
                        onClick={handleChangeCount}
                        limit={limit}
                      />
                    </div>
                  ) : (
                    <button
                      className={classes([
                        'button _primary _med price-info-buttons__cta',
                        limit === 0 && '_disabled',
                      ])}
                      onClick={() => handleChangeCount(count + 1, 'add')}
                    >
                      {limit > 0 ? t('addToCart') : t('soldOut')}
                    </button>
                  )}
                  <button
                    className="button _med price-info-buttons__actions-btn"
                    onClick={handleFavorite}
                  >
                    <Icon
                      size={24}
                      type={
                        catalogStore.favorites[product.data?.id || '']
                          ? 'fav-fill'
                          : 'fav'
                      }
                      className={classes(
                        catalogStore.favorites[product.data?.id || ''] &&
                          '_fill',
                      )}
                    />
                  </button>
                  <button
                    className="button _med price-info-buttons__actions-btn"
                    onClick={handleCopyCode}
                  >
                    <Icon size={24} type="share" />
                  </button>
                </div>
                <div className="price-info__bages">
                  {isExpress ? (
                    <div className="badge__container">
                      {showFlashIcon && (
                        <Icon
                          type="express-fill"
                          size={16}
                          className="badge__icon"
                        />
                      )}{' '}
                      {t(expressDeliveryLabel, expressDeliveryLabelArgs)}
                    </div>
                  ) : isBackorderAvailable ? (
                    <div className={styles.backorder_date}>
                      <Icon
                        type="calendar-2"
                        size={20}
                        className={styles.backorder_date__icon}
                      />
                      {deliveryToday
                        ? t('deliveryToday')
                        : t('slotDeliveryFrom', { backorderDate })}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="price-info _skeleton">
                <Skeleton
                  className="skeleton"
                  containerClassName="skeleton-container"
                />
              </div>
            )}
            <ProductBadges data={product.selectedOffer?.badges} />
            {bundleList}
            {currentItem?.description && (
              <CollapsibleText
                className="description"
                text={currentItem.description}
                isLoading={product.request.isLoading}
              />
            )}
            {!isBundle && (
              <ul className="characteristic-list">
                {propertyList.map((prop) => (
                  <li className="characteristic-list-item" key={prop.name}>
                    <div className="characteristic-list-item__name">
                      <p className="">{prop.name}</p>
                    </div>
                    <div className="characteristic-list-item__value">
                      <p className="">{prop.value}</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {productInfoReviews}
        {!isMobile && recommendationsBlock}
      </>
    );
  },
);
