import 'swiper/css';
import 'swiper/css/pagination';

import { classes } from 'html-classes';
import { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { userStore } from '~/stores/UserStore';

import SwiperIcon from '../../Swiper/SwiperIcon';

import { createCategoryUrl } from '../utils';

import styles from './BannerMainBlock.module.scss';

interface Banner {
  src: string;
  alt: string;
  linkId: string | number;
}

interface BannerMainBlockProps {
  banners: Banner[];
  isMobile: boolean;
}

const BannerMainBlock = ({ banners, isMobile }: BannerMainBlockProps) => {
  const { handleClickCategory } = useCategoryAnalytics();
  const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);

  const isPrevHidden = isMobile || activeSwiperIndex === 0;
  const isNextHidden = isMobile || activeSwiperIndex === banners.length - 1;

  const onClickNext = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const onClickPrev = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  if (!banners || banners.length === 0) {
    return (
      <div className={classes([styles.container, styles.skeleton])}>
        <div className={styles.skeleton__container}>
          <Skeleton className={classes([styles.skeleton__item])} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes([styles.container, 'banner-carousel carousel'])}>
      <SwiperIcon
        hide={isPrevHidden}
        position="left"
        variant="fill"
        onClick={onClickPrev}
      />
      <Swiper
        ref={swiperRef}
        spaceBetween={isMobile ? 6 : 10}
        slidesPerView={1}
        pagination={{ enabled: true, horizontalClass: styles.pagination }}
        modules={[Pagination]}
        onSlideChange={(swiper) => setActiveSwiperIndex(swiper.activeIndex)}
        className={styles.swiper}
        dir={userStore.dir}
        key={userStore.dir}
      >
        {banners.map(({ src, alt, linkId }) => (
          <SwiperSlide className="banner-carousel__item" key={src}>
            <Link
              onClick={handleClickCategory(linkId)}
              to={createCategoryUrl({ id: linkId })}
            >
              <CloudflareResponseImage
                src={src}
                //srcParams={{ width: 96 }}
                srcSetParams={defaultSrcSetParams}
                alt={alt}
                loading="lazy"
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperIcon
        hide={isNextHidden}
        position="right"
        variant="fill"
        onClick={onClickNext}
      />
    </div>
  );
};

export default BannerMainBlock;
