import { Locale, isToday, isTomorrow } from 'date-fns';
import { format } from 'date-fns-tz';
import { t } from 'i18next';

import { DeliverySlot } from '~/api/ETA';
import { company } from '~/company/Company';

export const getSelectedDay = (slot: DeliverySlot, locale?: Locale): string => {
  const selectedTime = slot
    ? `${format(new Date(slot.delivery_min_time * 1000), 'hh:mm aaaa', {
        timeZone: company.config.timeZone,
        locale,
      })}
    -
    ${format(new Date(slot.delivery_max_time * 1000), 'hh:mm aaaa', {
      timeZone: company.config.timeZone,
      locale,
    })}`
    : '';

  let selectedDay =
    slot &&
    `${format(new Date(+slot.current_date * 1000), 'd MMMM', {
      timeZone: company.config.timeZone,
      locale,
    })} ${selectedTime}`;

  if (slot && isToday(new Date(+slot.current_date * 1000))) {
    selectedDay = `${t('today')} ${selectedTime}`;
  } else if (slot && isTomorrow(new Date(+slot.current_date * 1000))) {
    selectedDay = `${t('tomorrow')} ${selectedTime}`;
  }

  return selectedDay;
};
