import {
  ProductPropertiesKeyAPI,
  ProductPropertiesAPI,
  ProductPropertiesTypeAPI,
  Variant,
} from '~/api/Catalog';
import { CartItem } from '~/stores/CatalogStore';
import { Offer, Product } from '~/stores/CategoriesStore';
import { mainStore } from '~/stores/MainStore';

import { ProductProperties } from './interface';

export function isProductBundle(item: Offer | Product): item is Product {
  return Boolean((item as Product).productType === 'bundle');
}

/**
 * Precalculate some fields
 * */
export function calcProductOfferFields<T extends Offer | CartItem>(
  offer: Mutable<T>,
): T {
  offer.priceFormatted = mainStore.convertPenceToPounds(offer.price);
  offer.discountPriceFormatted = mainStore.convertPenceToPounds(
    offer.discountPrice,
  );
  offer.promoQuantityDiscountPriceFormatted = mainStore.convertPenceToPounds(
    offer.promoQuantityDiscountPrice,
  );
  if (offer.discountPrice) {
    const discount = mainStore.calcDiscountPercentage(
      offer.price,
      offer.discountPrice,
    );
    offer.discountPercentage = discount ? `-${discount}%` : '';
  } else {
    offer.discountPercentage = '';
  }
  if (!offer.pricePerUnit && offer.properties) {
    const { product_size, uom } = offer.properties;
    const items_per_outer_retail_pack = parseInt(
      (offer.properties.items_per_outer_retail_pack || '0') as string,
      10,
    );
    if (!items_per_outer_retail_pack || !product_size || !uom) {
      offer.pricePerUnit = '1x';
    } else {
      offer.pricePerUnit =
        items_per_outer_retail_pack === 1
          ? ''
          : `${items_per_outer_retail_pack}×`;
      offer.pricePerUnit += `${product_size}${
        (uom.value as string).includes('pack') ? ' ' : ''
      }${uom}`;
    }
  }
  /*if (offer.bundle_products && offer.bundle_products.length) {
    offer.bundle_products = offer.bundle_products.map(
      product => this.calcProductFields(product));
  }*/
  return offer;
}

export function parseVariants(data: Variant[]): ProductProperties {
  return data.reduce((properties, { value, property }, order) => {
    properties[property as ProductPropertiesKeyAPI] = {
      order,
      value,
      type: '',
      name: '',
    };

    return properties;
  }, {} as ProductProperties);
}

/**
 * Parse formatted properties fields
 * */
export function parseProperties(
  props: ProductPropertiesAPI[],
): ProductProperties {
  const properties: ProductProperties = {};

  if (!props.length) {
    return properties;
  }

  const types: ProductPropertiesTypeAPI[] = [
    'checkboxGroup',
    'radioGroup',
    'selector',
    'tagList',
  ];
  for (let order = 0; order < props.length; order++) {
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    const { code, value, values = '', type, name } = props[order];
    let val: string | number | boolean | string[] = value ?? '';
    if (type === 'toggle' && typeof value === 'string') {
      val = value === 'true';
    }
    if (types.includes(type)) {
      val = values;
    }
    properties[code as ProductPropertiesKeyAPI] = {
      name,
      value: val,
      order,
      type,
    };
  }

  return properties;
}
