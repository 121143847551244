import { OrderPublicStatus } from '~/api/Order';

export const DELIVERY_INDICATORS: OrderPublicStatus[] = [
  'accepted',
  'picking',
  'ready_to_ship',
  'in_delivery',
];

export const TRACKING_MAP_API_URL = import.meta.env.SSR
  ? '/'
  : import.meta.env.REACT_APP_TRACKING_MAP_API_URL ||
    window.location.origin.replace(
      'frontend-cd-',
      'frontend-courier-tracking-',
    ) + '/';
