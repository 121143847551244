import { observer } from 'mobx-react-lite';
import {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { EnvConfig } from '~/constants/envConfig';
import { useModal } from '~/hooks/useModal';
import CardSelector from '~/pages/Checkout/CardSelector';
import { awsPaymentStore } from '~/stores/AwsPaymentStore';
import { catalogStore } from '~/stores/CatalogStore/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';
import { clearStoredPromocode, getStoredPromocode } from '~/utils/referralLink';

import { ModalType } from '../Modal/interface';
import Price from '../Price';

import styles from './AwsPayment.module.scss';

let timeout: ReturnType<typeof setTimeout>;

type AwsPaymentProps = {
  isVisibleSubmitButton?: boolean;
  isDisabledSubmit?: boolean;
};

export type AwsPaymentRef = {
  handleSubmitForm: () => void;
};

const AwsPayment = forwardRef<AwsPaymentRef, AwsPaymentProps>(
  ({ isVisibleSubmitButton, isDisabledSubmit }, ref) => {
    const { t } = useTranslation();
    const [isFormSubmited, setIsFormSubmited] = useState(false);
    const { openModal, closeModal } = useModal();
    const [searchParams] = useSearchParams();

    const formRef = useRef<HTMLFormElement>(null);
    const isLoading =
      checkoutStore.isLoading ||
      orderStore.isLoading ||
      awsPaymentStore.isIframeLoading;

    const requestData = () => {
      const data = {
        service_command: 'TOKENIZATION',
        return_url: EnvConfig.AWS_PAY_RETURN_URL,
        access_code: EnvConfig.AWS_PAY_ACCESS_CODE,
        merchant_identifier: EnvConfig.MERCHANT_IDENTIFIER,
        token_name: checkoutStore.currentSelectedMethod?.paymentToken,
        merchant_reference:
          checkoutStore.paymentIntentMultiorder?.id ||
          checkoutStore.paymentIntent?.order?.id ||
          '',
        language: userStore.lang,
      };

      if (
        checkoutStore.isAddNewCard ||
        !checkoutStore.currentSelectedMethod?.paymentToken
      ) {
        delete data.token_name;
      } else {
        data.token_name = checkoutStore.currentSelectedMethod?.paymentToken;
      }

      return data;
    };

    const dataToSend = requestData();

    const signature = awsPaymentStore.generateString(dataToSend);

    useImperativeHandle(
      ref,
      () => {
        return {
          handleSubmitForm: handleSubmit,
        };
      },
      [],
    );

    useEffect(() => {
      if (orderStore.url3Ds && searchParams.get('modal')) {
        const orderId =
          checkoutStore.paymentIntentMultiorder?.id ||
          checkoutStore.paymentIntent?.order?.id ||
          '';
        check3dsStatusWithRetries(orderId, 10000, true);
      }
    }, [orderStore.url3Ds, searchParams.get('modal')]);

    useEffect(() => {
      const messageListenerHandler = (event: MessageEvent<any>) => {
        if (
          event.data.type === 'backendResponse' &&
          !awsPaymentStore.messageReceived
        ) {
          if (!orderStore.url3Ds) {
            const orderId =
              checkoutStore.paymentIntentMultiorder?.id ||
              checkoutStore.paymentIntent?.order?.id ||
              '';
            check3dsStatusWithRetries(orderId, 1000);
          }

          awsPaymentStore.setPostMessageReceived(event.data.type);

          awsPaymentStore.setHeight(0);

          awsPaymentStore.setIsActive(false);
        }
      };

      window.addEventListener('message', messageListenerHandler);

      return () => {
        window.removeEventListener('message', messageListenerHandler);
        clearState();
      };
    }, [
      checkoutStore.activePaymentMethod,
      checkoutStore.isAddNewCard,
      checkoutStore.paymentsMethodId,
      checkoutStore.paymentIntentMultiorder?.id,
      checkoutStore.paymentIntent?.order?.id,
    ]);

    const finalizePayment = async () => {
      await checkoutStore.finalizePayment();

      awsPaymentStore.setPostMessageReceived(false);

      const storedPromocode = getStoredPromocode();

      if (
        catalogStore.promocode.success &&
        catalogStore.promocode.value === storedPromocode?.code
      ) {
        clearStoredPromocode();
      }
    };

    const handleSubmit = async () => {
      try {
        clearState();

        await checkoutStore.orderCheckout();

        if (checkoutStore.isAddNewCard) {
          awsPaymentStore.setIsIframeLoading(true);

          timeout = setTimeout(() => {
            formRef?.current?.submit();
            clearTimeout(timeout);
          }, 400);
        } else {
          const orderId =
            checkoutStore.paymentIntentMultiorder?.id ||
            checkoutStore.paymentIntent?.order?.id ||
            '';
          check3dsStatusWithRetries(orderId, 20);
        }
        setIsFormSubmited(true);
      } catch (e) {
        console.error(e);
      }
    };

    const check3dsStatusWithRetries = async (
      orderId: string,
      iterations: number,
      avoidCheckingUrl = false,
    ) => {
      for (let i = 0; i < iterations; i++) {
        if (!window.location.href.includes('checkout')) {
          break;
        }

        if (avoidCheckingUrl && !window.location.href.includes('modal')) {
          break;
        }

        try {
          awsPaymentStore.setIsIframeLoading(true);
          const res = await orderStore.check3dsStatus(orderId);

          if (
            res['3ds_url'] &&
            res.status === 'waiting_3ds' &&
            !avoidCheckingUrl
          ) {
            setIsFormSubmited(false);
            openModal(ModalType.Check3Ds);
            break;
          }

          if (res.status === 'created' && i > 10) {
            awsPaymentStore.setIsIframeLoading(false);
            orderStore.set3dsUrl('');
            break;
          }

          if (res.status === 'charged') {
            orderStore.set3dsUrl('');
            closeModal();
            finalizePayment();
            awsPaymentStore.setIsIframeLoading(false);
            break;
          }

          if (res.status === 'cancelled') {
            orderStore.set3dsUrl('');
            closeModal();
            awsPaymentStore.setIsIframeLoading(false);
            break;
          }
        } catch (error) {
          orderStore.set3dsUrl('');
          closeModal();
          awsPaymentStore.setIsIframeLoading(false);
          console.error(`Error checking 3DS status: ${error}`);
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, 1300));
      }
    };

    const handleIframeLoaded = () => {
      if (
        awsPaymentStore.messageReceived ||
        awsPaymentStore.heightIframe ||
        !isFormSubmited
      ) {
        return;
      }

      awsPaymentStore.setHeight(350);

      awsPaymentStore.setIsActive(true);

      awsPaymentStore.setIsIframeLoading(false);
    };

    const handleAgreeChange = () =>
      checkoutStore.setAgreeSaveCard(!checkoutStore.agreeSaveCard);

    const clearState = () => {
      awsPaymentStore.setHeight(0);
      setIsFormSubmited(false);
      awsPaymentStore.setPostMessageReceived(false);
      orderStore.set3dsUrl('');
      awsPaymentStore.setIsActive(false);
      awsPaymentStore.setIsIframeLoading(false);
    };

    return (
      <>
        <CardSelector headerClasses={styles.paymentDetailsContainer} />
        <div className={styles.container}>
          {checkoutStore.isAddNewCard && (
            <label className="control__checkbox">
              <input
                type="checkbox"
                className="control__checkbox-input"
                checked={checkoutStore.agreeSaveCard}
                onChange={handleAgreeChange}
              />
              <span className="control__checkbox-mark" />
              {t('phrases:rememberMyCard')}
            </label>
          )}
          <form
            ref={formRef}
            method="post"
            target="awsPayIframe"
            action={EnvConfig.AWS_PAY_BASE_URL}
          >
            <input
              type="hidden"
              name="service_command"
              value={dataToSend.service_command}
            />
            <input type="hidden" name="language" value={dataToSend.language} />
            <input
              type="hidden"
              name="merchant_identifier"
              value={dataToSend.merchant_identifier}
            />
            <input
              type="hidden"
              name="access_code"
              value={dataToSend.access_code}
            />
            <input type="hidden" name="signature" value={signature} />
            <input
              type="hidden"
              name="return_url"
              value={dataToSend.return_url}
            />
            <input
              type="hidden"
              name="merchant_reference"
              value={dataToSend.merchant_reference}
            />
            {!checkoutStore.isAddNewCard && dataToSend.token_name && (
              <input
                type="hidden"
                name="token_name"
                value={dataToSend.token_name}
              />
            )}
          </form>
          <div className={styles.iframeContainer}>
            <iframe
              name="awsPayIframe"
              frameBorder="0"
              border="0"
              cellSpacing="0"
              onLoad={handleIframeLoaded}
              width="100%"
              height={awsPaymentStore.heightIframe}
            />
          </div>
          {!awsPaymentStore.isActiveAwsIframe && isVisibleSubmitButton && (
            <div className={styles.buttonContainer}>
              <button
                className="button _primary"
                disabled={isDisabledSubmit}
                onClick={handleSubmit}
              >
                {checkoutStore?.payments?.length &&
                checkoutStore?.payments?.length > 0
                  ? t('confirmOrder')
                  : t('addNewCard')}
                {isLoading ? (
                  <span className="spinner _white" />
                ) : (
                  checkoutStore?.payments?.length && (
                    <Price price={catalogStore.finalPrice} />
                  )
                )}
              </button>
            </div>
          )}
        </div>
      </>
    );
  },
);

AwsPayment.displayName = 'AwsPayment';

export default observer(AwsPayment);
