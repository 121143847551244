import { zodResolver } from '@hookform/resolvers/zod';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useGlobal } from '~/hooks/useGlobal';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { textRegex } from '~/utils/regexp';

import { useModal } from '../../hooks/useModal';
import { userStore } from '../../stores/UserStore';

import Icon from '../Icon/Icon';
import InputText from '../InputText/InputText';

import styles from './ModalCheckoutReciever.module.scss';

const ModalCheckoutReciever = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const backHandler = () => closeModal();
  const { isMobile } = useGlobal();

  useEffect(() => {
    setValue('name', checkoutStore.nameVal ?? '');
    setValue('email', checkoutStore.emailVal ?? '');
  }, []);

  const RecieverValidationSchema = z.object({
    name: z
      .string()
      .min(1, { message: t('pleaseEnterRecieversName') })
      .regex(textRegex, { message: t('invalidCharacters') })
      .default(''),

    email: z
      .string()
      .min(1, { message: t('pleaseEnterEmail') })
      .email({ message: t('incorrectEmail') })
      .default(''),
  });

  type RecieverSchema = z.infer<typeof RecieverValidationSchema>;

  useEffect(() => {
    setValue('name', checkoutStore.nameVal ?? '');
    setValue('email', checkoutStore.emailVal ?? '');
  }, [checkoutStore.nameVal, checkoutStore.emailVal]);

  const handleForm = async (data: RecieverSchema) => {
    const emailChanged = await userStore.updatePersonalEmail(data.email.trim());
    checkoutStore.setIsEmailSync(emailChanged);
    checkoutStore.setIsPersonalDataSync(true);

    if (!emailChanged) {
      return;
    }

    try {
      const { firstName, middleName, lastName } = userStore.splitFullName(
        data.name.trim(),
      );
      await userStore.updatePersonalData({
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
      });

      checkoutStore.setNameVal(data.name);
      userStore.setPersonalDataName(data.name);
      mainStore.pushAlert('success', t('emailChanged'));

      closeModal();
    } catch (_) {
      mainStore.pushAlert('error', t('errors:oops'));
    } finally {
      checkoutStore.setIsPersonalDataSync(true);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<RecieverSchema>({
    resolver: zodResolver(RecieverValidationSchema),
  });

  const resetName = () => setValue('name', '');
  const resetEmail = () => setValue('email', '');

  const firstNameProps = register('name');
  const emailProps = register('email');

  const [nameVal, emailVal] = watch(['name', 'email']);

  return (
    <div className="popover-container input-form">
      <div className={styles.root}>
        <button className={styles.closeButton} onClick={backHandler}>
          <Icon
            type="burger-close"
            size={isMobile ? 24 : 32}
            className={styles.closeButtonIcon}
          />
        </button>
        <div className={styles.header}>
          <p className={styles.headerTitle}>{t('reciever')}</p>
        </div>

        <form className={styles.form} onSubmit={handleSubmit(handleForm)}>
          <div className={styles.content}>
            <InputText
              classNameWrapper={styles.input_wrapper}
              classNameInput={styles.input}
              classNameLabelError={styles.labelError}
              classNameInputError={styles.inputError}
              placeholder={t('messageNamePlaceholder')}
              externalLabel={t('name')}
              error={errors.name?.message ?? ''}
              refObj={firstNameProps.ref}
              handleReset={nameVal?.length > 0 ? resetName : undefined}
              required
              {...firstNameProps}
            />
            <InputText
              classNameWrapper={styles.input_wrapper}
              classNameInput={styles.input}
              classNameLabelError={styles.labelError}
              classNameInputError={styles.inputError}
              placeholder={t('messageEmailPlaceholder')}
              externalLabel={t('email')}
              error={errors.email?.message ?? ''}
              refObj={emailProps.ref}
              handleReset={emailVal?.length > 0 ? resetEmail : undefined}
              required
              {...emailProps}
            />
            <div className={styles.notEditableInfo}>
              <p className={styles.notEditableInfoTitle}>{t('phoneNumber')}</p>
              <p className={styles.notEditableInfoValue}>
                {mainStore.prettyPhoneNumber(checkoutStore.phoneVal ?? '')}
              </p>
            </div>
          </div>
          <div className={styles.footer}>
            <button
              className={classes([styles.button, styles.primary])}
              type="submit"
            >
              {t('apply')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default observer(ModalCheckoutReciever);
