import { classes } from 'html-classes';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './Welcome.module.scss';

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div className={classes(['content-layout', styles.welcome])}>
      <h2 className={styles.title}>{t('welcome')}</h2>
      <p className={styles.description}>
        <Trans
          t={t}
          i18nKey="welcomeText"
          components={{ a: <Link className={styles.link} to={'/our-story'} /> }}
        />
      </p>
    </div>
  );
};

export default Welcome;
