import { CompanyName } from '~/company/interface';
import { SommelierButton } from '~/components/SommelierButton';

import styles from './BottomNavigationBar.module.scss';
import { BottomNavigationItem, BottomNavigationType } from './interfaces';

export const CD_NAV_ITEMS: BottomNavigationItem[] = [
  {
    key: 'home',
    type: BottomNavigationType.LINK,
    iconType: 'home',
    link: '/',
    title: 'home',
  },
  {
    key: 'shop',
    type: BottomNavigationType.LINK,
    iconType: 'nav-bar-search',
    link: '/shop',
    title: 'shop',
  },
  {
    key: 'ai',
    type: BottomNavigationType.NODE,
    node: <SommelierButton className={styles.sommelierButton} />,
  },
  {
    key: 'favorite',
    type: BottomNavigationType.LINK,
    iconType: 'nav-bar-fav',
    link: '/favs',
    title: 'favs',
  },
  {
    key: 'cabinet',
    type: BottomNavigationType.LINK,
    iconType: 'cabinet',
    link: '/cabinet',
    title: 'cabinet',
  },
];

export const LOCALEE_NAV_ITEMS: BottomNavigationItem[] = [
  {
    key: 'home',
    type: BottomNavigationType.LINK,
    iconType: 'home',
    link: '/',
    title: 'home',
  },
  {
    key: 'shop',
    type: BottomNavigationType.LINK,
    iconType: 'nav-bar-search',
    link: '/shop',
    title: 'discover',
  },
  {
    key: 'cart',
    type: BottomNavigationType.LINK,
    iconType: 'bag',
    link: '/cart',
    title: 'myCart',
  },
  {
    key: 'favorite',
    type: BottomNavigationType.LINK,
    iconType: 'nav-bar-fav',
    link: '/favs',
    title: 'favsShort',
  },
  {
    key: 'cabinet',
    type: BottomNavigationType.LINK,
    iconType: 'cabinet',
    link: '/cabinet',
    title: 'cabinet',
  },
];

export const CIRKLEK_NAV_ITEMS: BottomNavigationItem[] = [
  {
    key: 'home',
    type: BottomNavigationType.LINK,
    iconType: 'home',
    link: '/',
    title: 'mobileNav:home',
  },
  {
    key: 'shop',
    type: BottomNavigationType.LINK,
    iconType: 'nav-bar-search',
    link: '/shop',
    title: 'mobileNav:discover',
  },
  {
    key: 'cart',
    type: BottomNavigationType.LINK,
    iconType: 'bag',
    link: '/cart',
    title: 'mobileNav:cart',
  },
  {
    key: 'favorite',
    type: BottomNavigationType.LINK,
    iconType: 'nav-bar-fav',
    link: '/favs',
    title: 'mobileNav:favs',
  },
  {
    key: 'cabinet',
    type: BottomNavigationType.LINK,
    iconType: 'cabinet',
    link: '/cabinet',
    title: 'mobileNav:cabinet',
  },
];

export const VILO_NAV_ITEMS: BottomNavigationItem[] = [
  {
    key: 'home',
    type: BottomNavigationType.LINK,
    iconType: 'home',
    link: '/',
    title: 'home',
  },
  {
    key: 'shop',
    type: BottomNavigationType.LINK,
    iconType: 'nav-bar-search',
    link: '/shop',
    title: 'discover',
  },
  {
    key: 'cart',
    type: BottomNavigationType.LINK,
    iconType: 'bag',
    link: '/cart',
    title: 'myCart',
  },
  {
    key: 'favorite',
    type: BottomNavigationType.LINK,
    iconType: 'nav-bar-fav',
    link: '/favs',
    title: 'favsShort',
  },
  {
    key: 'cabinet',
    type: BottomNavigationType.LINK,
    iconType: 'cabinet',
    link: '/cabinet',
    title: 'cabinet',
  },
];

export const BOTTOM_NAVIGATION_ITEMS: Record<
  CompanyName,
  BottomNavigationItem[]
> = {
  [CompanyName.CityDrinks]: CD_NAV_ITEMS,
  [CompanyName.Localee]: LOCALEE_NAV_ITEMS,
  [CompanyName.CircleK]: CIRKLEK_NAV_ITEMS,
  [CompanyName.Vilo]: VILO_NAV_ITEMS,
  [CompanyName.Jiffy]: LOCALEE_NAV_ITEMS,
  [CompanyName.Kids]: LOCALEE_NAV_ITEMS,
};
