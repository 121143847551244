import { AxiosError } from 'axios';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { FormEvent, useEffect, useRef, useState } from 'react';
import Recaptcha, { Callbacks } from 'react-google-invisible-recaptcha';
import { Trans, useTranslation } from 'react-i18next';
import { AnyMasked, IMaskInput } from 'react-imask';
import { Link, useNavigate } from 'react-router-dom';

import { KYCStatus } from '~/api/KYCStatus';
import { company } from '~/company/Company';
import { useModal } from '~/hooks/useModal';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import Icon from '../Icon/Icon';
import InputPhone from '../InputPhone/InputPhone';
import InputText from '../InputText/InputText';

import ApplySMSButton from './ApplySMSButton/ApplySMSButton';
import { ResponseErrorCodes } from './constants';
import { OtpErrorBody } from './interface';

const termsUrls = [
  company.config.links.legals?.generalTradingPolicy || { link: '' },
  company.config.links.legals?.privacyPolicy || { link: '' },
];

// https://jiffygrocery.atlassian.net/browse/JS-2419
const PHONE_MAX_LENGTH = 15 + 1;

export default observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [phoneVal, setPhoneVal] = useState(userStore.personalData.phone || '');
  const [phoneValid, setPhoneValid] = useState(false);
  const [codeVal, setCodeVal] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isPendingCode, setIsPendingCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setCanRequestAgain] = useState(false);
  const [maxAttemptsThreshold, setMaxAttemptsThreshold] = useState(0);
  const [isDeletedUser, setIsDeletedUser] = useState(false);
  const phoneNumber = useRef<string>('');
  const refRecaptcha = useRef<
    HTMLDivElement & { callbacks?: Callbacks | undefined }
  >(null);
  const navigate = useNavigate();
  const [policyAgreement, setPolicyAgreement] = useState(false);

  useEffect(() => {
    if (!userStore.isAuthorized) {
      return;
    }
    if (
      userStore.isAuthorized &&
      userStore.personalData.kycStatus !== KYCStatus.Verified
    ) {
      navigate('/cabinet/personal-data');
    }
    closeModal();
  }, [userStore.isAuthorized]);

  useEffect(() => {
    analyticsEventsEmitter.emit(EventsName.LOGIN_SCREEN_SHOWN);
    analyticsEventsEmitter.emit(EventsName.COMMON_ANALYTICS_LOGIN_SCREEN_SHOWN);
  }, []);

  const backHandler = () => {
    closeModal();
  };

  const handleChangePhone = () => {
    setIsPendingCode(false);
    refRecaptcha?.current?.callbacks?.reset();
  };

  const onResolved = async () => {
    setIsLoading(true);
    try {
      const res = await userStore.requestAuthCode(
        phoneNumber.current,
        refRecaptcha?.current?.callbacks?.getResponse() || '',
      );
      if ('isDeleted' in res && res.isDeleted) {
        setIsDeletedUser(true);
      }
      setIsPendingCode(true);
      setCanRequestAgain(false);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === 429 &&
        !error.response?.data?.message
      ) {
        mainStore.pushAlert(
          'error',
          `<div>${t('errors:unknown')}<br>${t('phrases:supportLink')}</div>`,
        );
      }
    }
    setIsLoading(false);
  };

  const handlePhoneChange = (val: string) => {
    setPhoneVal(val);
    phoneNumber.current = val;
  };

  const handlePhoneValidate = (flag: boolean) => {
    setPhoneValid(flag);

    if (!flag) {
      setCodeVal('');
      setIsCodeValid(false);
      setIsPendingCode(false);
      setMaxAttemptsThreshold(0);
    } else {
      mainStore.sendToRN('analytics', {
        name: 'Login: phone entered',
        params: {},
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'login_phone_entered',
      });
    }
  };

  const handleCodeChange = (val: string, mask: AnyMasked) => {
    setCodeVal(val);
    setIsCodeValid(mask.masked.isComplete);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isPendingCode) {
      if (refRecaptcha.current) {
        refRecaptcha.current.callbacks?.reset();
        refRecaptcha.current.callbacks?.execute();
      }
    } else {
      handleVerifyCode(isDeletedUser).catch(
        (error) => error && console.error(error),
      );
    }
  };

  const handleVerifyCode = async (restore?: boolean): Promise<void> => {
    if (!phoneVal || !codeVal) {
      return;
    }
    setIsLoading(true);
    try {
      const data = await userStore.verifyAuthCode(
        phoneVal.replace('+', ''),
        codeVal,
        Boolean(restore),
      );
      if ('customer' in data && !data.customer.email && data.previousEmail) {
        mainStore.pushAlert('error', t('errors:emailIsAlreadyUse'));
      }
    } catch (error) {
      const errorBody: OtpErrorBody =
        (error as AxiosError<OtpErrorBody>)?.response?.data ||
        ({} as OtpErrorBody);
      if (errorBody.code) {
        switch (errorBody.code) {
          case ResponseErrorCodes.InvalidOtp:
            mainStore.pushAlert('error', t('errors:invalidCodeError'));
            break;
          case ResponseErrorCodes.TryOtpLimitIsReached:
            mainStore.pushAlert(
              'error',
              t('errors:tryOtpLimitIsReached', {
                timer: errorBody.timeout ? ` in ${errorBody.timeout}s` : '',
              }),
            );
            setMaxAttemptsThreshold(errorBody.timeout ?? 0);
            setIsPendingCode(false);
            setCodeVal('');
            break;
          default:
            mainStore.pushAlert('error', t('errors:otpCodeError'));
        }
      } else {
        mainStore.pushAlert('error', t('errors:otpCodeError'));
      }
      error && console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="modal-auth">
      <button
        className="button _no-padding _no-color input-form__back"
        onClick={backHandler}
      >
        <Icon type="arrow" className="icon__rotate-180" size={24} />
      </button>
      <form onSubmit={handleSubmit}>
        {!isPendingCode ? (
          <>
            <h1 className="modal-auth__title">
              {t('phrases:loginOrRegister')}
            </h1>
            <div className="modal-auth__text">
              {t('phrases:enterPhoneNumber')}
            </div>
            <InputPhone
              className={classes([
                'modal-auth__input-phone',
                { invalid: !!phoneVal && !phoneValid },
              ])}
              label=""
              value={phoneVal}
              onChange={handlePhoneChange}
              onValidate={handlePhoneValidate}
              autoFocus={true}
              maxLength={PHONE_MAX_LENGTH}
            />
            <ApplySMSButton
              isLoading={isLoading}
              isDisabled={
                !phoneValid || maxAttemptsThreshold > 0 || !policyAgreement
              }
            >
              {t('verifyNumber')}
            </ApplySMSButton>

            {/*{maxAttemptsThreshold > 0 && (
              <div className="mt-16 fs-14 ">
                <SmsRequestTimer onTimerEnd={() => setMaxAttemptsThreshold(
                  0)} timeoutThreshold={maxAttemptsThreshold} />
              </div>
            )}*/}
          </>
        ) : (
          <>
            <h1 className="modal-auth__title">
              {t('phrases:verifyPhoneNumber')}
            </h1>
            <div className="modal-auth__text">
              {t('phrases:enterCode', { count: 4 })}
            </div>
            <div className="modal-auth__phone">{phoneNumber.current}</div>
            <InputText
              className="modal-auth__input-code"
              label=""
              value={codeVal}
            >
              <IMaskInput
                className="input-text"
                mask="0000"
                value={codeVal}
                unmask={true}
                type="tel"
                autoFocus={true}
                onAccept={handleCodeChange}
                autoComplete="one-time-code"
              />
            </InputText>
            <div className="modal-auth__buttons">
              <div
                className="button _bordered _block _med"
                onClick={handleChangePhone}
              >
                <Icon type="arrow" className="_mirror-x" />
                {t('changeNumber')}
              </div>
              <ApplySMSButton
                isLoading={isLoading}
                isDisabled={!phoneValid || !isCodeValid}
              >
                {/*{!canRequestAgain ? (
                    <SmsRequestTimer onTimerEnd={() => setCanRequestAgain(true)} />
                  ) : (
                    'Send code'
                  )}*/}
                {isDeletedUser ? t('enterOTPAndRecoverAccount') : t('enterOTP')}
              </ApplySMSButton>
            </div>
          </>
        )}

        {!isPendingCode && (
          <div className="modal-auth__agree">
            <label className="type control__checkbox">
              <input
                type="checkbox"
                checked={policyAgreement}
                className="type control__checkbox-input"
                onChange={() => setPolicyAgreement(!policyAgreement)}
              />
              <span className="type control__checkbox-mark" />
              <span>
                <Trans
                  i18nKey="phrases:agreeToCollection"
                  components={termsUrls.map(({ link = '' }) => (
                    <Link to={link} key={link} />
                  ))}
                />
              </span>
            </label>
          </div>
        )}

        <Recaptcha
          ref={refRecaptcha}
          onResolved={onResolved}
          sitekey={mainStore.recaptchaSiteID}
          locale="en"
        />
      </form>
    </div>
  );
});
