import 'swiper/css';
import 'swiper/css/pagination';

import { classes } from 'html-classes';
import { debounce } from 'lodash-es';
import { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { userStore } from '~/stores/UserStore';

import SwiperIcon from '../../Swiper/SwiperIcon';

import { SwiperWithSlidesPerView } from '../BannerProductBlock/interfaces';
import { createCategoryUrl } from '../utils';

import styles from './BannerCategoryBlock.module.scss';

interface Banner {
  src: string;
  alt: string;
  linkId: number | string;
}

interface BannerCategoryBlockProps {
  banners: Banner[];
  isMobile: boolean;
}

const BannerCategoryBlock = ({
  banners,
  isMobile,
}: BannerCategoryBlockProps) => {
  const [slidesPerView, setSlidesPerView] = useState(1);
  const { handleClickCategory } = useCategoryAnalytics();
  const [, setActiveSwiperIndex] = useState(0);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const swiperRef = useRef<SwiperRef>(null);

  const initializeSwiperArrowsDebouncedRef = useRef(
    debounce((swiper: SwiperWithSlidesPerView) => {
      if (!swiper.slides.length) {
        setSlidesPerView(1);
        setIsBeginning(false);
        setIsEnd(false);
        return;
      }

      const slidesPerView = swiper.slidesPerViewDynamic?.() ?? 1;

      setSlidesPerView(slidesPerView);
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }, 100),
  );

  const isArrowsAllowed = () => {
    const swiper = swiperRef.current?.swiper;

    if (!swiper) {
      return false;
    }

    return !isMobile && swiper.slides.length > slidesPerView;
  };

  const isPrevArrowVisible = () => isArrowsAllowed() && !isBeginning;

  const isNextArrowVisible = () => isArrowsAllowed() && !isEnd;

  if (!banners || banners.length === 0) {
    return (
      <div className={classes([styles.container, styles.skeleton])}>
        <div
          className={classes([
            styles.skeleton__container,
            isMobile && styles.mobile,
          ])}
        >
          <Skeleton className={classes([styles.skeleton__item])} />
        </div>
        {!isMobile && (
          <div className={styles.skeleton__container}>
            <Skeleton className={styles.skeleton__item} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classes([styles.container, 'banner-carousel carousel'])}>
      {isPrevArrowVisible() && (
        <SwiperIcon
          position="left"
          variant="fill"
          onClick={() => {
            swiperRef.current?.swiper.slidePrev();
            setIsEnd(false);
            if (swiperRef.current?.swiper.isBeginning) {
              setIsBeginning(true);
            }
          }}
        />
      )}
      <Swiper
        ref={swiperRef}
        spaceBetween={10}
        slidesPerView={isMobile ? 1 : 2}
        pagination={isMobile}
        modules={[Pagination]}
        onSlideChange={(swiper) => setActiveSwiperIndex(swiper.activeIndex)}
        onSlidesLengthChange={initializeSwiperArrowsDebouncedRef.current}
        className={styles.swiper}
        dir={userStore.dir}
        key={userStore.dir}
      >
        {banners.map(({ src, alt, linkId }) => (
          <SwiperSlide className="banner-carousel__item" key={src}>
            <Link
              onClick={handleClickCategory(linkId)}
              to={createCategoryUrl({ id: linkId })}
            >
              <CloudflareResponseImage
                src={src}
                //srcParams={{ width: 595 }}
                srcSetParams={defaultSrcSetParams}
                alt={alt}
                loading="lazy"
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      {isNextArrowVisible() && (
        <SwiperIcon
          position="right"
          variant="fill"
          onClick={() => {
            swiperRef.current?.swiper.slideNext();
            setIsBeginning(false);
            if (swiperRef.current?.swiper.isEnd) {
              setIsEnd(true);
            }
          }}
        />
      )}
    </div>
  );
};

export default BannerCategoryBlock;
