import { format } from 'date-fns-tz';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Order, OrderShort } from '~/api/Order';
import { company } from '~/company/Company';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import { useDateLang } from '~/hooks/useDateLang';
import { mainStore } from '~/stores/MainStore';
import { OrderStatusStore } from '~/stores/OrderStore';
import { formatPriceWithCurrency } from '~/utils/formaters';
import { normalizeOrderId } from '~/utils/normalizeOrderId';

import { OrdersTab } from './interfaces';
import styles from './Orders.module.scss';

type OrderItemProps = {
  order: Order | OrderShort;
  isActive?: boolean;
};

export const OrderItem = ({ order, isActive }: OrderItemProps) => {
  const { t } = useTranslation();
  const { locale } = useDateLang();
  const [publicStatus, setPublicStatus] =
    useState<OrderStatusStore>('accepted');
  const isDelivering = order.public_status === 'in_delivery';

  useEffect(() => {
    let status: OrderStatusStore;
    switch (order.public_status) {
      case 'in_delivery':
        status = 'inDelivery';
        break;
      case 'ready_to_ship':
        status = 'readyToShip';
        break;
      case 'ready_for_pickup':
        status = 'readyForPickup';
        break;
      default:
        status = order.public_status;
        break;
    }
    setPublicStatus(status);
    //eslint-disable-next-line
  }, []);

  const address = [
    order.address?.zip,
    order.address?.address_1,
    order.address?.address_2,
    order.address?.city,
    order.address?.country_id,
  ]
    .filter((part) => part)
    .join(', ');

  return (
    <Link
      to={`/order/${order.id}`}
      state={isActive ? undefined : { tab: OrdersTab.HISTORY }}
      replace={true}
      className={styles.orderItem}
    >
      <div
        className={classes([
          'order-wrapper',
          isActive && '_active',
          order.public_status === 'cancelled' && '_cancelled',
          isDelivering && '_delivering',
        ])}
      >
        <div className="header">
          <div className="title-wrapper">
            <div className="title">
              {t('dayAtTime', {
                day: format(new Date(order.created_at), 'MMMM d', {
                  timeZone: company.config.timeZone,
                  locale,
                }),
                time: format(new Date(order.created_at), "h:mm aaaaa'm'", {
                  timeZone: company.config.timeZone,
                  locale,
                }),
              })}
            </div>
            <div className="subtitle">#{normalizeOrderId(order.order_id)}</div>
          </div>
          <div className="right-block">
            <div className={classes(['badge-closed', order.public_status])}>
              {t(publicStatus)}
            </div>
          </div>
        </div>
        <div className="body">
          {order.public_status !== 'cancelled' && (
            <div className="left-block">
              <>
                <div className="paid-wrapper">
                  <div className="title">{t('paid')}</div>
                  <div className="value">
                    {formatPriceWithCurrency(
                      mainStore.convertPenceToPounds(order.paid_total || 0),
                    )}
                  </div>
                </div>
                <div className="paid-wrapper">
                  <div className="title">{t('deliveryAddress')}</div>
                  <div className="address">{address}</div>
                </div>
                {!isActive && (
                  <div className="paid-wrapper">
                    <div className="title">{t('dateOfDelivery')}</div>
                    <div className="address">
                      {t('dayAtTime', {
                        day: format(new Date(order.sla.ship_by), 'MMMM d', {
                          timeZone: company.config.timeZone,
                          locale,
                        }),
                        time: format(
                          new Date(order.sla.ship_by),
                          "h:mm aaaaa'm'",
                          {
                            timeZone: company.config.timeZone,
                            locale,
                          },
                        ),
                      })}
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
          <div className="right-block">
            <div className="buttle-wrapper">
              {order.items
                .filter((i) => !i.bundle_id)
                .map((item) => (
                  <div key={item.image} className="buttle-wrapper-image">
                    <img alt="" src={item.image} />
                  </div>
                ))}
              {order.bundles?.map((item) => (
                <div key={item.image} className="buttle-wrapper-image">
                  <CloudflareResponseImage
                    src={item.image ?? ''}
                    srcParams={{ width: 54, height: 62 }}
                    srcSetParams={defaultSrcSetParams}
                    alt=""
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
            {/* <Icon
              type="chevron"
              size={16}
              className="icon__rotate-270 control"
            /> */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default observer(OrderItem);
