import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { company } from '~/company/Company';
import BannerMainBlockSkeleton from '~/components/CategoryBlocks/BannerMainBlock/BannerMainBlockSkeleton';
import BannerProductBlockSkeleton from '~/components/CategoryBlocks/BannerProductBlock/BannerProductBlockSkeleton';
import CategoriesGridOneSBlockSkeleton from '~/components/CategoryBlocks/CategoriesGridOneSBlock/CategoriesGridOneSBlockSkeleton';
import CategoryBlock, {
  CategoryDisplayType,
} from '~/components/CategoryBlocks/CategoryBlock';
import ProductsCarouselSliderOneBlockSkeleton from '~/components/CategoryBlocks/ProductsCarouselSliderOneBlock/ProductsCarouselSliderOneBlockSkeleton';
import ProductsGridBlockSkeleton from '~/components/CategoryBlocks/ProductsGridBlock/ProductsGridBlockSkeleton';
import LanguageSelector from '~/components/LanguageSelector/LanguageSelector';
import { ModalType } from '~/components/Modal/interface';
import { NoSsr } from '~/components/NoSsr';
import Welcome from '~/components/Welcome/Welcome';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { useOnWarehouseChange } from '~/hooks/useOnWarehouseChange';
import { DeliveryBanner } from '~/pages/Main/DeliveryBanner';
import { ScrollPaginationSkeleton } from '~/pages/Main/ScrollPaginationSkeleton';
import { homeCategories } from '~/stores/CategoriesStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { checkAndSaveReferralPromocode } from '~/utils/referralLink';

import styles from './Main.module.scss';
import MobileHeaderAddress from './MobileHeader/MobileHeaderAddress';
import MobileHeaderEta from './MobileHeader/MobileHeaderEta';
import MobileHeaderLayout from './MobileHeader/MobileHeaderLayout';
import MobileLogo from './MobileHeader/MobileLogo';

const Main = () => {
  const { isMobile } = useGlobal();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  useOnWarehouseChange((code, isChanged) => {
    if (isChanged || !homeCategories.list.length) {
      homeCategories.paginationRequest.loadPage(1, code);
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    if (state?.isShowAuthModal) {
      openModal(ModalType.Auth);
    }
  }, [state?.isShowAuthModal]);

  useEffect(() => {
    (async () => {
      mainStore.sendToRN('analytics', {
        name: 'Catalog: home page visited',
        params: {
          warehouse_code: orderStore.etaWarehouseCode,
        },
      });

      const urlSearchParams = await checkAndSaveReferralPromocode();

      if (urlSearchParams) {
        navigate(
          {
            pathname,
            search: urlSearchParams.toString(),
          },
          { replace: true },
        );
      }
    })();
  }, []);

  const loadMore = useCallback(() => {
    homeCategories.paginationRequest.loadMore(orderStore.etaWarehouseCode);
  }, []);

  const skeleton = (
    <>
      <BannerMainBlockSkeleton />
      <ProductsCarouselSliderOneBlockSkeleton />
      <CategoriesGridOneSBlockSkeleton />
      <ProductsCarouselSliderOneBlockSkeleton />
      <BannerProductBlockSkeleton />
      <ProductsGridBlockSkeleton />
    </>
  );

  const {
    message: expressDeliveryMessage,
    args: expressDeliveryMessageArgs,
    showFlashIcon,
  } = orderStore.getDeliveryLabel({ minLabel: t('min') });

  const renderDeliveryBanner = () => {
    if (!company.showExpressDeliveryBannerMainPage) {
      return;
    }

    // if (isMobile) {
    //   return <ExpressDeliveryBannerMobile />;
    // }
    // return <ExpressDeliveryBannerDesktop />;

    return (
      <div className="content-layout">
        <DeliveryBanner />
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {t('phrases:mainMetaTitle')} {company.config.name}
        </title>
        <meta name="description" content={t('phrases:mainMetaDescription')} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div
        className={classes(['page-main', styles.root])}
        data-company={company.name}
      >
        <MobileLogo />
        <MobileHeaderLayout
          className={styles.mobileHeaderLayoutClassName}
          showCart={true}
          isSticky={true}
          content={
            <>
              <MobileHeaderAddress />
              <div className={styles.headerGroup}>
                {company.showETAInfoMobileHeader && (
                  <MobileHeaderEta
                    {...{
                      expressDeliveryMessage,
                      expressDeliveryMessageArgs,
                      showFlashIcon,
                    }}
                  />
                )}
                <LanguageSelector />
              </div>
            </>
          }
        />
        {!isMobile && company.hasWelcomeBlock && <Welcome />}
        <div className={styles.container}>
          {renderDeliveryBanner()}
          {homeCategories.list.length === 0
            ? skeleton
            : homeCategories.list.map((category) => (
                <CategoryBlock
                  className={
                    category.displayType !==
                    CategoryDisplayType.CATEGORIES_CAROUSEL_SLIDER_TWO
                      ? 'content-layout'
                      : undefined
                  }
                  category={category}
                  key={category.id}
                  externalLink={category.externalLink ?? undefined}
                />
              ))}
          <NoSsr>
            {homeCategories.list.length > 0 &&
              homeCategories.paginationRequest.canLoadMore && (
                <ScrollPaginationSkeleton onLoadMore={loadMore} />
              )}
          </NoSsr>
        </div>
      </div>
    </>
  );
};

export default observer(Main);
