import { addHours, isToday } from 'date-fns';
import { format } from 'date-fns-tz';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigationType, useParams } from 'react-router-dom';

import { Paginated, RecommendationSource } from '~/api/Catalog';
import { DEFAULT_COMPANIES, company } from '~/company/Company';
import { formatVolume } from '~/components/ProductCard/utils';
import { useDateLang } from '~/hooks/useDateLang';
import { useOnWarehouseChange } from '~/hooks/useOnWarehouseChange';
import { useProductLimit } from '~/hooks/useProductLimit';
import { useStoreInstance } from '~/hooks/useStoreInstance';
import { TabItem, useTabs } from '~/hooks/useTabs';
import { ProductPageCD } from '~/pages/Product/Citydrinks/Product';
import ProductPageLocalee from '~/pages/Product/Localee/Product';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { catalogStore } from '~/stores/CatalogStore';
import { Offer, Product, ProductState } from '~/stores/CategoriesStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { isProductBundle } from '~/types/Product';
import { ProductPropertiesValue } from '~/types/Product/interface';

interface ProductPageLocation {
  state: {
    source?: string;
    isDeeplink?: boolean;
    clean_filters?: boolean;
    offerSku?: string;
  };
}

export interface ProductViewProps {
  tabs: Record<'product' | 'characteristics' | 'reviews', TabItem>;
  product: ProductState;
  isExpress: boolean;
  deliveryToday: boolean;
  backorderDate: Nullable<string>;
  isBackorderAvailable: Nullable<boolean>;
  isBundle: boolean;
  isMultiBuy: boolean;
  volumes: Array<{ id: string; value: string }>;
  currentItem: Nullable<Product | Offer>;
  currentPrice?: string;
  oldPrice: Nullable<string>;
  count: number;
  title?: string;
  limit: number;
  tab: Nullable<TabItem>;
  propertyList: ProductPropertiesValue[];
  bundleImages: string[];
  similarProducts: Paginated<Product> | undefined;
  matchingProducts: Paginated<Product> | undefined;
  handleCopyCode: () => Promise<void>;
  handleFavorite: () => void;
  addMultiBuyProductToCart: () => void;
  handleChangeSection: (alias: string) => void;
  handleChangeCount: (count: number, action: 'add' | 'remove') => void;
  handleChangeOffer: (sku: string) => () => void;
}

const ProductPage = () => {
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string }>();
  const { state }: ProductPageLocation = useLocation();
  const product = useStoreInstance(ProductState, productId || '');
  const navType = useNavigationType();
  const { locale } = useDateLang();
  const [tab, setTab, tabs] = useTabs({
    product: {
      alias: 'product',
      title: t('product'),
    },
    characteristics: {
      alias: 'characteristics',
      title: t('characteristics'),
    },
    reviews: {
      alias: 'reviews',
      title: t('reviews'),
    },
  });

  useOnWarehouseChange(
    async (code) => {
      if (productId && !product.data && !product.request.isLoading) {
        await product.request.run({ productId, warehouse: code });

        if (state?.offerSku) {
          product.selectOffer(state.offerSku);
        }
      }
    },
    [productId, product],
  );

  useEffect(() => {
    if (!product.data) {
      return;
    }

    catalogStore.getProductRecommendationDebounced(
      product.data.offers.map((item) => item.sku),
      [
        RecommendationSource.CARD_ASSOCIATIONS,
        RecommendationSource.CARD_SIMILAR,
      ],
    );

    return () => {
      catalogStore.clearProductRecommendations(
        RecommendationSource.CARD_SIMILAR,
      );
      catalogStore.clearProductRecommendations(
        RecommendationSource.CARD_ASSOCIATIONS,
      );
    };
  }, [product.data]);

  useEffect(() => {
    if (navType !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [JSON.stringify(tab)]);

  useEffect(() => {
    if (product.data) {
      analyticsEventsEmitter.emit(EventsName.VIEW_ITEM, {
        currency: company.currency,
        value: product.data.discountPrice || product.data.price,
        items: [catalogStore.convertToFirebase(product.data)],
      });
    }
  }, [product.data]);

  const volumes = product.data?.offers?.map((item) => ({
    id: item.sku,
    value: formatVolume(item.properties),
  })) || [{ id: 'unknown', value: '1x' }];

  const isBundle = product.data ? isProductBundle(product.data) : false;
  const bundleImages = product.data?.images
    ? product.data.images
    : [`${product.data?.previewImageThumb}`].filter(Boolean);

  const currentItem = isBundle
    ? product.data
    : product.selectedOffer || product.data;
  const isMultiBuy =
    !isBundle && Boolean(product.selectedOffer?.promoRequiredQuantity);

  const title = product.data?.name;
  const currentPrice = currentItem?.discountPrice
    ? currentItem?.discountPriceFormatted
    : currentItem?.priceFormatted;
  const oldPrice =
    !isMultiBuy && currentItem?.discountPrice
      ? currentItem?.priceFormatted
      : null;

  const count = currentItem
    ? catalogStore.getCartItemCountById(currentItem)
    : 0;

  const { limit, currentWhSellable, parentWhSellable } = useProductLimit({
    offer: currentItem,
  });

  const matchingProducts =
    catalogStore.productRecommendations[RecommendationSource.CARD_ASSOCIATIONS];
  const similarProducts =
    catalogStore.productRecommendations[RecommendationSource.CARD_SIMILAR];

  const handleChangeCount = (count: number, action: 'add' | 'remove') => {
    if (currentItem) {
      catalogStore.setCartItemCountByProduct(
        {
          ...currentItem,
          ratingAverage: currentItem?.ratingAverage ?? 0,
          ratingMarksCount: currentItem?.ratingMarksCount ?? 0,
        },
        count,
        action,
        'product_main',
      );
    }
  };

  const handleFavorite = () => {
    if (!product.data) {
      return;
    }
    catalogStore.toggleFavorite(product.data, 'product_main');
    mainStore.sendToRN('hapticFeedback', {
      count: 1,
    });
  };

  const handleChangeOffer = (sku: string) => () => {
    product.selectOffer(sku);
  };

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(location.href);
      mainStore.pushAlert('success', t('linkCopied'));
    } catch (error) {
      error && console.error(error);
      mainStore.sendToRN(
        'copyToClipboard',
        location.href,
        () => {
          mainStore.pushAlert('success', t('referral:promoCopied'));
        },
        () => {
          mainStore.pushAlert('error', t('errors:unknown'));
        },
      );
    }
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Referral program: Copy code',
      params: {},
    });
  };

  const addMultiBuyProductToCart = () => {
    if (!product.selectedOffer) {
      return;
    }

    const targetCount = product.selectedOffer.promoRequiredQuantity - count;

    if (targetCount <= 0 || currentWhSellable < count + targetCount) {
      return;
    }

    handleChangeCount(count + targetCount, 'add');
  };

  const propertyList = Object.values(product.selectedOffer?.properties ?? {})
    .filter(
      ({ type, name }) =>
        type !== 'toggle' &&
        name.toLowerCase() !== 'volume' &&
        type !== 'badge',
    )
    .sort((a, b) => a.order - b.order);

  const backorderDate = product.selectedOffer?.backorderLeadTime
    ? format(
        addHours(new Date(), product.selectedOffer.backorderLeadTime),
        'd MMMM',
        {
          timeZone: company.config.timeZone,
          locale,
        },
      )
    : null;

  const deliveryToday = product.selectedOffer?.backorderLeadTime
    ? isToday(addHours(new Date(), product.selectedOffer?.backorderLeadTime))
    : false;

  const isExpress =
    currentItem &&
    currentWhSellable &&
    currentWhSellable >= count &&
    orderStore.isExpressAvailableNow;

  const isBackorderAvailable =
    currentItem &&
    currentItem.isBackorderAvailable &&
    !!currentItem.backorderLeadTime &&
    parentWhSellable > 0;

  const Component = DEFAULT_COMPANIES.includes(company.name)
    ? ProductPageLocalee
    : ProductPageCD;

  return (
    <Component
      tabs={tabs}
      product={product}
      isExpress={!!isExpress}
      deliveryToday={deliveryToday}
      backorderDate={backorderDate}
      isBackorderAvailable={isBackorderAvailable}
      isBundle={isBundle}
      isMultiBuy={isMultiBuy}
      volumes={volumes}
      currentItem={currentItem}
      currentPrice={currentPrice}
      oldPrice={oldPrice}
      count={count}
      title={title}
      limit={limit}
      tab={tab}
      propertyList={propertyList}
      similarProducts={similarProducts}
      matchingProducts={matchingProducts}
      bundleImages={bundleImages}
      handleCopyCode={handleCopyCode}
      handleFavorite={handleFavorite}
      addMultiBuyProductToCart={addMultiBuyProductToCart}
      handleChangeSection={setTab}
      handleChangeCount={handleChangeCount}
      handleChangeOffer={handleChangeOffer}
    />
  );
};

export default observer(ProductPage);
