import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import Swiper from '~/components/Swiper/Swiper';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import styles from '~/pages/Checkout/CheckoutContent.module.scss';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { isProductBundle } from '~/types/Product';

const PickUpProductList = ({ className }: { className?: string }) => {
  const { handleClickProduct } = useCategoryAnalytics();
  const { t } = useTranslation();

  return (
    <div className={classes([styles.pickupContentWr, className])}>
      <h3 className={styles.pickupContent__title}>
        {t('deliveryMethods:yourOrderWillBeReady', {
          range: '5 - 10',
        })}
      </h3>

      <div className={styles.pickupContent__products}>
        <Swiper
          slideClassName={styles.pickupContent__products__item}
          spaceBetween={8}
          slidesPerViewProp="auto"
          arrows={false}
        >
          {
            checkoutStore.deliveries
              ?.map((item) => item.itemsIds)
              .flat()
              .map((cartItemId) => {
                const item = catalogStore.selectedCartItems.find(
                  (item) => item.sku === cartItemId,
                );

                if (!item) {
                  return;
                }

                return (
                  <Link
                    key={item.id}
                    onClick={handleClickProduct(item)}
                    to={`/p/${item.slug}`}
                    state={{
                      ...(isProductBundle(item) ? {} : { offerSku: item.sku }),
                    }}
                  >
                    <div
                      key={item.previewImageThumb}
                      className="slot-list__slot-images-item"
                    >
                      <CloudflareResponseImage
                        src={item.previewImageThumb}
                        srcParams={{ width: 50, height: 72 }}
                        srcSetParams={defaultSrcSetParams}
                        alt={item.name}
                        loading="lazy"
                      />
                    </div>
                  </Link>
                );
              })
              .filter(Boolean) as ReactNode[]
          }
        </Swiper>
      </div>
    </div>
  );
};

export default observer(PickUpProductList);
