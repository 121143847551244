import { classes } from 'html-classes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import img from '~/assets/img/localee/referral.png';
import Icon from '~/components/Icon/Icon';
import Modal from '~/components/Modal/Modal';
import { useGlobal } from '~/hooks/useGlobal';

import styles from './ReferralProgram.module.scss';

interface ReferralProgramModal {
  open: boolean;
  onClose(): void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ReferralProgramModal = ({
  open,
  onClose,
}: ReferralProgramModal) => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    if (open && isMobile) {
      navigate('/cabinet/referral');
    }
  }, [isMobile, navigate, open]);

  return (
    <Modal isShow={open} className={styles.modal}>
      <button
        onClick={onClose}
        className={classes(['button _no-padding', styles.close])}
      >
        <Icon type="close" size={32} />
      </button>
      <div className={styles.block}>
        <p className={styles.title}>{t('shareLove')}</p>
        <p className={styles.description}>{t('referFriend')}</p>
        <Link
          to="/cabinet/referral"
          className={classes(['button _primary', styles.action])}
        >
          {t('inviteFriendsNow')}
        </Link>
      </div>
      <img src={img} alt="" className={styles.image} />
    </Modal>
  );
};
