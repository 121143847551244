export const OFFERS_LIMIT = 1000;

export const SECOND = 1000;

export enum Time {
  Second = 1000,
  Minute = 60 * 1000,
  Hour = 60 * 60 * 1000,
  Day = 24 * 60 * 60 * 1000,
}
