import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import LanguageSelector from '~/components/LanguageSelector/LanguageSelector';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';

import Icon from '../../Icon/Icon';

import styles from './DeliveryButton.module.scss';

type Props = {
  expressDeliveryMessage: string;
  expressDeliveryMessageArgs: Record<string, string>;
  showFlashIcon?: boolean;
  handleDeliveryAddress: () => void;
};

export const DeliveryButton = observer(
  ({
    expressDeliveryMessage,
    expressDeliveryMessageArgs,
    showFlashIcon,
    handleDeliveryAddress,
  }: Props) => {
    const { t } = useTranslation();

    if (DEFAULT_COMPANIES.includes(company.name)) {
      return (
        <div
          className={classes([
            styles.header_address,
            !orderStore.etaCalculation && '_active',
          ])}
          data-company={company.name}
        >
          <div className={styles.headerGroup}>
            <div className={styles.header_eta}>
              {showFlashIcon && (
                <Icon
                  type="express-fill"
                  size={16}
                  className={styles.header_eta__icon}
                />
              )}
              <div className={styles.header_eta__text}>
                {t(expressDeliveryMessage, expressDeliveryMessageArgs)}
              </div>
            </div>
            <LanguageSelector />
          </div>
          <div
            className={styles.header_address_container}
            onClick={handleDeliveryAddress}
          >
            <Icon type="location" size={20} />
            <div
              className={styles.header_address__text}
              title={t('phrases:selectDeliveryAddress')}
            >
              {!orderStore.etaCalculation || !userStore.deliveryAddress
                ? t('phrases:selectDeliveryAddress')
                : `${userStore.deliveryAddress.address1} ${userStore.deliveryAddress.zip}`}
            </div>
            <Icon type="chevron" size={16} className="icon__rotate-270" />
          </div>
        </div>
      );
    }

    return (
      <div
        className={classes([
          styles.header_address,
          !orderStore.etaCalculation && '_active',
        ])}
        onClick={handleDeliveryAddress}
        data-company={company.name}
      >
        <div className={styles.header_eta}>
          {showFlashIcon && <Icon type="express-fill" size={16} />}
          <div className={styles.header_eta__text}>
            {t(expressDeliveryMessage, expressDeliveryMessageArgs)}
          </div>
          <Icon type="chevron" size={16} />
        </div>
        <div
          className={styles.header_address__text}
          title={t('phrases:selectDeliveryAddress')}
        >
          {!orderStore.etaCalculation || !userStore.deliveryAddress
            ? t('phrases:selectDeliveryAddress')
            : `${userStore.deliveryAddress.address1} ${userStore.deliveryAddress.zip}`}
        </div>
      </div>
    );
  },
);
