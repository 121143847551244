export enum CompanyName {
  CityDrinks = 'citydrinks',
  Localee = 'localee',
  CircleK = 'circlek',
  Vilo = 'vilo',
  Jiffy = 'jiffy',
  Kids = 'ourkids',
}

export type GtmConfig = { auth: string; preview: string };

export type CompanyConfig = {
  id: string;
  lang: string;
  langs: string[];
  name: string;
  currency: string;
  officialName: string;
  marketingEmail: string;
  email: string;
  marketingEmail: string;
  phonePlaceholder: string;
  defaultPhoneCode?: string;
  phoneNumber?: string;
  address?: string;
  canonical: string;
  ageRestricted: number;
  timeZone: string;
  minimalOrderFeeThreshold: number;
  useGoogleApiForGeocoding?: boolean;
  countryCode?: string;
  warehouse: {
    default: string;
    minimalOrderFeeWarehouseCodes: string[];
    location: GeoCoordinates;
  };
  map: {
    center: GeoCoordinates;
    zoom: number;
    markers?: {
      position: GeoCoordinates;
      label?: string;
    }[];
  };
  links: {
    terms?: string;
    privacy?: string;
    cookie: string;
    deliveryZones: string;
    forRiders: string;
    affiliateProgram: string;
    legals?: {
      [key: string]: {
        label: string;
        link: string;
        blank?: boolean;
      };
    };
    whatsapp?: string;
    facebook?: string;
    instagram?: string;
    tiktok?: string;
    youtube?: string;
    linkedin?: string;
    // [key: string]: string;
  };
  referral: {
    code: string;
    discount: string;
    minBasket: string;
    downloadAppLink: string;
  };
  points?: {
    value?: string;
    pointsPerUnit?: number;
    icon: 'coins' | 'jiffy-points';
  };
  appStoreUrl: {
    ios: string;
    android: string;
  };
  apis: {
    [api: string]: {
      token?: string | number;
      locale?: string;
      production?: GtmConfig | string;
      staging?: GtmConfig | string;
      test?: GtmConfig | string;
      development?: GtmConfig | string;
      gtmId?: string;
      url?: string;
      widgetId?: string;
    };
  };
  cloudflareImages: {
    enabled: boolean;
    domain?: string;
  };
  license: {
    name: string;
    numberPrinary: string;
    numberSecondary?: string;
  };
};
