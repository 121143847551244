import { toJS } from 'mobx';
import { useEffect } from 'react';

import {
  deliveryAddressStore,
  mapCenter,
} from '~/components/ModalDeliveryAddress/DeliveryAddressStore';
import { MODAL_EDIT_ADDRESS_PARAM } from '~/hooks/useModal';
import { orderStore } from '~/stores/OrderStore';
import { UserStore } from '~/stores/UserStore';

const useModalEditEffect = ({
  params,
  userStore,
}: {
  params: URLSearchParams;
  userStore: UserStore;
}) => {
  useEffect(() => {
    if (params.has(MODAL_EDIT_ADDRESS_PARAM)) {
      const address = userStore.addressList.find(
        ({ id }) => id === params.get(MODAL_EDIT_ADDRESS_PARAM),
      );

      if (!address) {
        return;
      }

      const coordinates =
        address.latitude && address.longitude
          ? {
              lat: address.latitude,
              lng: address.longitude,
            }
          : mapCenter;

      const selectedZone = deliveryAddressStore.getSelectedPolygon(coordinates);

      deliveryAddressStore.setEditAddressData({
        addressId: address.id ?? null,
        address2: address.street_address_2 ?? '',
        type: address.type ?? 'home',
        comment: address.comment ?? '',
        instructions: address.instructions ?? [],
      });
      deliveryAddressStore.setMapCenter(coordinates);
      deliveryAddressStore.setSelectedZone(selectedZone);

      deliveryAddressStore.setDeliveryAddress({
        address1: address.street_address_1 ?? '',
        addressId: address.id,
        comment: address.comment ?? '',
        address2: address.street_address_2 ?? '',
        coordinates,
        shortAddress: address.street_address_1 ?? '',
        instructions: address.instructions ?? [],
        city: address.city,
        country: address.country_code,
        placeId: '',
        //  FIXME: what is correct region?
        region: '',
        zip: address.postcode,
        type: address.type ?? 'home',
      });

      deliveryAddressStore.setEtaCalculation(null);
      deliveryAddressStore.setInputAddressValue(address.street_address_1 ?? '');
      deliveryAddressStore.geocoding(false, coordinates);
      return;
    }

    const center = toJS(userStore.deliveryAddress?.coordinates || mapCenter);
    const selectedZone = deliveryAddressStore.getSelectedPolygon(center);
    deliveryAddressStore.setSelectedZone(selectedZone);
    deliveryAddressStore.setMapCenter(center);
    deliveryAddressStore.setDeliveryAddress(toJS(userStore.deliveryAddress));
    deliveryAddressStore.setEditAddressData({
      addressId: null,
      address2: toJS(userStore.deliveryAddress?.address2) ?? '',
      type: toJS(userStore.deliveryAddress?.type) ?? 'home',
      comment: '',
      instructions: [],
    });
    deliveryAddressStore.setEtaCalculation(toJS(orderStore.etaCalculation));
    if (deliveryAddressStore.deliveryAddress?.shortAddress) {
      deliveryAddressStore.setInputAddressValue(
        deliveryAddressStore.deliveryAddress?.shortAddress,
      );
    }
  }, [userStore.addressList.length]);
};

export default useModalEditEffect;
