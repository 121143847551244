import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';

import { company } from '~/company/Company';
import Section from '~/components/Section';
import { Offer, Product } from '~/stores/CategoriesStore';
import { ProductPropertiesValue } from '~/types/Product/interface';

import styles from '../Product.module.scss';

import '../styles.scss';

type Props = {
  propertyList: ProductPropertiesValue[];
  currentItem?: Product | Offer;
};

const ProductCharacteristicsLocalee = ({
  propertyList,
  currentItem,
}: Props) => {
  return (
    <div
      className={classes([styles.productContent, styles.characteristic])}
      data-company={company.name}
    >
      <Section>
        <ul className={styles.characteristicList} data-company={company.name}>
          {propertyList.map((prop) => (
            <li className={styles.characteristicListItem} key={prop.name}>
              <div className={styles.characteristicListItem__name}>
                <p className="">{prop.name}</p>
              </div>
              <div className={styles.characteristicListItem__value}>
                <p className="">{prop.value}</p>
              </div>
            </li>
          ))}
        </ul>
        {currentItem?.description &&
          company.showDescriptionReviewProductPage && (
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: currentItem.description }}
            />
          )}
      </Section>
    </div>
  );
};

export default observer(ProductCharacteristicsLocalee);
