import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import AppleStoreText from '~/assets/img/apple_store_text.svg';
import GooglePlayText from '~/assets/img/google_play_text.svg';
import { company } from '~/company/Company';
import { IMAGES } from '~/constants/images';

import { StoreButton } from '../../StoreButtons/StoreButton';

import styles from './DownloadApp.module.scss';

export const DownloadApp = observer(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.header_top_download}>
      <div className="content-layout">
        <div className={styles.header_top_download__content}>
          <div className={styles.header_top_download__title}>
            {t('downloadApp')}
          </div>
          <div className={styles.header_top_download__downloadItems}>
            <StoreButton
              iconType="apple"
              link={company.config.appStoreUrl.ios}
              containerClass="header-top-download__storeButton"
              img={AppleStoreText}
            />
            <StoreButton
              iconType="gplay"
              link={company.config.appStoreUrl.android}
              containerClass={styles.header_top_download__storeButton}
              img={GooglePlayText}
            />
            <img
              src={IMAGES.qr.mini}
              alt=""
              className={styles.header_top_download__qr}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
