import { useClickOutside, useDebouncedEffect } from '@react-hookz/web';
import { useQuery } from '@tanstack/react-query';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { company } from '~/company/Company';
import AddressAutocomplete from '~/components/ModalDeliveryAddress/AddressAutocomplete';
import geocoderRequest from '~/components/ModalDeliveryAddress/geocoderRequest';
import useModalEditEffect from '~/components/ModalDeliveryAddress/useModalEditEffect';
import { useGeolocation } from '~/hooks/useGeolocation';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import Icon from '../Icon/Icon';

import AddressAutocompleteDropDown from './AddressAutocompleteDropDown';
import { deliveryAddressStore } from './DeliveryAddressStore';
import Geocoding from './Geocoding/Geocoding';
import Geolocation from './Geolocation/Geolocation';
import GMap from './GMap/GMap';
import styles from './ModalDeliveryAddress.module.scss';
import { SaveButton } from './SaveButton/SaveButton';

interface AutocompleteResponse {
  data: {
    result: {
      address: string;
      postcode: null | string;
      location: {
        latitude: number;
        longitude: number;
      };
    } | null;
  };
}

const ModalDeliveryAddress = () => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const { requestLocation } = useGeolocation();
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
  const [addressInputText, setAddressInputText] = useState('');
  const [addressToSearch, setAddressToSearch] = useState('');
  const [autocompletePlaceId, setAutocompletePlaceId] = useState<string>();
  const [searchParams] = useSearchParams();
  const editableAddressId = searchParams.get('editAddress');
  const addressFormRef = useRef<HTMLDivElement>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);
  const geocodingRequestTimeoutRef =
    useRef<Nullable<ReturnType<typeof setTimeout>>>(null);
  const { closeModal } = useModal();

  const setAutocompleteActive = () => {
    setIsAutoCompleteOpen(true);
  };

  const handleCloseAddressModal = () => {
    closeModal();
    deliveryAddressStore.setSelectedZone(null);
  };

  useEffect(() => {
    if (isAutoCompleteOpen) {
      addressInputRef.current?.focus();
    }
  }, [isAutoCompleteOpen]);

  useEffect(() => {
    userStore.resetAddressSearchSessionToken();
    if (editableAddressId) {
      return;
    }
    requestLocation();
  }, []);

  useEffect(() => {
    setAddressInputText(deliveryAddressStore.inputAddressValue);
  }, [deliveryAddressStore.inputAddressValue]);

  useDebouncedEffect(
    () => setAddressToSearch(addressInputText),
    [addressInputText],
    500,
  );

  useClickOutside(addressFormRef, () => {
    setIsAutoCompleteOpen(false);
    setAddressInputText(deliveryAddressStore.inputAddressValue);
  });

  useEffect(() => {
    return () => {
      deliveryAddressStore.resetStore();
    };
  }, []);

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useModalEditEffect({ params, userStore });

  useEffect(() => {
    setAutocompletePlaceId(undefined);
  }, [deliveryAddressStore.deliveryAddress?.placeId]);

  const {
    data: { result: autocompleteResult },
    isFetching,
  } = useQuery({
    enabled: !!userStore.addressSearchSessionToken && !!autocompletePlaceId,
    queryKey: ['autocomplete-get-place', autocompletePlaceId],
    initialData: {
      result: null,
    },
    queryFn: () =>
      geocoderRequest
        .post<AutocompleteResponse>('/autocomplete', {
          input: '',
          placeId: autocompletePlaceId,
          sessionToken: userStore.addressSearchSessionToken,
          countryCode: company.config.countryCode || '',
        })
        .then(({ data: { data } }) => data),
  });

  useEffect(() => {
    if (!autocompleteResult) {
      return;
    }

    const point = {
      lat: autocompleteResult.location.latitude,
      lng: autocompleteResult.location.longitude,
    };

    const selectedZone = deliveryAddressStore.getSelectedPolygon(point);

    deliveryAddressStore.setMapCenter(point);
    deliveryAddressStore.setSelectedZone(selectedZone);

    deliveryAddressStore.setDeliveryAddress({
      zip: '',
      country: '',
      city: '',
      region: '',
      address1: '',
      address2: '',
      shortAddress: '',
      coordinates: point,
      placeId: mainStore.getPseudoId(),
      addressId: null,
      comment: '',
      instructions: [],
      type: 'home',
    });

    const cleanTimeout = () => {
      if (geocodingRequestTimeoutRef.current) {
        clearTimeout(geocodingRequestTimeoutRef.current);
        geocodingRequestTimeoutRef.current = null;
      }
    };

    if (selectedZone) {
      cleanTimeout();
      // FIXME: I don't know why we need this timeout here
      //  but it has been added in the saas code and I don't want to break anything
      geocodingRequestTimeoutRef.current = setTimeout(() => {
        deliveryAddressStore.geocoding(false, {
          lng: autocompleteResult.location.longitude,
          lat: autocompleteResult.location.latitude,
        });
        geocodingRequestTimeoutRef.current = null;
      }, 500);
    }

    return cleanTimeout;
  }, [autocompleteResult, deliveryAddressStore.renderedZonesOnMap]);

  useEffect(() => {
    deliveryAddressStore.setIsLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (editableAddressId) {
      const editableAddress = userStore.addressList.find(
        (item) => item.id === editableAddressId,
      );
      if (editableAddress) {
        const point = {
          lat: editableAddress.latitude,
          lng: editableAddress.longitude,
        };
        deliveryAddressStore.setMapCenter(point);
        deliveryAddressStore.setSelectedZone(
          deliveryAddressStore.getSelectedPolygon(point),
        );
      }
    }
  }, []);

  const autocompleteInput = (
    <div
      ref={addressFormRef}
      className={classes([
        'address__form-input-container',
        isAutoCompleteOpen && styles.addressInputContainerFocused,
        isAutoCompleteOpen && 'mobile__opened',
      ])}
    >
      <Icon type="location" size={24} />
      <input
        placeholder={t(isAutoCompleteOpen ? 'typeLocation' : 'deliveryAddress')}
        value={addressInputText}
        onChange={(e) => setAddressInputText(e.target.value)}
        onFocus={() => setAutocompleteActive()}
        disabled={deliveryAddressStore.isLoading && !isAutoCompleteOpen}
        ref={addressInputRef}
      />
      {isAutoCompleteOpen && (
        <button
          className={classes([
            'button _no-padding _no-color',
            styles.backButton,
          ])}
          onClick={() => setIsAutoCompleteOpen(false)}
        >
          <Icon
            type="arrow"
            className={classes(['icon__rotate-180', styles.backButtonIcon])}
            size={24}
          />
        </button>
      )}
      {isAutoCompleteOpen && (
        <button
          className={classes([
            'button _no-padding _no-color',
            styles.resetButton,
          ])}
          onClick={() => {
            setAddressInputText('');
            deliveryAddressStore.setDeliveryAddress(null);
            deliveryAddressStore.setInputAddressValue('');
          }}
        >
          <Icon
            type="plus"
            className={classes(['icon__rotate-45', styles.resetButtonIcon])}
            size={36}
          />
        </button>
      )}
      <AddressAutocompleteDropDown
        isOpen={isAutoCompleteOpen}
        className={styles.dropDown}
      >
        <AddressAutocomplete
          searchSessionToken={userStore.addressSearchSessionToken}
          searchText={addressToSearch}
          onSelect={(placeId) => {
            setIsAutoCompleteOpen(false);

            if (placeId) {
              setAutocompletePlaceId(placeId);
              userStore.resetAddressSearchSessionToken();
            }
          }}
          onLoadingStateChange={(isLoading) =>
            deliveryAddressStore.setIsLoading(isLoading)
          }
        />
      </AddressAutocompleteDropDown>
    </div>
  );

  return (
    <div className="modal-delivery-address" data-company={company.name}>
      {isMobile && (
        <style>
          {`.alert {
              bottom: unset !important; top: calc(var(--offset-top) + 60px)
            }`}
        </style>
      )}
      {isAutoCompleteOpen && isMobile ? (
        <div
          className={classes([
            'modal-delivery-address__data',
            styles.addressContainer,
            isAutoCompleteOpen && isMobile && styles.addressContainerFocused,
          ])}
        >
          <div className="modal-delivery-address__form">
            {autocompleteInput}
          </div>
        </div>
      ) : (
        <div
          className={classes([
            'modal-delivery-address__data',
            styles.addressContainer,
            isAutoCompleteOpen && isMobile && styles.addressContainerFocused,
          ])}
        >
          <p className="h2 modal-delivery-address__title">
            {t('enterDeliveryAddress')}
          </p>
          <div className="modal-delivery-address__form">
            {autocompleteInput}{' '}
            <div className="address__form-input-container">
              <Icon type="home-edit" size={24} />
              <input
                placeholder={`${t('address2')}*`}
                value={deliveryAddressStore.editAddressData.address2}
                onChange={(e) =>
                  deliveryAddressStore.setDeliveryAddress2(e.target.value)
                }
              />
            </div>
            <div className="address__form-selector">
              <button
                className={classes([
                  'button address__form-selector-btn',
                  deliveryAddressStore.editAddressData.type === 'home' &&
                    'selected',
                ])}
                onClick={() => deliveryAddressStore.setAddressType('home')}
              >
                {t('addressHome')}
              </button>
              <button
                className={classes([
                  'button address__form-selector-btn',
                  deliveryAddressStore.editAddressData.type !== 'home' &&
                    'selected',
                ])}
                onClick={() => deliveryAddressStore.setAddressType('custom')}
              >
                {t('addressCustom')}
              </button>
            </div>
            {deliveryAddressStore.editAddressData.type !== 'home' && (
              <div className="address__form-input-container">
                <input
                  placeholder={t('addressCustomName')}
                  value={deliveryAddressStore.editAddressData.type}
                  onChange={(e) =>
                    deliveryAddressStore.setAddressTypeValue(e.target.value)
                  }
                />
              </div>
            )}
          </div>
          <div className="address__form-button-container">
            <SaveButton />
          </div>
        </div>
      )}

      <div
        className={classes([
          'modal-delivery-address__map',
          isAutoCompleteOpen && 'hide',
        ])}
      >
        <Geocoding />
        <GMap />
        <Icon
          type="pin"
          size={company.match({
            citydrinks: 44,
            default: 84,
          })}
          className="modal-delivery-address__map-pin"
        />
        <Geolocation />
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={handleCloseAddressModal}
        >
          <Icon
            type="plus"
            className="icon__rotate-45"
            size={company.match({
              citydrinks: 36,
              default: isMobile ? 16 : 24,
            })}
          />
        </button>
      </div>
    </div>
  );
};

export default observer(ModalDeliveryAddress);
