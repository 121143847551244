import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IMAGES } from '~/constants/images';

import EmptyCardsImage from '../../assets/img/empty_payments.png';

const EmptyOrders = ({ orderType = '' }: { orderType?: string }) => {
  const { t } = useTranslation();

  const literalOrderType = orderType ? t(`phrases:${orderType}`) : '';

  return (
    <div className="empty-cart">
      <div className="empty-cart__content">
        <img
          className="empty-cart__img"
          src={IMAGES.order.empty ?? EmptyCardsImage}
          alt="empty list"
        />
        <p className="empty-cart__title">
          {t('phrases:noOrders', {
            orderType: literalOrderType,
          })}
        </p>
      </div>
      <div className="empty-cart__buttons">
        <Link to={'/shop'} className="button _primary btn-wrapper">
          {t('goShopping')}
        </Link>
      </div>
    </div>
  );
};

export default observer(EmptyOrders);
