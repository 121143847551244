import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';
import { CopyOrderNumber } from '~/pages/Order/components/CopyOrderNumber';
import { OrderQrCode } from '~/pages/Order/components/OrderQrCode';
import { OrderStatusBadge } from '~/pages/Order/components/OrderStatusBadge';
import { orderStore } from '~/stores/OrderStore';
import { normalizeOrderId } from '~/utils/normalizeOrderId';

import styles from '../../Order.module.scss';

import { OrderDateText } from '../OrderDateText';

import { PickUpOrderHeaderProps } from './interfaces';

const PATH = '/cabinet/orders';

const PickUpOrderHeader = ({ order }: PickUpOrderHeaderProps) => {
  const { isTablet, isMobile } = useGlobal();
  const { state } = useLocation();
  const { t } = useTranslation();

  const orderNumberText = t('phrases:orderNumber', {
    number: normalizeOrderId(order?.order_id ?? ''),
  });

  const isReadyForPickUpStatus = order.public_status === 'ready_for_pickup';
  const isCanceled = ['failed', 'cancelled'].includes(order.public_status);

  const messageByStatus = () => {
    switch (order.public_status) {
      case 'accepted':
      case 'picking':
      case 'in_delivery':
        return t('deliveryMethods:yourOrderWillBeReady', {
          range: '5 - 10',
        });
      case 'ready_to_ship':
      case 'ready_for_pickup':
        return t('deliveryMethods:yourOrderIsReady');
      case 'delivered':
        return t('deliveryMethods:orderFinished');
      case 'failed':
      case 'cancelled':
      default:
        return undefined;
    }
  };

  return (
    <>
      <div className={styles.pickUpOrderHeader}>
        {isTablet || isMobile ? (
          <Link
            to={state && state.tab ? `${PATH}/${state.tab}` : PATH}
            replace={true}
            className="go-back"
          >
            <Icon type="arrow" className="icon__rotate-90" />
            <span>{orderNumberText}</span>
          </Link>
        ) : (
          <div className={styles.breadcrumbsWr}>
            <Breadcrumbs
              items={[
                {
                  link: PATH,
                  text: 'Order List',
                },
                {
                  text: orderNumberText,
                  link: '',
                },
              ]}
              className={styles.breadcrumbs}
              delimiterContent={
                <Icon type="chevron" size={16} className="icon__rotate-270" />
              }
            />
          </div>
        )}
        <div className={styles.pickUpOrderHeader__status}>
          {!(isTablet || isMobile) && (
            <span>
              <OrderDateText createdAt={order.created_at} />
            </span>
          )}
          <OrderStatusBadge status={order.public_status} />
        </div>
      </div>
      <div className={styles.pickUpOrderInfo}>
        {!isCanceled && (
          <div className={styles.pickUpOrderMessage}>
            <h2>{messageByStatus()}</h2>
            <CopyOrderNumber
              orderNumber={orderStore.etaCalculation?.warehouse.address || ''}
            />
          </div>
        )}
        {isReadyForPickUpStatus && !(isMobile || isTablet) && (
          <div className={styles.pickUpOrderRedeemCode}>
            <span className={styles.pickUpOrderRedeemCode__label}>
              {t('deliveryMethods:redeemCodeLabel')}
            </span>
            <span className={styles.pickUpOrderRedeemCode__value}>
              {order.order_id}
            </span>
          </div>
        )}
        {isReadyForPickUpStatus && (isMobile || isTablet) && (
          <OrderQrCode orderNumber={order.order_id} />
        )}
      </div>
    </>
  );
};

export default PickUpOrderHeader;
