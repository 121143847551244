import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  ProductFilters,
  ProductPropertyFilterCode,
  catalogStore,
} from '~/stores/CatalogStore';
import { ProductPropertiesFilter } from '~/types/Product/interface';

import Collapsible from '../Collapsible/Collapsible';
import RangeInput from '../RangeInput/RangeInput';

import ProductExpressDeliveryFilter from './ProductExpressDeliveryFilter';
import ProductMultiselectFilter from './ProductMultiselectFilter';
import ProductTypeListFilter from './ProductRadioListFilter';
import ProductToggleFilter from './ProductToggleFilter';

interface ProductFilterProps {
  filter: ProductPropertiesFilter;
  selectedFilters: ProductFilters;
  collapsible?: boolean;
  onChange?: (code: ProductPropertyFilterCode, values: any | any[]) => void;
  className?: string;
  noCollapsible?: boolean;
  isCompact?: boolean;
}

const ProductFilter = ({
  filter,
  onChange,
  selectedFilters,
  className,
  noCollapsible,
  isCompact,
}: ProductFilterProps) => {
  const { t } = useTranslation();

  const label = filter.name ?? t(`properties:${filter.code}`);
  const onValueChange = (value: any) => onChange?.(filter.code, value);

  if (filter.code === 'express_delivery') {
    return (
      <ProductExpressDeliveryFilter
        onChange={onValueChange}
        value={selectedFilters.properties?.[filter.code]}
        label={label}
        className={classes([
          className,
          !isCompact && 'reversed',
          isCompact && '_med',
        ])}
        isCompact={isCompact}
      />
    );
  }

  if (filter.code === 'price') {
    return (
      <Collapsible
        isCollapsed={!filter.showExpanded}
        title={t('price')}
        className={classes(['filter__item', className])}
      >
        <RangeInput
          onChange={({ min, max }) => onValueChange({ from: min, to: max })}
          step={1}
          min={catalogStore.pricesminmax.priceMinPounds}
          max={catalogStore.pricesminmax.priceMaxPounds}
          currentMin={selectedFilters.price?.from}
          currentMax={selectedFilters.price?.to}
          isLoading={catalogStore.isLoading}
        />
      </Collapsible>
    );
  }

  if (filter.code === 'type') {
    if (filter.values.length < 2) {
      return null;
    }

    return (
      <ProductTypeListFilter
        isCollapsed={!filter.showExpanded}
        label={label}
        values={filter.values}
        currentValues={selectedFilters.properties?.[filter.code]}
        onChange={onValueChange}
        className={className}
        noCollapsible={noCollapsible}
      />
    );
  }

  if (filter.type === 'toggle') {
    return (
      <ProductToggleFilter
        label={label}
        value={selectedFilters.properties?.[filter.code]}
        onChange={onValueChange}
        className={classes([className, 'reversed'])}
      />
    );
  }

  if (filter.type === 'selector') {
    return (
      <ProductMultiselectFilter
        isCollapsed={!filter.showExpanded}
        label={label}
        values={filter.values}
        currentValues={selectedFilters.properties?.[filter.code]}
        onChange={onValueChange}
        className={className}
      />
    );
  }

  return (
    <Collapsible
      title={label}
      className={classes(['filter__item', className])}
    />
  );
};

export default observer(ProductFilter);
