import { debounce } from 'lodash-es';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { company } from '~/company/Company';
import { getCloudflareUrl } from '~/components/CloudflareResponsiveImage/utils';
import SwiperIcon from '~/components/Swiper/SwiperIcon';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';
import { Category } from '~/stores/CategoriesStore';
import { userStore } from '~/stores/UserStore';

import { BREAKPOINTS } from '../../../breakpoints';

import { SwiperWithSlidesPerView } from '../BannerProductBlock/interfaces';
import CategoryBlockTitle from '../CategoryBlockTitle';
import { createCategoryUrl } from '../utils';

import styles from './CategoriesCarouselSliderTwoSBlock.module.scss';
import './styles.scss';

interface CategoriesCarouselSliderTwoSBlockProps {
  category: Category;
}

const CategoriesCarouselSliderTwoSBlock = ({
  category,
}: CategoriesCarouselSliderTwoSBlockProps) => {
  const { handleClickCategory } = useCategoryAnalytics();
  const { isMobile } = useGlobal();
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [, setActiveSwiperIndex] = useState(0);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const swiperRef = useRef<SwiperRef>(null);

  const initializeSwiperArrowsDebouncedRef = useRef(
    debounce((swiper: SwiperWithSlidesPerView) => {
      if (!swiper.slides.length) {
        setSlidesPerView(1);
        setIsBeginning(false);
        setIsEnd(false);
        return;
      }

      const slidesPerView = swiper.slidesPerViewDynamic?.() ?? 1;

      setSlidesPerView(slidesPerView);
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }, 100),
  );

  const isArrowsAllowed = () => {
    const swiper = swiperRef.current?.swiper;

    if (!swiper) {
      return false;
    }

    return !isMobile && swiper.slides.length > slidesPerView;
  };

  const isPrevArrowVisible = () => isArrowsAllowed() && !isBeginning;

  const isNextArrowVisible = () => isArrowsAllowed() && !isEnd;

  const cfSrcForImage = (src: string) =>
    getCloudflareUrl(src, company.config.cloudflareImages.domain, {
      width: 224,
      height: 320,
    });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.info}>
          <CategoryBlockTitle className={styles.title} text={category.name} />
          <div
            className={styles.cta}
            dangerouslySetInnerHTML={{ __html: category.previewText }}
          />
        </div>

        <div className="categories-carousel-slider-two-block">
          {isPrevArrowVisible() && (
            <SwiperIcon
              position="left"
              variant="fill"
              onClick={() => {
                swiperRef.current?.swiper.slidePrev();
                setIsEnd(false);
                if (swiperRef.current?.swiper.isBeginning) {
                  setIsBeginning(true);
                }
              }}
            />
          )}
          <div className={styles.carousel}>
            <Swiper
              ref={swiperRef}
              autoHeight
              slidesPerView="auto"
              slidesPerGroupAuto
              spaceBetween={8}
              slidesOffsetAfter={16}
              slidesOffsetBefore={16}
              breakpoints={{
                [BREAKPOINTS.phone]: {
                  spaceBetween: 20,
                  slidesOffsetAfter: 0,
                  slidesOffsetBefore: 0,
                },
              }}
              onSlideChange={(swiper) =>
                setActiveSwiperIndex(swiper.activeIndex)
              }
              onSlidesLengthChange={initializeSwiperArrowsDebouncedRef.current}
              dir={userStore.dir}
              key={userStore.dir}
            >
              {category.subcategory.map(
                ({ previewImage, name, previewText, id }) => (
                  <SwiperSlide
                    key={name}
                    className={styles.whatToChooseElement}
                    style={{
                      backgroundImage: `url('${cfSrcForImage(previewImage)}')`,
                    }}
                  >
                    <Link
                      onClick={handleClickCategory(id)}
                      to={createCategoryUrl({ id })}
                      className={styles.link}
                    >
                      <div className={styles.whatToChooseTitle}>{name}</div>
                      <div
                        className={styles.whatToChoosePrice}
                        dangerouslySetInnerHTML={{ __html: previewText }}
                      />
                    </Link>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </div>
          {isNextArrowVisible() && (
            <SwiperIcon
              position="right"
              variant="fill"
              onClick={() => {
                swiperRef.current?.swiper.slideNext();
                setIsBeginning(false);
                if (swiperRef.current?.swiper.isEnd) {
                  setIsEnd(true);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoriesCarouselSliderTwoSBlock;
