import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { company } from '~/company/Company';
import { deliveryAddressStore } from '~/components/ModalDeliveryAddress/DeliveryAddressStore';
import { ModalRateApp } from '~/components/ModalRateApp';
import { AddPaymentMethod } from '~/pages/PaymentMethod/PaymentMethod';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import { ModalType } from '../Modal/interface';
import Modal from '../Modal/Modal';
import Modal3DS from '../Modal3DS/Modal3DS';
import ModalAgeRestricted from '../ModalAgeRestricted/ModalAgeRestricted';
import ModalAuth from '../ModalAuth/ModalAuth';
import ModalChangeEmail from '../ModalChangeEmail/ModalChangeEmail';
import ModalChangeName from '../ModalChangeName/ModalChangeName';
import ModalChangeNotifications from '../ModalChangeNotifications/ModalChangeNotifications';
import ModalCheckoutReciever from '../ModalCheckoutReciever/ModalCheckoutReciever';
import ModalDeleteAccount from '../ModalDeleteAccount/ModalDeleteAccount';
import ModalDeleteAddress from '../ModalDeleteAddress/ModalDeleteAddress';
import ModalDeleteCard from '../ModalDeleteCard/ModalDeleteCard';
import ModalDeliveryAddress from '../ModalDeliveryAddress/ModalDeliveryAddress';
import ModalDeliveryInfo from '../ModalDeliveryInfo/ModalDeliveryInfo';
import { ModalEnterDeliveryAddress } from '../ModalEnterDeliveryAddress';
import ModalFeedback from '../ModalFeedback/ModalFeedback';
import ModalFeedbackSuccess from '../ModalFeedback/ModalFeedbackSuccess';
import ModalLogout from '../ModalLogout/ModalLogout';
import ModalPromocode from '../ModalPromocode/ModalPromocode';
import { ModalRecommendations } from '../ModalRecommendations';
import ModalSlotsSelectDate from '../ModalSlotsSelectDate/ModalSlotsSelectDate';

import styles from './ModalList.module.scss';

export default observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const modalType = searchParams.get('modal');
  const [showModal, setShowModal] = useState(false);
  const ageRestrictionModal =
    !userStore.personalData.isAdult &&
    location.pathname !== '/legals' &&
    !company.missAgeRestrictionModal;

  useEffect(() => {
    if (modalType) {
      mainStore.sendToRN('showModal', modalType);
      setShowModal(true);
    } else {
      mainStore.sendToRN('showModal', '');
      setShowModal(false);
    }
  }, [modalType]);

  useEffect(() => {
    if (ageRestrictionModal) {
      mainStore.sendToRN('setStatusBar', 'light-content');
      setSearchParams((prev) => {
        prev.set('modal', 'age-restriction');
        return prev;
      });
    } else if (modalType === 'age-restriction') {
      mainStore.sendToRN('setStatusBar', 'dark-content');
      setSearchParams((prev) => {
        prev.delete('modal');
        return prev;
      });
    }
  }, [modalType, ageRestrictionModal]);

  const handleDeliveryAddressBackdropDismiss = (closeModal: () => void) => {
    closeModal();
    deliveryAddressStore.setSelectedZone(null);
  };

  if (!showModal) {
    return null;
  }

  return (
    <>
      <Modal
        isShow={modalType === ModalType.Auth && !userStore.isAuthorized}
        className="modal-auth__body"
      >
        <ModalAuth />
      </Modal>

      <ModalDeliveryInfo isShow={modalType === ModalType.DeliveryInfo} />

      <Modal
        isShow={modalType === ModalType.DeliveryAddress}
        className="_mobile-fullscreen modal-delivery-address__body"
        onBackdropDismiss={handleDeliveryAddressBackdropDismiss}
      >
        <ModalDeliveryAddress />
      </Modal>

      <Modal
        isShow={modalType === ModalType.ChangeEmail}
        className="modal-change-email__body"
      >
        <ModalChangeEmail />
      </Modal>

      <Modal
        isShow={modalType === ModalType.ChangeName}
        className="modal-change-name__body"
      >
        <ModalChangeName />
      </Modal>

      <Modal
        isShow={modalType === ModalType.ChangeNotifications}
        className="modal-change-notifications__body"
      >
        <ModalChangeNotifications />
      </Modal>

      <Modal
        isShow={modalType === ModalType.Promocode}
        className="modal-promocode__body"
      >
        <ModalPromocode />
      </Modal>

      <Modal
        isShow={modalType === ModalType.DeleteAccount}
        className="modal-delete-account__body"
      >
        <ModalDeleteAccount />
      </Modal>

      <Modal
        isShow={modalType === ModalType.DeleteAddress}
        className="modal-delete-address__body"
      >
        <ModalDeleteAddress />
      </Modal>

      <Modal
        isShow={modalType === ModalType.DeleteCard}
        className="modal-delete-card__body"
      >
        <ModalDeleteCard />
      </Modal>

      <Modal
        isShow={modalType === ModalType.Logout}
        className="modal-logout__body"
      >
        <ModalLogout />
      </Modal>

      <Modal isShow={modalType === ModalType.Check3Ds} className="modal_3ds">
        <Modal3DS />
      </Modal>

      <Modal
        isShow={modalType === ModalType.EnterDeliveryAddress}
        className="_mobile-fullscreen modal-enter-delivery-address__body"
      >
        <ModalEnterDeliveryAddress />
      </Modal>

      <Modal
        isShow={modalType === ModalType.AddCard}
        className="modal-add-card__body"
      >
        <AddPaymentMethod />
      </Modal>

      <Modal
        isShow={modalType === ModalType.SelectDate}
        className="modal-select-date__body"
        onBackdropDismiss={(closeModal) => {
          checkoutStore.slotSelectionModalStore.setSelectedSlot(null, null);
          closeModal();
        }}
      >
        {modalType === ModalType.SelectDate && <ModalSlotsSelectDate />}
      </Modal>

      <Modal
        isShow={modalType === ModalType.Feedback}
        className={styles.modalFeedback__body}
      >
        <ModalFeedback />
      </Modal>

      <Modal
        isShow={modalType === ModalType.FeedbackSuccess}
        className={styles.modalFeedbackSuccess__body}
      >
        <ModalFeedbackSuccess />
      </Modal>

      <Modal
        isShow={modalType === ModalType.CheckoutReciever}
        className={styles.modalFeedbackSuccess__body}
      >
        <ModalCheckoutReciever />
      </Modal>

      <Modal
        isShow={modalType === ModalType.AgeRestriction}
        className="_mobile-fullscreen modal-age-restricted__body"
        containerClassName="modal-age-restricted__container"
        onBackdropDismiss={() => null}
      >
        <ModalAgeRestricted />
      </Modal>

      <ModalRateApp />

      <Modal
        isShow={modalType === ModalType.Recommendations}
        className={styles.modalFeedback__body}
      >
        <ModalRecommendations />
      </Modal>
    </>
  );
});
