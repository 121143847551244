import { format } from 'date-fns-tz';
import { classes } from 'html-classes';

import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';

import styles from './DeliveryDateLabel.module.scss';

interface DeliveryDateLabelProps {
  deliveryDate: Date | null;
  className?: string;
  dateFormat?: string;
}

const DeliveryDateLabel = ({
  deliveryDate,
  className,
  dateFormat = 'dd.MM',
}: DeliveryDateLabelProps) => {
  const { locale } = useDateLang();

  return (
    (deliveryDate && (
      <div className={classes([styles.container, className])}>
        {format(deliveryDate, dateFormat, {
          timeZone: company.config.timeZone,
          locale,
        })}
      </div>
    )) ||
    null
  );
};

export default DeliveryDateLabel;
