import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import CD_CAREER_COURIER from '~/assets/img/cd/career_courier.png';
import CD_CAREER_DRIVER from '~/assets/img/cd/career_driver.png';
import CD_Careers from '~/assets/img/cd_careers.png';
import CD_CareersJoin from '~/assets/img/cd_careers_join.png';
import LinkedIn from '~/assets/img/LinkedIn.png';
import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Careers.module.scss';

const workWithUs = [
  {
    iconName: 'people',
    content:
      'Passionate Team: Join a team of enthusiasts who share a love for fine spirits, exquisite wines, and craft beers.',
  },
  {
    iconName: 'home-trend-up',
    content:
      'Growth Opportunities: Be part of a company that values individual growth and provides opportunities for career development.',
  },
  {
    iconName: 'note',
    content: `Innovative Environment: Work in a dynamic and innovative environment where creativity is encouraged, and your ideas are valued.`,
  },
  {
    iconName: 'promocode',
    content: `Employee Benefits: Enjoy competitive compensation packages, health benefits, and special perks related to our world of premium beverages.`,
  },
];

const jobOpenings = [
  {
    title: 'Driver',
    location: 'Khalifa Industrial Zone – Abu Dhabi, UAE',
    description: `We're looking for experienced, reliable drivers to transport goods locally and regionally, ensuring timely and safe deliveries while complying with traffic laws and company policies.`,
    requirements: [
      "Valid driver's license with a clean driving record.",
      'Proven experience as a driver, preferably in a delivery or transportation role.',
      'Flexibility to work various shifts, including weekends and holidays as required.',
    ],
    image: CD_CAREER_DRIVER,
  },
  {
    title: 'Courier',
    location: 'Khalifa Industrial Zone – Abu Dhabi, UAE',
    description: `Your role will involve timely and safe delivery of goods to customers while maintaining high service standards.`,
    requirements: [
      'Strong communication and customer service skills.',
      'Excellent time management and organizational skills.',
      'Physically fit and able to lift packages.',
      'Attention to detail and accuracy in package handling.',
    ],
    image: CD_CAREER_COURIER,
  },
];

export default observer(() => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();
  const { state } = useLocation();
  const source = state?.source || 'home';

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      {isTablet && (
        <MobileHeaderLayout
          showTopCart
          content={<MobileHeaderTitle text={t('careers')} />}
        />
      )}
      <div className={classes(['content-layout', styles.root])}>
        {!isTablet && (
          <Breadcrumbs
            items={
              [
                {
                  text: t('home'),
                  link: '/',
                },
                source === 'cabinet' && {
                  text: t('cabinet'),
                  link: '/cabinet',
                },
                {
                  text: t('careers'),
                  link: '/careers',
                },
              ].filter(Boolean) as BreadcrumbsItem[]
            }
            className={styles.breadcrumbs}
          />
        )}
        <section className={styles.header}>
          <h1 className={styles.header__welcome}>
            Join Our Team at&nbsp;CityDrinks!
          </h1>
          <p className={styles.content__article_text}>
            At&nbsp;CityDrinks, we believe in bringing together a team of
            passionate individuals who share our commitment to delivering
            excellence in the world of premium beverages. As a growing company,
            we are always on the lookout for dynamic, creative, and dedicated
            professionals to join our journey.
          </p>

          <img src={CD_Careers} className={styles.image} />
          <h3 className={styles.content__article_first}>Why Work with Us?</h3>
          <div className={styles.whyWorkWithUs}>
            {workWithUs.map((item, i: number) => (
              <div className={styles.wWBlock} key={i}>
                <Icon type={item?.iconName ?? 'people'} size={24} />
                <p dangerouslySetInnerHTML={{ __html: item?.content ?? '' }} />
              </div>
            ))}
          </div>

          <h3 className={styles.content__article_first}>Current Openings</h3>

          <div className={styles.job_slider_container}>
            <Swiper
              spaceBetween={50}
              slidesPerView={2}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              breakpoints={{
                320: {
                  slidesPerView: 'auto',
                  spaceBetween: 18,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 32,
                },
              }}
            >
              {jobOpenings.map((job, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.job_card}>
                    <div className={styles.job_image}>
                      <img src={job.image} alt={job.title} />
                    </div>
                    <div className={styles.job_details}>
                      <h3>{job.title}</h3>
                      <span>Location:</span>
                      <p>{job.location}</p>
                      <span>Description:</span>
                      <p>{job.description}</p>
                      <span>Requirements:</span>
                      <ul>
                        {job.requirements.map((req, x) => (
                          <li key={x}>{req}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className={styles.howToApply}>
            <h3>How to Apply</h3>
            <p>
              To apply for a position at&nbsp;CityDrinks, <br />
              please send your resume and cover letter to
            </p>
            <a href={`mailto:${company.config.email}`}>
              <Icon type="envelope" size={24} />
              {company.config.email}
            </a>
            <p className={styles.secondaryText}>
              Please mention the position&nbsp;title in&nbsp;the&nbsp;subject
              line of&nbsp;your&nbsp;email.
            </p>
          </div>

          <h3 className={styles.content__article_first}>Stay Connected</h3>
          <div className={styles.stayConnected}>
            <a
              href={company.config.links.linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedIn} width={40} />
            </a>
            <p>
              <a
                href={company.config.links.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                Follow us on LinkedIn for updates
              </a>{' '}
              <br />
              on career opportunities and company news.
            </p>
          </div>

          <div className={styles.note}>
            <img src={CD_CareersJoin} className={styles.noteImage} />
            <div className={styles.noteText}>
              <h3>Note:</h3>
              <p>
                CityDrinks is an equal opportunity employer. We&nbsp;celebrate
                diversity and are committed to creating an inclusive environment
                for all employees.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
});
