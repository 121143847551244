import {
  CardElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  type Stripe,
  type StripeCardElementChangeEvent,
  type StripeCardNumberElement,
} from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { AxiosError } from 'axios';
import { classes } from 'html-classes';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FormEvent, useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { IMAGES } from '~/constants/images';
import CARD_BRANDS from '~/pages/Checkout/cardBrands';

import { Payment } from '../../api/Order';
import UnknownCard from '../../assets/img/unknown_card.png';
import Icon from '../../components/Icon/Icon';
import MainSidebar from '../../components/MainSidebar/MainSidebar';
import { ModalType } from '../../components/Modal/interface';
import { useModal } from '../../hooks/useModal';
import { catalogStore } from '../../stores/CatalogStore';
import { checkoutStore } from '../../stores/CheckoutStore/CheckoutStore';
import { mainStore } from '../../stores/MainStore';
import { orderStore } from '../../stores/OrderStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './PaymentMethod.module.scss';
import PaymentMethodsSkeleton from './PaymentMethodsSkeleton';

type PaymentMethodItemProps = {
  paymentMethod: Payment;
};

class Store {
  isShowAddPaymentCards = false;
  isLoading = false;
  listToDelete: string[] = [];
  defaultCardId = '';
  methodToDelete: string | null = null;

  addError = '';

  constructor() {
    makeAutoObservable(this);
  }

  async addItemToDelete(id: string) {
    try {
      await orderStore.deletePayment(id);
      await orderStore
        .requestPayments()
        .catch((error) => error && console.error(error));
    } catch (e) {
      // Sentry.captureMessage('[PaymentCards] Failed delete payments', 'warning');
      await orderStore
        .requestPayments()
        .catch((error) => error && console.error(error));
      this.setIsLoading(false);
      return;
    }
  }

  async setDefaultCardId(id: string) {
    try {
      paymentStore.defaultCardId = id;
      await orderStore.setDefaultPayment(id);
      await orderStore
        .requestPayments()
        .catch((error) => error && console.error(error));
    } catch (e) {
      await orderStore
        .requestPayments()
        .catch((error) => error && console.error(error));
      this.setIsLoading(false);
      return;
    }
  }

  setIsShowAddPaymentCards(flag: boolean) {
    this.isShowAddPaymentCards = flag;
  }

  setMethodToDelete(id: string | null) {
    this.methodToDelete = id;
  }

  setAddError(e: string) {
    this.addError = e;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }
}

export const paymentStore = new Store();

export const PaymentPage = observer(() => {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    paymentStore.setIsLoading(true);
    orderStore
      .requestPayments()
      .catch((error) => error && console.error(error))
      .finally(() => {
        runInAction(() => {
          paymentStore.setIsLoading(false);
        });
      });
  }, []);

  return (
    <>
      <MobileHeaderLayout
        content={<MobileHeaderTitle text="Payment Method" />}
      />

      <div className="content payment">
        <aside className="sidebar">
          <MainSidebar />
        </aside>

        <main className={classes(['container', styles.container])}>
          <h1 className="page-title">{t('paymentMethod')}</h1>
          {orderStore.paymentCards.length > 0 || paymentStore.isLoading ? (
            <div className="top-block">
              <section className="payment-items">
                {orderStore.paymentCards.length > 0 ? (
                  orderStore.paymentCards.map((card) => (
                    <PaymentMethodItem paymentMethod={card} key={card.id} />
                  ))
                ) : (
                  <PaymentMethodsSkeleton />
                )}
              </section>
            </div>
          ) : (
            <EmptyCards />
          )}
        </main>
      </div>
    </>
  );
});

const PaymentMethodItem = observer(
  ({ paymentMethod }: PaymentMethodItemProps) => {
    const { openModal } = useModal();
    const {
      card: { brand, last4 },
      metadata: { is_default },
      id,
    } = paymentMethod;

    const handleCardToDelete = () => {
      paymentStore.setMethodToDelete(id);
      openModal(ModalType.DeleteCard);
    };

    const handleSetDefault = () => paymentStore.setDefaultCardId(id);
    const isDefaultCard = paymentStore.defaultCardId
      ? paymentStore.defaultCardId === id
      : is_default === 'true';

    if (paymentStore.listToDelete.indexOf(id) !== -1) {
      return <></>;
    }

    return (
      <label className="item" data-company={company.name}>
        <div className="left-block">
          <div className="radio-wrapper">
            <input
              type="radio"
              className="radio"
              name="address"
              checked={isDefaultCard}
              onClick={handleSetDefault}
            />
            <span className="mark" />
            <div className="text-wrapper">
              <div className="card">
                <img
                  className={classes(['w-38 h-22', isDefaultCard && 'mt-18'])}
                  src={CARD_BRANDS[brand]?.icon || UnknownCard}
                  alt=""
                />
                {CARD_BRANDS[brand]?.title || brand}
                <div className="card-number">•• {last4}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-block">
          <div className="icon-wrapper">
            <button className="button _no-padding" onClick={handleCardToDelete}>
              <Icon type="trash" size={24} />
            </button>
          </div>
        </div>
      </label>
    );
  },
);

export const AddPaymentMethod = observer(() => {
  const { t } = useTranslation();

  const [stripePromise, setStripePromise] = useState<Stripe | null | undefined>(
    undefined,
  );
  const init = useCallback(async () => {
    let stripePromise: Stripe | null = null;
    try {
      stripePromise = await loadStripe(
        import.meta.env.REACT_APP_STRIPE_API_KEY,
        {
          locale: 'en-GB',
        },
      );
      setStripePromise(stripePromise || null);
    } catch (error) {
      error && console.error(error);
    }
  }, []);

  useLayoutEffect(() => {
    paymentStore.setIsLoading(true);
    init().catch((error) => error && console.error(error));
    orderStore
      .requestPayments()
      .catch((error) => error && console.error(error))
      .finally(() => {
        runInAction(() => {
          paymentStore.setIsLoading(false);
        });
      });
    //eslint-disable-next-line
  }, []);

  return (
    <div className="card-add">
      <p className="section-title">{t('enterNewCard')}</p>
      {stripePromise !== undefined && (
        <div className="card-add-container">
          <Elements stripe={stripePromise}>
            <AddCard />
          </Elements>
        </div>
      )}
    </div>
  );
});

const AddCard = observer(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { closeModal } = useModal();

  const handleCloseModal = () => closeModal();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    paymentStore.setAddError('');
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    let intent: any;
    try {
      intent = await orderStore.createIntent();
    } catch (error) {
      setIsLoading(false);
      return;
    }
    if (!intent || !intent.client_secret) {
      setIsLoading(false);
      return;
    }
    try {
      const paymentMethod = {
        card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
      };
      const confirm = await stripe.confirmCardSetup(intent.client_secret, {
        payment_method: paymentMethod,
      });
      if (confirm.error) {
        setIsLoading(false);
        paymentStore.setAddError(confirm.error.message as string);
        mainStore.pushAlert('error', confirm.error.message as string);
        return;
      } else if (confirm.setupIntent?.payment_method) {
        await orderStore.addPayment(
          confirm.setupIntent?.payment_method as string,
        );
        await orderStore.requestPayments();
        paymentStore.setIsShowAddPaymentCards(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error instanceof AxiosError && !error.response) {
        return;
      }
    }
    setIsLoading(false);
  };

  const handleChange = (e: StripeCardElementChangeEvent) => {
    checkoutStore.setIsDisabled(!e.complete);
    checkoutStore.setError(e.error ? e.error.message : '');
    if (e.complete) {
      mainStore.sendToRN('analytics', {
        name: 'Purchase: payment method filled in',
        params: {
          cart_id: undefined,
          payment_method: 'card',
          type: 'new',
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'add_payment_info',
        params: {
          currency: orderStore.currency.toUpperCase(),
          payment_type: 'credit card',
          value: mainStore.toFloat(catalogStore.finalPrice),
          items: catalogStore.cartForFirebase,
          coupon: catalogStore.promocode.value,
        },
      });
    }
  };

  return (
    <form className="card-data" onSubmit={handleSubmit}>
      <div className="card-data-info">
        <CardElement
          options={checkoutStore.cardStyle}
          onChange={handleChange}
        />
      </div>
      <div className="card-data-buttons">
        <button
          className={classes([
            'button _primary _med',
            isLoading && '_disabled',
          ])}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <span className="spinner" /> : t('add')}
        </button>
        <button className="button _bordered _med" onClick={handleCloseModal}>
          {t('cancel')}
        </button>
      </div>
    </form>
  );
});

const EmptyCards = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="empty-cart" data-company={company.name}>
      <img
        className="empty-cart__img"
        src={IMAGES.payments.empty}
        alt="empty list"
      />
      <p className="empty-cart__text empty-cart__title">
        {t('phrases:emptyCards')}
      </p>
      {company.showAdditionalTextEmptyPayments && (
        <p className="empty-cart__text empty-cart__additional">
          {t('phrases:emptyCardsAdditional')}
        </p>
      )}
    </div>
  );
});
